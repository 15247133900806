import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { Box, Icon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EventHeader from "./EventHeader";
import { ReactComponent as ObjectStop } from "../../../../assets/object_stop.svg";
import * as actions from "../../../../store/actions";
import {
  getColor,
  getName,
  isInactive,
  isOutOfBounds,
  isPositionAccurate,
} from "../../../../utils/movingObjectHelper";
import moment from "moment";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    marginTop: "0px",
  },
  alertBox: {
    height: "47px",
    maxWidth: "390px",
    width: "100vw",
    marginBottom: "3px",
    backgroundColor: "transparent",
    marginTop: "-18px",
    zIndex: "5",
  },
  alertText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
  },
  iconButton: {
    marginLeft: "10px",
    marginTop: "8px !important",
    color: theme.palette.common.white + " !important",
    display: "inline-flex !important",
  },
  header: {
    display: "contents",
    float: "left",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  dividerLarge: {
    display: "inline-block",
    width: "50% !important",
  },
  dividerSmall: {
    display: "inline-block",
    width: "0px !important",
    marginLeft: "-20px",
  },
  title: {
    fontSize: "16px !important",
    marginLeft: "15px !important",
    marginBottom: "5px !important",
  },
  column: {
    display: "inherit",
    marginTop: "6px",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.common.white,
    padding: "8px",
    paddingBottom: "10vh",
    transition: "opacity 1s",
    overflow: "scroll",
    overflowY: "auto",
    overflowX: "hidden",
  },
  italics: {
    fontStyle: "italic",
    fontSize: "18px",
  },
  row: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "22px",
  },
  rowNoBorders: {
    backgroundColor: theme.palette.common.white,
    fontSize: "22px",
  },
  lastRowNoBorders: {
    backgroundColor: theme.palette.common.white,
    marginBottom: "56px",
    fontSize: "22px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "22px",
  },
  buttonRow: {
    backgroundColor: theme.palette.common.white,
    marginBottom: "8px",
    padding: "10px",
    fontSize: "22px",
  },
  objectContentInaccurate: {
    cursor: "pointer",
    marginLeft: "70px",
    fontSize: "15px",
    border: "2px solid magenta",
    borderRadius: "10px",
  },
  objectContentAccurate: {
    cursor: "pointer",
    marginLeft: "70px",
    border: "2px solid white",
    borderRadius: "10px",
    fontSize: "15px",
  },
  objectContentInaccurateSelected: {
    cursor: "pointer",
    marginLeft: "70px",
    fontSize: "15px",
    border: "2px solid magenta",
    borderRadius: "10px",
    backgroundColor: "silver",
  },
  objectContentAccurateSelected: {
    cursor: "pointer",
    marginLeft: "70px",
    fontSize: "15px",
    border: "2px solid white",
    borderRadius: "10px",
    backgroundColor: "silver",
  },
}));

const MovingObjects = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const [actives, setActives] = useState([]);
  const [inactives, setInactives] = useState([]);
  // const [depot, setDepot] = useState([]);
  const allPositions = useSelector((state) => state.mqtt.allPositions);
  const followedUnit = useSelector((s) => s.map.followUnit);
  const dispatch = useDispatch();

  const handleClose = () => {
    props.showView("map");
  };

  const handleClick = (item) => {
    if (!isOutOfBounds(item.coordinates.lat, item.coordinates.lon)) {
      if (item.deliveryStatus.sentBy === followedUnit) {
        dispatch(actions.followUnit(null));
      } else {
        dispatch(actions.followUnit(item.deliveryStatus.sentBy));
      }
    }
  };

  const getClassName = (item) => {
    if (item.deliveryStatus.sentBy === followedUnit) {
      if (isPositionAccurate(item.dop, item.fix)) {
        return classes.objectContentAccurateSelected;
      } else {
        return classes.objectContentInaccurateSelected;
      }
    } else {
      if (isPositionAccurate(item.dop, item.fix)) {
        return classes.objectContentAccurate;
      } else {
        return classes.objectContentInaccurate;
      }
    }
  };

  function compare(a, b) {
    if (a.deliveryStatus.sentBy < b.deliveryStatus.sentBy) {
      return -1;
    }
    if (a.deliveryStatus.sentBy > b.deliveryStatus.sentBy) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    let act = [];
    let inact = [];
    for (let position of allPositions) {
      if (
        isInactive(position.deliveryStatus.sentAt) ||
        isOutOfBounds(position.coordinates.lat, position.coordinates.lon)
      ) {
        inact.push(position);
      } else {
        act.push(position);
      }
    }
    act.sort(compare);
    inact.sort(compare);
    setActives(act);
    setInactives(inact);
    // setDepot([]);
  }, [allPositions]);

  return (
    <div className={classes.mainContainer}>
      <EventHeader
        title={i18n.t("navigation.movingObjects.text")}
        handleCloseClick={handleClose}
      />
      <div id="objectContainer" className={classes.objectContainer}>
        <Box
          id="objects-column"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
            maxHeight: {
              xs: `calc(${height}px - 56px)`,
              md: `${height}px`,
            },
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div id="column" className={classes.column}>
            <div id="active-row" className={classes.row}>
              <div id="active-header" className={classes.italics}>
                Aktiiviset:
              </div>
              {actives.map((item) => (
                <div
                  id="object-content"
                  className={getClassName(item)}
                  key={item.deliveryStatus.sentBy}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  <Icon baseClassName="material-icons-outlined">
                    <ObjectStop
                      style={{
                        padding: "2.2px 1px 1.5px 0px",
                        transform: "scale(6)",
                        height: 30,
                        width: 30,
                        stroke: "#000000",
                        fill: getColor(item.deliveryStatus.sentBy),
                      }}
                    />
                  </Icon>
                  {getName(item.deliveryStatus.sentBy)}
                  {" | "}
                  {item.nearest_track}
                  {" | Nopeus: "}
                  {(item.speed * 3.6).toFixed(0)}
                  {" km/h"}
                </div>
              ))}
            </div>
            <div id="inactive-row" className={classes.row}>
              <div id="inactive-header" className={classes.italics}>
                Ei aktiiviset:
              </div>
              {inactives.map((item) => (
                <div
                  id="object-content"
                  className={getClassName(item)}
                  key={item.deliveryStatus.sentBy}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  <Icon baseClassName="material-icons-outlined">
                    <ObjectStop
                      style={{
                        padding: "2.2px 1px 1.5px 0px",
                        transform: "scale(6)",
                        height: 30,
                        width: 30,
                        stroke: "#000000",
                        fill: getColor(item.deliveryStatus.sentBy),
                      }}
                    />
                  </Icon>
                  {getName(item.deliveryStatus.sentBy)}
                  {" | "}
                  {item.nearest_track}
                  {" | "}
                  {moment(
                    new Date(item.deliveryStatus.sentAt),
                    "DD.MM.YYYY HH:mm:ss"
                  ).format("DD.MM.YYYY HH:mm")}
                </div>
              ))}
            </div>
            {/* <div id="depot-row" className={classes.row}>
              <div id="depot-header" className={classes.italics}>
                Varikolla:
              </div>
              {depot.map((item) => (
                <div
                  id="object-content"
                  className={classes.objectContent}
                  key={item.deliveryStatus.sentBy}
                >
                  <Icon baseClassName="material-icons-outlined">
                    <ObjectStop
                      style={{
                        padding: "2.2px 1px 1.5px 0px",
                        transform: "scale(6)",
                        height: 30,
                        width: 30,
                        stroke: "#000000",
                        fill: getColor(item.deliveryStatus.sentBy),
                      }}
                    />
                  </Icon>
                  {getName(item.deliveryStatus.sentBy)}
                  {" | "}
                  {item.trackName}
                  {" | "}
                  {moment(
                    new Date(item.deliveryStatus.sentAt),
                    "DD.MM.YYYY HH:mm:ss"
                  ).format("DD.MM.YYYY HH:mm")}
                </div>
              ))}
            </div> */}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default MovingObjects;
