import { Flex } from "../../Layout";
import i18n from "i18next";
import "./index.css";
import { Icon } from "@mui/material";
import { monthInfo } from "../../../../utils/schedulerUtils";
import HeaderDayColumns from "./_components/HeaderDayColumns";
import { breakpointUpMedium } from "../../../../utils/breakpointsHelper";

export default function SchedulerHeader(props) {
  const { dayColumnRef, onScroll, openview, onChangeView } = props;
  const currentDate = new Date();
  const { prevThree, prevTwo, prev, current, next, nextTwo, nextThree } =
    monthInfo(currentDate);

  const handleFullscreenExit = () => {
    if (openview === "filterListFull") {
      onChangeView("filterListHalf");
    } else if (openview === "eventListFull") {
      onChangeView("eventListHalf");
    } else if (openview === "eventInfoEditFull") {
      onChangeView("eventInfoEditHalf");
    } else if (openview === "eventInfoCreateFull") {
      onChangeView("eventInfoCreateHalf");
    } else {
      onChangeView("halfevents");
    }
  };

  const handleFullscreenEnter = () => {
    if (openview === "filterListHalf") {
      onChangeView("filterListFull");
    } else if (openview === "eventListHalf") {
      onChangeView("eventListFull");
    } else if (openview === "eventInfoEditHalf") {
      onChangeView("eventInfoEditFull");
    } else if (openview === "eventInfoCreateHalf") {
      onChangeView("eventInfoCreateFull");
    } else {
      onChangeView("events");
    }
  };

  const handleFilterListClick = () => {
    if (
      ["events", "eventInfoCreateFull", "eventInfoEditFull"].includes(openview)
    ) {
      onChangeView("filterListFull");
    } else if (
      ["halfevents", "eventInfoCreateHalf", "eventInfoEditHalf"].includes(
        openview
      )
    ) {
      onChangeView("filterListHalf");
    } else if (openview === "filterListHalf") {
      onChangeView("halfevents");
    } else {
      onChangeView("events");
    }
  };

  return (
    <Flex>
      <Flex direction="row" justify="center" id="header" className="header">
        <Flex
          id="fl-toggler"
          className="fl-toggler"
          onClick={handleFilterListClick}
        >
          <Icon sx={{ fontSize: "17px" }} className="fl-icon">
            filter_list
          </Icon>
        </Flex>
        <Flex
          id="today-toggler"
          className="today-toggler"
          onClick={props.executeScroll}
        >
          <Icon sx={{ fontSize: "17px" }} className="today-icon">
            today
          </Icon>
        </Flex>
        <Flex
          direction="row"
          id="view-toggler-cont"
          className="view-toggler-cont"
        >
          <Flex className="view-toggler selected">
            <Icon sx={{ fontSize: "17px" }} className="calendar-vew-icon">
              calendar_view_week
            </Icon>
          </Flex>
          <Flex id="view-toggler" className="view-toggler">
            <Icon sx={{ fontSize: "17px" }} className="calendar-vew-icon">
              crop_landscape_outlined
            </Icon>
          </Flex>
        </Flex>
        <span id="header-title" className="header-title">
          {i18n.t("schedulerPage.pageTitle")}
        </span>
        <Flex id="fullscreen-toggler" className="fullscreen-toggler">
          {[
            "events",
            "eventInfoEditFull",
            "eventInfoCreateFull",
            "filterListFull",
            "eventListFull",
          ].includes(openview) &&
            breakpointUpMedium() && (
              <Icon className="fullscreen-icon" onClick={handleFullscreenExit}>
                {"fullscreen_exit"}
              </Icon>
            )}

          {[
            "halfevents",
            "eventInfoEditHalf",
            "eventInfoCreateHalf",
            "filterListHalf",
            "eventListHalf",
          ].includes(openview) &&
            breakpointUpMedium() && (
              <Icon className="fullscreen-icon" onClick={handleFullscreenEnter}>
                {"fullscreen"}
              </Icon>
            )}
        </Flex>
      </Flex>
      <HeaderDayColumns
        style={{ flex: 1 }}
        monthInfo={{
          prevThree,
          prevTwo,
          prev,
          current,
          next,
          nextTwo,
          nextThree,
        }}
        dayColumnRef={dayColumnRef}
        onScroll={onScroll}
      />
    </Flex>
  );
}
