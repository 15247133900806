import * as actionTypes from "./actionTypes";

export const openView = (openview) => {
  return {
    type: actionTypes.OPEN_VIEW,
    openview: openview,
  };
};

export const addToLoading = (item) => {
  return {
    type: actionTypes.ADD_TO_LOADING,
    loadingItem: item,
  };
};

export const removeFromLoading = (item) => {
  return {
    type: actionTypes.REMOVE_FROM_LOADING,
    loadingItem: item,
  };
};

export const eventsData = (objectArray) => {
  return {
    type: actionTypes.EVENTS_DATA,
    events: objectArray,
  };
};

export const setTrackEvents = (objectArray) => {
  return {
    type: actionTypes.TRACK_EVENTS,
    events: objectArray,
  };
};

export const updateEvents = (events) => {
  return {
    type: actionTypes.UPDATE_EVENTS,
    events: events,
  };
};

export const updateSingleEvent = (event) => {
  return {
    type: actionTypes.UPDATE_SINGLE_EVENT,
    event: event,
  };
};

export const removeEvent = (event) => {
  return {
    type: actionTypes.REMOVE_EVENT,
    event: event,
  };
};

export const updateEventComments = (eventId, comments) => {
  return {
    type: actionTypes.UPDATE_EVENT_COMMENTS,
    eventId: eventId,
    comments: comments,
  };
};

export const setSelectedEventId = (eventId) => {
  return {
    type: actionTypes.SELECTED_EVENT_ID,
    eventId: eventId,
  };
};

export const setFilterListEventIds = (objectArray) => {
  return {
    type: actionTypes.FILTER_LIST_EVENT_IDS,
    filterListEventIds: objectArray,
  };
};

export const fetchEvents = (fetchEvents) => {
  return {
    type: actionTypes.FETCH_EVENTS,
    fetchEvents: fetchEvents,
  };
};

export const trainsData = (objectArray) => {
  return {
    type: actionTypes.TRAINS_DATA,
    trains: objectArray,
  };
};

export const updateTrains = (objectArray) => {
  return {
    type: actionTypes.UPDATE_TRAINS,
    trains: objectArray,
  };
};

export const addToSelected = (time) => {
  return {
    type: actionTypes.ADD_TO_SELECTED,
    time: time,
  };
};

export const filterEventsByTime = (filter) => {
  return {
    type: actionTypes.EVENTS_TIME_FILTER,
    filter: filter,
  };
};

export const resetEventsByTime = () => {
  return {
    type: actionTypes.RESET_TIME_FILTER,
  };
};

export const changeLoadingState = (loading) => {
  return {
    type: actionTypes.LOADING_STATE,
    loading: loading,
  };
};
