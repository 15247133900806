import i18n from "i18next";
import { fi, enUS } from "date-fns/locale";

export const getCorrectLocale = () => {
  const language = i18n.language;

  switch (language) {
    case "fi":
      return fi;
    case "en":
      return enUS;
    default:
      return fi;
  }
};
