import * as React from "react";
import { useSelector } from "react-redux";
import { Source, Layer } from "react-map-gl";

// put wide transparent lines under actual tracks
// this helps users to select a track
const hitBufferStyle = {
  id: "tracklayer-hitbuffer",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "transparent",
    "line-width": 15,
  },
};

const lineStyle = {
  id: "tracklayer-lines",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "black",
    "line-width": 3,
  },
};

const selectionStyle = {
  id: "tracklayer-selection",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#2020f0",
    "line-width": 3,
  },
};

const labelStyle = {
  id: "tracklayer-labels",
  type: "symbol",
  minzoom: 8,
  filter: ["==", "$type", "LineString"],
  layout: {
    "symbol-placement": "line",
    "text-font": ["Noto Sans Bold"],
    "text-field": "{trackName}",
    "text-allow-overlap": true,
    "text-size": ["interpolate", ["linear"], ["zoom"], 12, 11, 20, 15],
  },
  //PRX-101 show only black text without halo
  //Note, changed halo from blue / 2.75 to white / 2. this change is commented on JIRA
  paint: {
    "text-color": "#000000", //"#ffffff",
    "text-halo-color": "#ffffff", //"#0000ff",
    "text-halo-width": 2, //2.75, // max 1/4 of font size
  },
};

const TrackLayer = () => {
  const trackLayerData = useSelector((s) => s.map.trackLayerData);
  const selectedFeature = useSelector((s) => s.map.selectedFeature);
  const selectedTrack =
    (selectedFeature && selectedFeature.properties.trackID) || "";
  const selectionFilter = React.useMemo(
    () => ["in", "trackID", selectedTrack],
    [selectedTrack]
  );

  return (
    <Source id="tracklayer" type="geojson" data={trackLayerData}>
      <Layer {...hitBufferStyle} />
      <Layer {...lineStyle} />
      <Layer {...selectionStyle} filter={selectionFilter} />
      <Layer {...labelStyle} />
    </Source>
  );
};

export default TrackLayer;
