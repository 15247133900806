import React from "react";
import "./index.css";

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Icon,
} from "@mui/material";
import i18n from "i18next";
import moment from "moment";

const checkIfEventStack = (event, events, clickDate) => {
  let stackedEvents = [event.uuid];
  if (events.length > 1) {
    for (let ev of events) {
      if (ev.uuid !== event.uuid) {
        if (ev.isForNow) {
          if (moment(ev.startTime).isBefore(clickDate)) {
            stackedEvents.push(ev.uuid);
          }
        } else {
          if (
            moment(ev.startTime).isBefore(clickDate) &&
            moment(ev.endTime).isAfter(clickDate)
          ) {
            stackedEvents.push(ev.uuid);
          }
        }
      }
    }
  }
  return stackedEvents;
};

const HoverContainer = ({ events }) => {
  const div = events.map((value, index) => {
    let newClsName = "event-hover";
    if (value.eventType === "maintenance") {
      newClsName = "event-hover " + value.criticality + " " + value.isForNow;
    } else {
      newClsName = "event-hover " + value.eventType + " " + value.isForNow;
    }

    return (
      <List key={index + "-" + value.uuid}>
        <ListItem>
          <ListItemAvatar>
            <Icon className={newClsName}>stop</Icon>
          </ListItemAvatar>
          <ListItemText>
            {value.description
              ? value.description
              : i18n.t("eventHover.descriptionMissing")}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar />
          {value.isForNow ? (
            <ListItemText>
              {new Date(value.startTime).toLocaleString()}-
            </ListItemText>
          ) : (
            <ListItemText>
              {new Date(value.startTime).toLocaleString()}-
              {new Date(value.endTime).toLocaleString()}
            </ListItemText>
          )}
        </ListItem>
      </List>
    );
  });

  return div;
};

const EventHoverTooltip = (props) => {
  const { event, events, hoverDate } = props;
  const stackedEvents = checkIfEventStack(event, events, hoverDate);
  let hoveredEvents = [];
  if (stackedEvents.length > 1) {
    for (const evt of stackedEvents) {
      const index = events.map((o) => o.uuid).indexOf(evt);
      if (index !== -1) {
        hoveredEvents.push(events[index]);
      }
    }
  } else {
    hoveredEvents.push(event);
  }

  return <HoverContainer events={hoveredEvents} />;
};

export default EventHoverTooltip;
