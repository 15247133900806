import { useEffect, useState } from "react";
import mqtt from "mqtt";
import MqttConfig from "./mqttConfig.js";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../store/actions/index";
import permissionParser from "../utils/permissionParser";

const HookMqtt = () => {
  const dispatch = useDispatch();

  const [testClient, setTestClient] = useState(null);
  const [isSubed, setIsSub] = useState(false);

  const connectStatus = useSelector((state) => state.mqtt.connectStatus);

  const mqttConnect = (
    host = MqttConfig.url,
    mqttOption = MqttConfig.options
  ) => {
    dispatch(actions.storeStatus("connecting"));
    try {
      const parsedEmail = permissionParser.parseMqttEmail();
      const parsedPass = permissionParser.parseMqttPassword();
      mqttOption.username =
        parsedEmail && parsedPass
          ? parsedEmail
          : process.env.REACT_APP_MQTT_USER;
      mqttOption.password =
        parsedEmail && parsedPass
          ? parsedPass
          : process.env.REACT_APP_MQTT_PASS;
      let cl = mqtt.connect(host, mqttOption);
      setTestClient(cl);
    } catch (err) {
      console.error(err);
    }
  };

  const mqttSub = (subscription) => {
    if (testClient) {
      const { topic, qos } = subscription;
      testClient.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        } else {
          console.log("MQTT Client subscribed");
          setIsSub(true);
        }
      });
    }
  };

  // Separate init with timeout to let map load
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (connectStatus === "connect") {
        console.log("MQTT Client connecting");
        mqttConnect();
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (testClient) {
      testClient.on("connect", () => {
        dispatch(actions.storeStatus("connected"));
        console.log("MQTT Client connected");
        if (!isSubed) {
          mqttSub({ topic: process.env.REACT_APP_MQTT_TOPIC, qos: 0 });
        }
      });
      testClient.on("error", (err) => {
        console.error("Connection error: ", err);
        setIsSub(false);
        testClient.end();
      });
      testClient.on("reconnect", () => {
        dispatch(actions.storeStatus("reconnecting"));
        if (!isSubed) {
          mqttSub({ topic: process.env.REACT_APP_MQTT_TOPIC, qos: 0 });
        }
      });
      testClient.on("message", (topic, message) => {
        try {
          let position = JSON.parse(message);
          if (
            position.distance_to_track >= 0 &&
            position.distance_to_track < 15.0
          ) {
            position.coordinates.lat = position.coord_on_track.lat;
            position.coordinates.lon = position.coord_on_track.lon;
            delete position["coord_on_track"];
          } else {
            position["nearest_track"] = "-";
          }
          dispatch(actions.storePositionMsg(position));
        } catch (err) {
          console.error(err);
          console.error(message);
        }
      });
    }
  }, [testClient]);
};

export default HookMqtt;
