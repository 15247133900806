import { COLUMN_WIDTH } from "../constants";
import moment from "moment";

function convertToMins(dateString) {
  let dateInit;
  // null means that the event param isForNow is true
  if (dateString === null) {
    dateInit = lastDayOfScheduler();
  } else {
    dateInit = new Date(dateString);
  }

  // Adjust if event begins or ends outside of scheduler
  if (moment(dateInit).isSameOrAfter(moment(lastDayOfScheduler()))) {
    dateInit = lastDayOfScheduler();
  } else if (moment(dateInit).isSameOrBefore(moment(firstDayOfScheduler()))) {
    dateInit = firstDayOfScheduler();
  }

  const dateObj = dateInit;
  const eventMonthInfo = monthInfo(dateObj);
  const currentMonthInfo = monthInfo(new Date());
  let offset = 0;

  if (eventMonthInfo.current.num === currentMonthInfo.prevTwo.num) {
    offset = currentMonthInfo.prevThree.days * 24 * 60;
  } else if (eventMonthInfo.current.num === currentMonthInfo.prev.num) {
    offset =
      (currentMonthInfo.prevThree.days + currentMonthInfo.prevTwo.days) *
      24 *
      60;
  } else if (eventMonthInfo.current.num === currentMonthInfo.current.num) {
    offset =
      (currentMonthInfo.prevThree.days +
        currentMonthInfo.prevTwo.days +
        currentMonthInfo.prev.days) *
      24 *
      60;
  } else if (eventMonthInfo.current.num === currentMonthInfo.next.num) {
    offset =
      (currentMonthInfo.prevThree.days +
        currentMonthInfo.prevTwo.days +
        currentMonthInfo.prev.days +
        currentMonthInfo.current.days) *
      24 *
      60;
  } else if (eventMonthInfo.current.num === currentMonthInfo.nextTwo.num) {
    offset =
      (currentMonthInfo.prevThree.days +
        currentMonthInfo.prevTwo.days +
        currentMonthInfo.prev.days +
        currentMonthInfo.current.days +
        currentMonthInfo.next.days) *
      24 *
      60;
  } else if (eventMonthInfo.current.num === currentMonthInfo.nextThree.num) {
    offset =
      (currentMonthInfo.prevThree.days +
        currentMonthInfo.prevTwo.days +
        currentMonthInfo.prev.days +
        currentMonthInfo.current.days +
        currentMonthInfo.next.days +
        currentMonthInfo.nextTwo.days) *
      24 *
      60;
  }

  return (
    offset +
    dateObj.getDate() * 24 * 60 +
    dateObj.getHours() * 60 +
    dateObj.getMinutes() +
    dateObj.getSeconds() / 60
  );
}

export function firstDayOfScheduler() {
  let date = new Date();
  let month = date.getMonth() - 3;
  date.setMonth(month);
  let year = date.getFullYear();
  // first month, first day, first hour, first minute
  let newDate = new Date(year, month, 1, 0, 0);
  newDate.setFullYear(year);
  return newDate;
}

export function lastDayOfScheduler() {
  let date = new Date();
  let month = date.getMonth() + 3;
  date.setMonth(month);
  let year = date.getFullYear();
  // last month, last day, last hour, last minute
  return new Date(year, month, daysInMonth(year, month), 23, 59);
}

export function daysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

/**
 * This function takes a dateObj as an input parameter, which is expected to be a JavaScript Date object.
 * It returns an object with four properties:
 *    dod: The day of the week (0 for Sunday, 1 for Monday, etc.) of the date represented by dateObj.
 *    num: The month (0 for January, 1 for February, etc.) of the date represented by dateObj.
 *    days: The number of days in the month of the date represented by dateObj.
 *    year: The year of the date represented by dateObj.
 */
export function createObj(dateObj) {
  return {
    dod: dateObj.getDay(),
    num: dateObj.getMonth(),
    days: daysInMonth(dateObj.getFullYear(), dateObj.getMonth()),
    year: dateObj.getFullYear(),
  };
}

/**
 * This function takes a dateObj as an input parameter, which is expected to be a JavaScript Date object.
 * It creates seven more Date objects representing the first day of the month for the previous three months, the current month, and the next three months.
 * Then it calls the createObj function on each of these Date objects to create seven objects representing information about the previous three months, the current month, and the next three months.
 * It returns an object with these seven objects as properties, each named according to the number of months before or after the current month that it represents.
 */
export function monthInfo(dateObj) {
  const copyOfCurrent = new Date(dateObj.getTime());
  let prevObj = new Date(dateObj.getTime());
  let prevTwoObj = new Date(dateObj.getTime());
  let prevThreeObj = new Date(dateObj.getTime());
  let nextObj = new Date(dateObj.getTime());
  let nextTwoObj = new Date(dateObj.getTime());
  let nextThreeObj = new Date(dateObj.getTime());

  copyOfCurrent.setMonth(dateObj.getMonth(), 1);
  prevObj.setMonth(dateObj.getMonth() - 1, 1);
  prevTwoObj.setMonth(dateObj.getMonth() - 2, 1);
  prevThreeObj.setMonth(dateObj.getMonth() - 3, 1);
  nextObj.setMonth(dateObj.getMonth() + 1, 1);
  nextTwoObj.setMonth(dateObj.getMonth() + 2, 1);
  nextThreeObj.setMonth(dateObj.getMonth() + 3, 1);

  return {
    prevThree: createObj(prevThreeObj),
    prevTwo: createObj(prevTwoObj),
    prev: createObj(prevObj),
    current: createObj(copyOfCurrent),
    next: createObj(nextObj),
    nextTwo: createObj(nextTwoObj),
    nextThree: createObj(nextThreeObj),
  };
}

export function getEventPositionalProps(startTime, endTime) {
  // Convert
  const start = convertToMins(startTime);
  const end = convertToMins(endTime);
  const diff = end - start;
  const minDistance = COLUMN_WIDTH / 24 / 60;

  let left = 0;
  if (diff < 0) {
    left = end * minDistance - COLUMN_WIDTH;
  } else {
    left = start * minDistance - COLUMN_WIDTH;
  }

  const left_offset = left / COLUMN_WIDTH;
  const width = Math.abs(diff * minDistance);
  const width_offset = width / COLUMN_WIDTH;

  return {
    x: left - left_offset,
    width: width - width_offset,
    diff,
  };
}

export function getTodayPosition() {
  const startTime = new Date();
  const endTime = new Date();
  startTime.setHours(0);
  endTime.setHours(0);
  startTime.setMinutes(0);
  endTime.setMinutes(0);
  const { x } = getEventPositionalProps(startTime, endTime);
  return x;
}
