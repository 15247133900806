import * as React from "react";
import { useSelector } from "react-redux";
import { Source, Layer } from "react-map-gl";
import { useEffect } from "react";
import { useState } from "react";

// put wide transparent lines under actual tracks
// this helps users to select a track
const hitBufferStyle = {
  id: "restrictedLayer-hitbuffer",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "transparent",
    "line-width": 15,
  },
};

const lineStyle = {
  id: "restrictedLayer-lines",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "rgb(255, 195, 0)",
    "line-width": 3,
  },
};

const labelStyle = {
  id: "restrictedLayer-labels",
  type: "symbol",
  minzoom: 12,
  filter: ["==", "$type", "LineString"],
  layout: {
    "symbol-placement": "line",
    "text-font": ["Noto Sans Bold"],
    "text-field": "{trackName}",
    "text-size": ["interpolate", ["linear"], ["zoom"], 12, 11, 20, 15],
  },
  //PRX-101 show only black text without halo
  //Note, changed halo from blue / 2.75 to white / 2. this change is commented on JIRA
  paint: {
    "text-color": "#000000", //"#ffffff",
    "text-halo-color": "#ffffff", //"#0000ff",
    "text-halo-width": 2, //2.75, // max 1/4 of font size
  },
};

const ServiceLocationLayer = () => {
  const trackLayerData = useSelector((s) => s.map.trackLayerData);
  const restrictedTrackFilter = useSelector((s) => s.map.restrictedTrackFilter);
  const finalData = [];
  const features = [];
  const featuresFinal = [];
  const [restricted, setRestricted] = useState();
  useEffect(() => {
    if (restrictedTrackFilter !== undefined) {
      for (let i = 0; i < restrictedTrackFilter[0].length; i++) {
        for (
          let t = 0;
          t < restrictedTrackFilter[0][i].locationTracks.length;
          t++
        ) {
          features.push(
            trackLayerData.features.filter(function (k) {
              return (
                k.properties.siteId === restrictedTrackFilter[0][i].siteId &&
                k.properties.trackName ===
                  restrictedTrackFilter[0][i].locationTracks[t].trackName
              );
            })
          );
        }
      }
      for (let i = 0; i < features.length; i++) {
        if (features[i].length !== 0) {
          featuresFinal.push(features[i][0]);
        }
      }
      finalData.push({
        crs: trackLayerData.crs,
        features: featuresFinal,
        name: trackLayerData.name,
        type: trackLayerData.type,
      });
      setRestricted(finalData[0]);
    }
  }, [restrictedTrackFilter]);

  if (restricted !== undefined) {
    return (
      <Source id="restrictedLayer" type="geojson" data={restricted}>
        <Layer {...hitBufferStyle} />
        <Layer {...lineStyle} />
        <Layer {...labelStyle} />
      </Source>
    );
  } else {
    return <div />;
  }
};

export default ServiceLocationLayer;
