/* eslint-disable no-unused-vars */
import React from "react";
import i18n from "i18next";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import ProxionBackground from "../../../assets/proxion_background.svg";
import ProxionLogo from "../../../assets/prox_x.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { authenticationService } from "../_services";
import { Link } from "react-router-dom";
import "./index.css";

const Logo = styled("img")(() => ({
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "1rem",
  width: "100%",
  maxHeight: "20vh",
  height: "auto",
}));

const InvalidFeedback = styled("div")(({ theme }) => ({
  display: "block",
  marginTop: "0.25rem",
  fontSize: { sm: "1rem", xs: "0.875rem" },
  color: theme.palette.common.red,
}));

const FormikField = styled(Field)(({ theme }) => ({
  display: "block",
  width: "calc(100% - 1rem)",
  height: "calc(1em + 0.5rem + 2px)",
  padding: "0.25rem 0.5rem",
  fontFamily: "inherit",
  fontSize: { sm: "1rem", xs: "0.875rem" },
  fontWeight: "400",
  lineHeight: 1.5,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.proxBorderGrey}`,
  borderRadius: "0.25rem",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
}));

const FormGroup = styled("div")(() => ({
  marginBottom: "1rem",
}));

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      const { from } = this.props.history.state || {
        from: { pathname: "/" },
      };
      this.props.history.push(from);
    }
  }

  state = {
    //PRX-526 - state is needed for functionality
    //remember: true,
    username: "",
  };

  render() {
    const version = process.env.REACT_APP_VERSION;

    return (
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${ProxionBackground})`,
          backgroundSize: "100% 100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: { md: "32rem", sm: "24rem", xs: "16rem" },
            fontSize: { sm: "1rem", xs: "0.875rem" },
          }}
        >
          <Logo alt="Logo" src={ProxionLogo} />
          <Formik
            initialValues={{
              username: this.state.username,
              password: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .nullable()
                .email()
                .required(i18n.t("loginPage.usernameRequired.text")),
              password: Yup.string().required(
                i18n.t("loginPage.passwordRequired.text")
              ),
            })}
            onSubmit={(
              { username, password },
              { setStatus, setSubmitting }
            ) => {
              setStatus();
              //PRX-526
              // authenticationService.login(username, password, this.state.remember).then(
              authenticationService.login(username, password, true).then(
                // eslint-disable-next-line no-unused-vars
                (user) => {
                  const { from } = this.props.history.state || {
                    from: { pathname: "/" },
                  };
                  this.props.history.push(from);
                },
                (error) => {
                  setSubmitting(false);
                  setStatus(error);
                }
              );
            }}
          >
            {({ errors, status, touched, isSubmitting }) => {
              let errorMsg = i18n.t("loginPage.loginError");
              if (status === "Unauthorized") {
                errorMsg = i18n.t("loginPage.checkCredentials");
              }

              return (
                <Form>
                  <FormGroup>
                    <FormikField
                      id="username"
                      name="username"
                      type="text"
                      className={
                        "form-control" +
                        (errors.username && touched.username
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label htmlFor="username">
                      {i18n.t("loginPage.username.text")}
                    </label>
                    <ErrorMessage name="username">
                      {() => (
                        <InvalidFeedback>
                          {i18n.t("loginPage.validEmail")}
                        </InvalidFeedback>
                      )}
                    </ErrorMessage>
                  </FormGroup>
                  <FormGroup>
                    <FormikField
                      id="password"
                      name="password"
                      type="password"
                      className={
                        "form-control" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label htmlFor="password">
                      {i18n.t("loginPage.password.text")}
                    </label>
                    <ErrorMessage name="password" component={InvalidFeedback} />
                  </FormGroup>
                  <FormGroup id="button" align="right">
                    <Button
                      id="login-button"
                      variant="contained"
                      type="submit"
                      sx={{
                        fontFamily: "inherit",
                        fontWeight: 600,
                        borderRadius: "1rem",
                        backgroundColor: (theme) =>
                          theme.palette.common.proxBlue,
                        color: (theme) => theme.palette.common.white,
                        padding: "0.325rem 1rem",
                        fontSize: "0.75rem",
                      }}
                      disabled={isSubmitting}
                    >
                      {i18n.t("loginPage.login.text")}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size="1.96rem"
                        sx={{
                          position: "absolute",
                          left: 0,
                        }}
                      />
                    )}
                  </FormGroup>
                  {status && (
                    <Alert
                      severity="error"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.transparent.red,
                        border: "1px solid transparent",
                        borderRadius: "0.25rem",
                        fontSize: { sm: "1rem", xs: "0.875rem" },
                        marginBottom: "1rem",
                        padding: "0.75rem 1.25rem",
                        position: "relative",
                      }}
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <Link id="forgot-password-link" to="/forgotpassword">
                    {i18n.t("loginPage.forgotPassword.text")}
                  </Link>
                  <Link
                    id="user-manual-link"
                    to={{
                      pathname:
                        "https://www.proxion.fi/ratkaisumme/prox-rail/ukk/",
                    }}
                    target="_blank"
                  >
                    {i18n.t("navigation.userManual.text")}
                  </Link>
                  {version && (
                    <Typography
                      id="software-version"
                      sx={{
                        color: (theme) => theme.palette.common.proxOrange,
                        marginTop: "0.5rem",
                        fontSize: "0.75rem",
                      }}
                    >
                      {i18n.t("loginPage.softwareVersion")} {version}
                    </Typography>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    );
  }
}

export default LoginPage;
