import axios from "axios";
import jwtDecode from "jwt-decode";
import { authToken, logOut } from "../components/Auth/_helpers";

const BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    if (authToken()) {
      config.headers["Authorization"] = `Bearer ${authToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    //  Retry token on HTTP 403 FORBIDDEN
    // https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
    const originalRequest = err.config;
    if (err.response.status === 403 && !originalRequest._retry && authToken()) {
      originalRequest._retry = true;
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken()}`;
      return api(originalRequest);
    } else if (err.response.status === 403 && originalRequest._retry) {
      if (jwtDecode(authToken()).exp < Date.now() / 1000) {
        logOut();
        console.log("session expires!");
      }
    } else if (!authToken()) {
      logOut();
      console.log("session expires!");
    }

    console.log("api error: ", err.response);
    return Promise.reject(err);
  }
);

export default api;
