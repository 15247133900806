import * as actionTypes from "./actionTypes";

export const setMapBounds = (payload) => {
  return {
    type: actionTypes.SET_MAP_BOUNDS,
    payload,
  };
};

export const setViewState = (payload) => {
  return {
    type: actionTypes.SET_VIEW_STATE,
    payload,
  };
};

export const setMapStyle = (payload) => {
  return {
    type: actionTypes.SET_MAP_STYLE,
    payload,
  };
};

export const setTrackLayerData = (payload) => {
  return {
    type: actionTypes.SET_TRACK_LAYER_DATA,
    payload,
  };
};

export const setPointLayerData = (payload) => {
  return {
    type: actionTypes.SET_POINT_LAYER_DATA,
    payload,
  };
};

export const mapSelectFeature = (payload) => {
  return {
    type: actionTypes.MAP_SELECT_FEATURE,
    payload,
  };
};

export const mapDeselectFeature = () => {
  return {
    type: actionTypes.MAP_DESELECT_FEATURE,
  };
};

export const mapAlarmTracks = (payload) => {
  return {
    type: actionTypes.MAP_ALARM_TRACKS,
    payload,
  };
};

export const mapOtherTracks = (payload) => {
  return {
    type: actionTypes.MAP_OTHER_TRACKS,
    payload,
  };
};

export const mapServiceLocationTracks = (payload) => {
  return {
    type: actionTypes.MAP_SERVICE_LOCATION_TRACKS,
    payload,
  };
};

export const mapTrackCapacityTracks = (payload) => {
  return {
    type: actionTypes.MAP_TRACK_CAPACITY_TRACKS,
    payload,
  };
};

export const mapIsForNowAlarm = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_ALARM_TRACKS,
    payload,
  };
};

export const mapIsForNowOther = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_OTHER_TRACKS,
    payload,
  };
};

export const mapIsForNowServiceLocation = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_SERVICE_LOCATION_TRACKS,
    payload,
  };
};

export const mapIsForNowTrackCapacity = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_TRACK_CAPACITY_TRACKS,
    payload,
  };
};

export const mapStopTracks = (payload) => {
  return {
    type: actionTypes.MAP_STOP_TRACKS,
    payload,
  };
};

export const mapRestrictedTracks = (payload) => {
  return {
    type: actionTypes.MAP_RESTRICTED_TRACKS,
    payload,
  };
};

export const mapIsForNowStop = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_STOP_TRACKS,
    payload,
  };
};

export const mapIsForNowRestricted = (payload) => {
  return {
    type: actionTypes.MAP_ISFORNOW_RESTRICTED_TRACKS,
    payload,
  };
};

export const followUnit = (payload) => {
  return {
    type: actionTypes.FOLLOW_UNIT,
    payload,
  };
};
