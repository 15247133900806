import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import NavigationMenu from "../NavigationMenu";
import Scheduler from "../../../Scheduler";
import FilterInfo from "../../FilterInfo";
import FollowInfo from "../../FollowInfo";
import MiniScheduler from "../../../MiniScheduler";
import MapChooser from "../../../Map/MapChooser";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
// import { isBrowser, isMobile } from "react-device-detect";
import useMediaQuery from "@mui/material/useMediaQuery";
const miniSchedulerHeight = 110;
const offSetForMobile = miniSchedulerHeight + 56;

const ViewChooser = ({ view, setView, showViewAction, siteId }) => {
  const schedulerRef = useRef(null);
  const followedUnit = useSelector((s) => s.map.followUnit);
  const isFiltered = useSelector((state) => state.userinterface.isFiltered);
  const startFilter = useSelector((state) => state.userinterface.startFilter);
  const endFilter = useSelector((state) => state.userinterface.endFilter);
  const openview = useSelector((state) => state.userinterface.openview);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { height } = useWindowDimensions();

  return (
    <>
      {/* This view we would like to see on both mobile & desktop */}
      {[
        "eventInfoEditFull",
        "eventInfoCreateFull",
        "filterListFull",
        "eventListFull",
        "eventInfoEditHalf",
        "eventInfoCreateHalf",
        "filterListHalf",
        "eventListHalf",
        "movingObjects",
      ].includes(openview) && (
        <Box
          id="navigation-menu-wrapper"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: {
              xs: "100%",
              // sm: "100%",
              md: "440px",
            },
            // width: "100%",

            // maxWidth: theme.breakpoints.values.md,
            // overflow: "scroll",
            overflow: "hidden",
            // overflowX: "hidden",
            // overflowY: "hidden",
          }}
        >
          <NavigationMenu
            view={view}
            setView={setView}
            showViewAction={showViewAction}
          />
        </Box>
      )}

      {/* Desktop views */}

      {matches && (
        <Box
          id="desktop-view-chooser-main"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {["map", "movingObjects"].includes(openview) && (
            <>
              <Box
                id="desktop-map-wrapper"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: `calc(100% - ${miniSchedulerHeight}px)`,
                }}
              >
                {isFiltered && (
                  <FilterInfo start={startFilter} end={endFilter} />
                )}
                {followedUnit && (
                  <FollowInfo
                    followedUnit={followedUnit}
                    isFiltered={isFiltered}
                  />
                )}
                <MapChooser view={openview} desktop={true} siteId={siteId} />
              </Box>
              <Box
                id="desktop-minischeduler-wrapper"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: `${miniSchedulerHeight}px`,
                }}
              >
                <MiniScheduler
                  showViewAction={showViewAction}
                  openview={openview}
                />
              </Box>
            </>
          )}
          {[
            "events",
            "eventInfoEditFull",
            "eventInfoCreateFull",
            "filterListFull",
            "eventListFull",
          ].includes(openview) && (
            <Box
              id="desktop-scheduler-wrapper"
              sx={{
                height: "100%",
              }}
            >
              <Scheduler
                showViewAction={showViewAction}
                openview={openview}
                schedulerRef={schedulerRef}
              />
            </Box>
          )}
          {[
            "halfevents",
            "eventInfoEditHalf",
            "eventInfoCreateHalf",
            "filterListHalf",
            "eventListHalf",
          ].includes(openview) && (
            <>
              <Box
                id="desktop-half-scheduler-wrapper"
                sx={{
                  height: "50%",
                }}
              >
                <MapChooser view={openview} desktop={true} siteId={siteId} />
              </Box>
              <Box
                sx={{
                  height: "50%",
                }}
              >
                <Scheduler
                  showViewAction={showViewAction}
                  openview={openview}
                  schedulerRef={schedulerRef}
                />
              </Box>
            </>
          )}
        </Box>
      )}
      {/* Mobile views */}
      {!matches && (
        <Box
          id="mobile-view-chooser-main"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {["map", "movingObjects"].includes(openview) && (
            <>
              <Box
                id="mobile-map-wrapper"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: `calc(${height}px - ${offSetForMobile}px)`,
                }}
              >
                {isFiltered && (
                  <FilterInfo start={startFilter} end={endFilter} />
                )}
                {followedUnit && (
                  <FollowInfo
                    followedUnit={followedUnit}
                    isFiltered={isFiltered}
                  />
                )}
                <MapChooser view={openview} desktop={true} siteId={siteId} />
              </Box>
              <Box
                id="mobile-mini-scheduler-wrapper"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: `${miniSchedulerHeight}px`,
                }}
              >
                <MiniScheduler
                  showViewAction={showViewAction}
                  openview={openview}
                />
              </Box>
            </>
          )}
          {openview === "events" && (
            <Box
              id="mobile-scheduler-wrapper"
              sx={{
                height: "100%",
              }}
            >
              <Scheduler
                showViewAction={showViewAction}
                openview={openview}
                schedulerRef={schedulerRef}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ViewChooser;
