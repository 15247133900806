import * as actionTypes from "../actions/actionTypes";

const initialState = {
  connectStatus: "connect",
  position: {},
  allPositions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Connectivity status
    case actionTypes.MQTT_STATUS: {
      return {
        ...state,
        connectStatus: action.connectStatus,
      };
    }
    // Position
    case actionTypes.MQTT_POSITION: {
      const copyPositions = [...state.allPositions];
      const deviceIds = copyPositions.map((e) => {
        return e.deliveryStatus.sentBy;
      });
      const index = deviceIds.indexOf(action.position.deliveryStatus.sentBy);
      if (index === -1) {
        copyPositions.push(action.position);
      } else {
        copyPositions.splice(index, 1, action.position);
      }
      return {
        ...state,
        position: action.position,
        allPositions: copyPositions,
      };
    }

    default:
      return state;
  }
};

export default reducer;
