import React from "react";
import i18n from "i18next";
import "./index.css";
import { Flex } from "../../../../../../Layout";
import { COLUMN_WIDTH } from "../../../../../../../../constants";

const Column = React.forwardRef(function DayColumn(
  { day, date, month, ...rest },
  ref
) {
  const Hours = React.useCallback(() => {
    let x = [];
    for (let i = 0; i <= 24; i++) {
      if (i % 2 !== 0) {
        x.push(
          <span key={i} className="hour-label">
            {i < 10 ? "0" + i : i}
          </span>
        );
      } else {
        x.push(<span key={i} className="hour-label"></span>);
      }
    }

    return x;
  }, []);

  const dateString = React.useMemo(() => {
    return date < 10 ? "0" + date : date;
  }, [date]);
  const monthString = React.useMemo(() => {
    return month < 10 ? "0" + month : month;
  }, [month]);

  return (
    <Flex
      key={`${day}_${date}_${month}`}
      ref={ref}
      justify="flex-start"
      self="stretch"
      className="column"
      style={{ flex: "0 0 " + (COLUMN_WIDTH - 1) + "px" }}
      {...rest}
    >
      <span className="day-label">
        {i18n.t(`schedulerPage.daySymbols.${day}`)} {dateString}.{monthString}.
      </span>
      <Flex direction="row" justify="space-between" className="hours-cont">
        <Hours />
      </Flex>
      {/* <Flex
        direction="row"
        justify="space-between"
        className="hours-cont"
        style={{ marginTop: "auto" }}
      >
        <Hours />
      </Flex> */}
    </Flex>
  );
});

export default Column;
