import axiosEvent from "../utils/axiosEvent";

const API_URL = "/site/";
const EVENTS = "/events";
const ALL_SITES = `${API_URL}events`;
const LOCK = "/events/lock/";
const EVENT = "/events/";
const HISTORY = "/history/";
const ORGFIELD = "/orgfield/";

export const lockEvent = async (eventId) => {
  const url = `${LOCK}${eventId}`;
  return await axiosEvent.post(url);
};

export const getEventsForListedSites = async (
  sites,
  startDate,
  endDate,
  lastFetch
) => {
  const url = lastFetch
    ? `${ALL_SITES}?sites=${sites}&startTime=${startDate}&endTime=${endDate}&lastFetch=${lastFetch}`
    : `${ALL_SITES}?sites=${sites}&startTime=${startDate}&endTime=${endDate}`;
  return await axiosEvent.get(url);
};

export const getEvents = async (uuid, startDate, endDate) => {
  const url = `${API_URL}${uuid}${EVENTS}?startTime=${startDate}&endTime=${endDate}`;
  return await axiosEvent.get(url);
};

export const postEvents = async (siteId, data) => {
  const url = `${API_URL}${siteId}${EVENTS}`;
  return await axiosEvent.post(url, data);
};

export const editEvents = async (siteId, eventId, data) => {
  const url = `${API_URL}${siteId}${EVENT}${eventId}`;
  return await axiosEvent.put(url, data);
};

export const getEventById = async (uuid, siteId) => {
  const url = `${API_URL}${siteId}${EVENT}${uuid}`;
  return await axiosEvent.get(url);
};

export const deleteEvent = async (siteId, eventId) => {
  const url = `${API_URL}${siteId}${EVENT}${eventId}`;
  return await axiosEvent.delete(url);
};

export const postEventMessage = async (siteId, eventId, data) => {
  const url = `${API_URL}${siteId}${EVENT}${eventId}/`;
  return await axiosEvent.post(url, data);
};

export const getEventMessages = async (siteId, eventId) => {
  const url = `${API_URL}${siteId}${EVENT}${eventId}/`;
  return await axiosEvent.get(url);
};

export const getEventHistory = async (siteId, eventId) => {
  const url = `${API_URL}${siteId}${HISTORY}${eventId}`;
  return await axiosEvent.get(url);
};

export const getOrgFields = async (orgId) => {
  const url = `${ORGFIELD}${orgId}`;
  return await axiosEvent.get(url);
};
