import { combineReducers } from "redux";

import userInterfaceReducer from "./userinterface";
import map from "./map";
import mqtt from "./mqtt";
import permissions from "./permissions";

const rootReducer = combineReducers({
  userinterface: userInterfaceReducer,
  map: map,
  mqtt: mqtt,
  permissions: permissions,
});

export default (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);
