import React from "react";
import "./index.css";
import i18n from "i18next";
import moment from "moment";
import { Icon, Typography } from "@mui/material";

// eslint-disable-next-line no-unused-vars
const FilterInfo = ({ start, end, panelLeft }) => {
  const formattedStart = moment(start).toDate().toLocaleString();
  const formattedEnd = moment(end).toDate().toLocaleString();

  return (
    // <div className="filter-info-panel" style={{ left: panelLeft }}>
    <div className="filter-info-panel">
      <Icon className="filter-icon">{i18n.t("filterInfo.icon")}</Icon>
      <Typography variant="h6" className="filter-title">
        {i18n.t("filterInfo.title")}
      </Typography>
      <Typography variant="body2" className="filter-title">
        {i18n.t("filterInfo.details")}
      </Typography>
      <Typography
        variant="body2"
        className="filter-title"
      >{`${formattedStart} - ${formattedEnd}`}</Typography>
    </div>
  );
};

export default FilterInfo;
