import React from "react";
import i18n from "i18next";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { roundToClosest15 } from "../../../../../../utils/times";
import { getCorrectLocale } from "../../../../../../utils/localeHelper";

registerLocale(i18n.language, getCorrectLocale());

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    width: "100%",
    marginTop: "0px",
    backgroundColor: theme.palette.common.white,
    padding: "0px 0px 0px 0px",
  },
  column1: {
    display: "table",
    float: "left",
    marginTop: "0px",
  },
  column2: {
    display: "table",
    float: "right",
    marginTop: "0px",
  },
  timeTitle: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  timeTitleGrey: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    marginTop: "4px !important",
    marginLeft: "8px !important",
    color: "rgba(0, 0, 0, 0.5)",
  },
  timePicker: {
    marginTop: "4px !important",
    marginBottom: "16px !important",
  },
  timePickerSmall: {
    marginTop: "34px !important",
    marginLeft: "18px !important",
    width: "100%",
  },
  switchBox: {
    marginLeft: "50px",
    marginRight: "0px !important",
    marginTop: "-2px !important",
  },
  switchBoxText: {
    fontSize: "12px !important",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    marginTop: "18px !important",
  },
  inputBox: {
    fontSize: "16px",
    fontWeight: 400,
    padding: "5px 5px 5px 10px",
    borderWidth: "1px",
    borderColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
  },
  inputBoxSmall: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    padding: "5px 5px 5px 5px",
    borderWidth: "1px",
    borderColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    "& .MuiInputLabel-root": {
      zIndex: 0,
    },
  },
}));

const DateTimePicker = (props) => {
  const classes = useStyles();
  const {
    startTime,
    endTime,
    isForNow,
    duration,
    isEditable,
    lockedForEdit,
    validDuration,
    setValidDuration,
  } = props;

  const handleStartChange = (date) => {
    props.handleChangeStart(date);
  };

  const handleEndChange = (date) => {
    props.handleChangeEnd(date);
  };

  const handleIsForNow = (event) => {
    props.handleIsForNow(event.target.checked);
  };

  const handleDurationChange = (event) => {
    const newDuration = event.target.value;
    const durationCopy = { ...duration };
    const newDurationMins = moment.duration(newDuration).asMinutes();
    durationCopy["uiFormat"] = newDuration;
    durationCopy["apiFormat"] = newDurationMins;
    props.onDurationChange(durationCopy);
    if (newDuration.length >= 5 && startTime !== null) {
      setValidDuration(true);
      const newEndDate = moment(startTime, "DD.MM.YYYY HH:mm")
        .add(newDurationMins, "minutes")
        .format("DD.MM.YYYY HH:mm");
      const endDtObj = moment(newEndDate, "DD.MM.YYYY HH:mm").toDate();
      const newEnd = roundToClosest15(endDtObj);
      props.handleChangeEnd(newEnd);
    } else {
      setValidDuration(false);
    }
  };

  const durationOk = {
    backgroundColor: "white",
  };

  const durationError = {
    backgroundColor: "rgba(255, 0, 0, 0.2)",
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column1" className={classes.column1}>
        <Typography className={classes.timeTitle}>
          {i18n.t("navigation.eventInfo.startTime")}
        </Typography>
        <div className={classes.timePicker}>
          <DatePicker
            id="start-date-picker"
            disabled={!isEditable || !lockedForEdit}
            selected={startTime}
            onChange={handleStartChange}
            className={classes.inputBox}
            locale={i18n.language}
            showTimeSelect
            dateFormat="dd.MM.yyyy HH:mm"
            timeCaption={i18n.t("navigation.eventInfo.eventTime")}
            timeIntervals={15}
          />
          <Typography className={classes.timeTitleGrey}>
            {i18n.t("navigation.eventInfo.mandatory")}
          </Typography>
        </div>
        <Typography className={classes.timeTitle}>
          {i18n.t("navigation.eventInfo.endTimeText")}
        </Typography>
        <div className={classes.timePicker}>
          {isEditable ? (
            <DatePicker
              id="end-date-picker"
              selected={endTime}
              disabled={isForNow || !lockedForEdit}
              onChange={handleEndChange}
              minDate={startTime}
              className={classes.inputBox}
              locale={i18n.language}
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              timeCaption={i18n.t("navigation.eventInfo.eventTime")}
              timeIntervals={15}
            />
          ) : (
            <DatePicker
              id="end-date-picker"
              selected={endTime}
              disabled={true}
              onChange={handleEndChange}
              minDate={startTime}
              className={classes.inputBox}
              locale={i18n.language}
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              timeCaption={i18n.t("navigation.eventInfo.eventTime")}
              timeIntervals={15}
            />
          )}

          <Typography className={classes.timeTitleGrey}>
            {i18n.t("navigation.eventInfo.mandatoryLong")}
          </Typography>
        </div>
      </div>
      <div id="column2" className={classes.column2}>
        <div className={classes.timePicker}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                classes={{
                  root: classes.switchBox,
                  label: classes.switchBoxText,
                }}
                value="top"
                control={
                  <Switch
                    id="is-for-now-switch"
                    color="primary"
                    checked={isForNow}
                    onChange={handleIsForNow}
                    disabled={!isEditable || !lockedForEdit}
                  />
                }
                label={i18n.t("navigation.eventInfo.switchText")}
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className={classes.timePickerSmall}>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 0, width: "103px" },
            }}
            noValidate
            autoComplete="off"
          >
            {isEditable ? (
              <TextField
                id="event-duration-box"
                size="small"
                className={classes.inputBoxSmall}
                classes={{ root: classes.formControlRoot }}
                label="hh:mm"
                value={isForNow ? "00:00" : duration.uiFormat}
                variant="outlined"
                inputProps={{ maxLength: 12 }}
                disabled={isForNow || !lockedForEdit}
                onChange={handleDurationChange}
                style={validDuration ? durationOk : durationError}
              />
            ) : (
              <TextField
                id="event-duration-box"
                size="small"
                className={classes.inputBoxSmall}
                classes={{ root: classes.formControlRoot }}
                label="hh:mm"
                value={isForNow ? "00:00" : duration.uiFormat}
                variant="outlined"
                inputProps={{ maxLength: 12 }}
                disabled={true}
                onChange={handleDurationChange}
                style={validDuration ? durationOk : durationError}
              />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
