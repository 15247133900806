import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Chip,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import "../index.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 338,
    [theme.breakpoints.down("md")]: {
      width: "73vw",
    },
  },
}));

const ArrivalTrainRow = (props) => {
  const { arrivalTrain, onChangeArrivalTrain, trains, menu } = props;
  const classes = useStyles();
  return (
    <div id="event-arrival-row" className="rowSmall">
      <Typography className="targetTextTitle2">
        {i18n.t("navigation.eventInfoTargets.trainArrival")}
      </Typography>
      <div className="columnLargeRight">
        <FormControl sx={{ m: 1 }} classes={{ root: classes.formControl }}>
          <Select
            id="event-arrival-select"
            multiple
            value={arrivalTrain}
            onChange={onChangeArrivalTrain}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value.uuid} label={value.trainNumber} />
                ))}
              </Box>
            )}
            MenuProps={menu}
          >
            {trains.length > 0 ? (
              trains.map((train) => (
                <MenuItem key={train.uuid} value={train}>
                  {train.trainNumber}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                {i18n.t("navigation.eventInfoTargets.waitingForTrains")}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div className="columnSmallLeft">
        <Icon className="trainIconStyleLeft">
          {i18n.t("navigation.eventInfoIcons.train")}
        </Icon>
      </div>
    </div>
  );
};

export default ArrivalTrainRow;
