import * as React from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "content",
    width: "100%",
    marginTop: "-3px",
    backgroundColor: theme.palette.common.white,
  },
  panelSummary: {
    backgroundColor: theme.palette.common.proxBlue,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    fontSize: "15px !important",
    fontWeight: "600 !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.proxBlue,
      minHeight: "42px !important",
      maxHeight: "42px !important",
      color: "white",
      fontSize: "13px !important",
      fontWeight: "600 !important",
    },
  },
  column: {
    display: "table",
    float: "left",
  },
  sendButton: {
    color: "white",
  },
  iconButtonPosition: {
    alignItems: "center",
    margin: "0 !important",
  },
  iconButtonBackground: {
    backgroundColor: theme.palette.common.proxBlue,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    width: "32px",
    height: "32px",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    width: "100%",
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  switchBox: {
    float: "right",
    textAlign: "center",
    marginLeft: "50px",
    marginRight: "0px !important",
    marginTop: "-10px !important",
  },
  inputRootText: {
    width: "320px",
    height: "40px",
    marginRight: "4px !important",
    marginLeft: "8px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  row: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    marginBottom: "8px",
    padding: "10px",
    fontSize: "18px",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  cardItem: {
    width: "406px !important",
    border: "none !important",
    boxShadow: "none !important",
    marginLeft: "-16px",
    marginBottom: "-16px",
  },
  title: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: theme.palette.common.black,
  },
  smalltitle: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: theme.palette.common.black,
  },
  text: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: theme.palette.common.black,
  },
  timeTitle: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    color: theme.palette.common.black,
    paddingBottom: "5px",
  },
  chipStop: {
    display: "table-cell",
    float: "left",
    width: "150px",
    height: "50px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
  chipLimited: {
    display: "table-cell",
    float: "right",
    width: "150px",
    height: "50px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
  chipStopText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    "&:active": {
      cursor: "default",
      pointerEvents: "none",
    },
  },
  chipLimitedText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    "&:active": {
      cursor: "default",
      pointerEvents: "none",
    },
  },
  selectedStop: {
    "&&": {
      backgroundColor: "rgba(199, 63, 0, 1) !important",
      color: "white !important",
    },
  },
  selectedLimited: {
    "&&": {
      backgroundColor: "rgba(255, 195, 0, 1) !important",
      color: "black !important",
    },
  },
  inputBox: {
    fontSize: "16px",
    fontWeight: 400,
    padding: "5px 5px 5px 10px",
    borderWidth: "1px",
    borderColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
  },
  timeTitleGrey: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    marginTop: "4px !important",
    marginLeft: "8px !important",
    color: "rgba(0, 0, 0, 0.5)",
    paddingBottom: "5px",
  },
  columnLargeRight: {
    display: "table",
    float: "right",
    width: "90%",
  },
}));

// TODO: Implement this when unread messages supported
// const MessageDiv = (props) => {
//   const classes = props.classes;
//   return (
//     <div id="row" className={classes.row}>
//       {"Viestit"}
//       {/* TODO: Implement Ref for this in FilterList when unread messages supported*/}
//       <div className={classes.text}>
//         {"Vain tapahtumat, joissa on uusia viestejä"}
//         <Switch className={classes.switchBox} color="primary" />
//       </div>
//     </div>
//   );
// };

const EventTypeDiv = (props) => {
  const { classes, pickedTypes, handleClick } = props;

  let trackCapacityReservationStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let serviceLocationReservationStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let alarmStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let maintenanceStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let otherStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  if (pickedTypes.includes("trackcapacityreservation")) {
    trackCapacityReservationStyle = {
      backgroundColor: "rgba(89, 168, 99, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (pickedTypes.includes("servicelocationreservation")) {
    serviceLocationReservationStyle = {
      backgroundColor: "rgba(179, 64, 255, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (pickedTypes.includes("alarm")) {
    alarmStyle = {
      backgroundColor: "rgba(255, 0, 0, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (pickedTypes.includes("maintenance")) {
    maintenanceStyle = {
      backgroundColor: "rgba(241, 177, 67, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (pickedTypes.includes("other")) {
    otherStyle = {
      backgroundColor: "rgba(149, 149, 149, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }

  return (
    <div id="row" className={classes.row}>
      <p>{i18n.t("navigation.filterList.eventType")}</p>
      <Stack spacing={1} alignItems="left">
        <Stack direction="row" spacing={1}>
          <Chip
            style={trackCapacityReservationStyle}
            label={i18n.t("navigation.eventInfoChips.trackcapacityreservation")}
            onClick={() => handleClick("trackcapacityreservation")}
          />
          <Chip
            style={serviceLocationReservationStyle}
            label={i18n.t(
              "navigation.eventInfoChips.servicelocationreservation"
            )}
            onClick={() => handleClick("servicelocationreservation")}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Chip
            style={maintenanceStyle}
            label={i18n.t("navigation.eventInfoChips.maintenance")}
            onClick={() => handleClick("maintenance")}
          />
          <Chip
            style={alarmStyle}
            label={i18n.t("navigation.eventInfoChips.alarm")}
            onClick={() => handleClick("alarm")}
          />
          <Chip
            style={otherStyle}
            label={i18n.t("navigation.eventInfoChips.other")}
            onClick={() => handleClick("other")}
          />
        </Stack>
      </Stack>
    </div>
  );
};

const CriticalityDiv = (props) => {
  const {
    classes,
    useCriticalities,
    criticality,
    handleUseCriticalities,
    handleCriticality,
  } = props;

  return (
    <div id="row" className={classes.row}>
      {i18n.t("navigation.filterList.trafficEffect")}
      <div className={classes.text}>
        {i18n.t("navigation.filterList.filterEffect")}
        <Switch
          className={classes.switchBox}
          color="primary"
          checked={useCriticalities}
          onChange={handleUseCriticalities}
        />
      </div>
      <ToggleButtonGroup
        color="primary"
        value={criticality}
        onChange={handleCriticality}
        disabled={!useCriticalities}
      >
        <ToggleButton
          disableRipple
          classes={{ selected: classes.selectedStop }}
          selectedcolor="rgba(204, 51, 0, 1.0)"
          className={classes.chipStop}
          value="stop"
        >
          <Typography className={classes.chipStopText}>
            {i18n.t("navigation.eventInfoSeverities.stop")}
          </Typography>
        </ToggleButton>
        <ToggleButton
          disableRipple
          classes={{ selected: classes.selectedLimited }}
          selectedcolor="rgba(255, 195, 0, 0.4)"
          className={classes.chipLimited}
          value="restricted"
        >
          <Typography className={classes.chipLimitedText}>
            {i18n.t("navigation.eventInfoSeverities.limited")}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

const DateDiv = (props) => {
  const {
    classes,
    isForNowFilter,
    startTime,
    endTime,
    handleIsForNow,
    handleStartChange,
    handleEndChange,
  } = props;

  return (
    <div id="row" className={classes.row}>
      {i18n.t("navigation.filterList.timePeriod")}
      <div className={classes.text}>
        {i18n.t("navigation.filterList.showIsForNow")}
        <Switch
          className={classes.switchBox}
          color="primary"
          checked={isForNowFilter}
          onChange={handleIsForNow}
        />
        <Typography className={classes.timeTitle}>
          {i18n.t("navigation.eventInfo.startTime")}
        </Typography>
        <DatePicker
          className={classes.inputBox}
          locale={i18n.language}
          showTimeSelect
          selected={startTime}
          onChange={handleStartChange}
          dateFormat="dd.MM.yyyy HH:mm"
          timeCaption={i18n.t("navigation.eventInfo.eventTime")}
          timeIntervals={15}
        />
        <Typography className={classes.timeTitleGrey}>
          {i18n.t("navigation.eventInfo.mandatory")}
        </Typography>
        <Typography className={classes.timeTitle}>
          {i18n.t("navigation.eventInfo.endTime")}
        </Typography>
        <DatePicker
          className={classes.inputBox}
          locale={i18n.language}
          showTimeSelect
          selected={endTime}
          onChange={handleEndChange}
          dateFormat="dd.MM.yyyy HH:mm"
          timeCaption={i18n.t("navigation.eventInfo.eventTime")}
          timeIntervals={15}
        />
        <Typography className={classes.timeTitleGrey}>
          {i18n.t("navigation.eventInfo.mandatory")}
        </Typography>
      </div>
    </div>
  );
};

const TrackDiv = (props) => {
  const { classes, locationTracks, tracks, handleTrackChange } = props;

  return (
    <div id="row" className={classes.row}>
      {i18n.t("navigation.filterList.tracks")}
      <div id="columnLargeLeft" className={classes.columnLargeLeft}>
        <FormControl sx={{ m: 0, width: "100%" }}>
          <Select
            multiple
            value={locationTracks}
            onChange={handleTrackChange}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {tracks.map((track) => (
              <MenuItem key={track} value={track}>
                {track}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const ButtonDiv = (props) => {
  const { classes, reset } = props;

  return (
    <div id="rowNoBorder" className={classes.row}>
      <Button onClick={reset}> {i18n.t("navigation.filterList.reset")}</Button>
    </div>
  );
};

const Filters = (props) => {
  const classes = useStyles();
  const {
    pickedTypes,
    setPickedTypes,
    useCriticalities,
    setUseCriticalities,
    criticality,
    setCriticality,
    isForNowFilter,
    setIsForNowFilter,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    locationTracks,
    setLocationTracks,
    reset,
  } = props;

  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  let tracks = [];
  trackLayerData.features.map((element) => {
    element.properties.trackName && tracks.push(element.properties.trackName);
  });
  tracks.sort();

  const handleClick = (type) => {
    let copy = [...pickedTypes];
    if (copy.includes(type)) {
      copy = copy.filter((item) => item !== type);
    } else {
      copy.push(type);
    }
    setPickedTypes(copy);
  };

  const handleUseCriticalities = (event) => {
    if (!event.target.checked) {
      setCriticality(null);
    }
    setUseCriticalities(event.target.checked);
  };

  const handleCriticality = React.useCallback((event, newValue) => {
    setCriticality(newValue);
  }, []);

  const handleIsForNow = (event) => {
    setIsForNowFilter(event.target.checked);
  };

  const handleStartChange = (date) => {
    setStartTime(date);
  };

  const handleEndChange = (date) => {
    setEndTime(date);
  };

  const handleTrackChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationTracks(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div className={classes.componentContainer}>
      <Accordion defaultExpanded={props.expanded}>
        <AccordionSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Icon sx={{ fontSize: "25px", marginRight: "10px" }}>
            filter_list
          </Icon>
          {i18n.t("navigation.filterList.filters")}
        </AccordionSummary>
        <AccordionDetails>
          {/* <MessageDiv classes={classes}></MessageDiv> */}
          <EventTypeDiv
            classes={classes}
            pickedTypes={pickedTypes}
            handleClick={handleClick}
          ></EventTypeDiv>
          <CriticalityDiv
            classes={classes}
            useCriticalities={useCriticalities}
            criticality={criticality}
            handleUseCriticalities={handleUseCriticalities}
            handleCriticality={handleCriticality}
          ></CriticalityDiv>
          <DateDiv
            classes={classes}
            isForNowFilter={isForNowFilter}
            startTime={startTime}
            endTime={endTime}
            handleIsForNow={handleIsForNow}
            handleStartChange={handleStartChange}
            handleEndChange={handleEndChange}
          ></DateDiv>
          <TrackDiv
            classes={classes}
            locationTracks={locationTracks}
            tracks={tracks}
            handleTrackChange={handleTrackChange}
          ></TrackDiv>
          <ButtonDiv classes={classes} reset={reset}></ButtonDiv>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
