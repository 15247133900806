import * as React from "react";
import { useSelector } from "react-redux";
import { Marker } from "react-map-gl";
import { Icon } from "@mui/material";
// import { ReactComponent as ObjectMoving } from "../../../../assets/object_moving.svg";
import { ReactComponent as ObjectStop } from "../../../../assets/object_stop.svg";
import {
  getColor,
  getName,
  isInactive,
  getLabelBackgroundColor,
  isOutOfBounds,
  isPositionAccurate,
} from "../../../../utils/movingObjectHelper";

const MovingObjectLayer = () => {
  const allPositions = useSelector((state) => state.mqtt.allPositions);

  const markers = React.useMemo(
    () =>
      allPositions.map(
        (item) =>
          !isOutOfBounds(item.coordinates.lat, item.coordinates.lon) && (
            <div
              key={"moving-object-div-" + item.deliveryStatus.sentBy}
              style={{
                height: "30px",
                width: "30px",
              }}
            >
              <Marker
                key={"moving-object-marker-" + item.deliveryStatus.sentBy}
                longitude={item.coordinates.lon}
                latitude={item.coordinates.lat}
              >
                <div className="moving-object-marker">
                  <span>
                    <Icon
                      baseClassName="material-icons-outlined"
                      // sx={{
                      //   transform: "rotate(" + item.heading + "deg)",
                      // }}
                    >
                      {/* {item.speed > 0.1 ? (
                        <ObjectMoving
                          style={{
                            transform: "scale(6)",
                            height: 30,
                            width: 30,
                            stroke: "#000000",
                            fill: getColor(item.deliveryStatus.sentBy),
                          }}
                        />
                      ) : ( */}
                      <ObjectStop
                        style={{
                          transform: "scale(6)",
                          height: 30,
                          width: 30,
                          stroke: "#000000",
                          fill: getColor(item.deliveryStatus.sentBy),
                          opacity: isInactive(item.deliveryStatus.sentAt)
                            ? 0.4
                            : 1,
                        }}
                      />
                      {/* )} */}
                    </Icon>
                  </span>
                </div>
              </Marker>
              <Marker
                key={"moving-object-label-marker-" + item.deliveryStatus.sentBy}
                longitude={item.coordinates.lon}
                latitude={item.coordinates.lat}
              >
                <div className="moving-object-label-marker">
                  <span>
                    <div
                      style={{
                        padding: "1px 5px",
                        color: "white",
                        fontWeight: 600,
                        backgroundColor: getLabelBackgroundColor(
                          item.deliveryStatus.sentAt
                        ),
                        opacity: isInactive(item.deliveryStatus.sentAt)
                          ? 0.4
                          : 0.8,
                        whiteSpace: "pre-line",
                        border: isPositionAccurate(item.dop, item.fix)
                          ? "none"
                          : "5px ridge magenta",
                      }}
                    >
                      {isInactive(item.deliveryStatus.sentAt)
                        ? getName(item.deliveryStatus.sentBy) +
                          " | " +
                          item.nearest_track
                        : getName(item.deliveryStatus.sentBy) +
                          " | " +
                          item.nearest_track +
                          " | " +
                          (item.speed * 3.6).toFixed(0) +
                          " km/h"}
                    </div>
                    <div
                      style={{
                        height: 50,
                        width: 50,
                      }}
                    />
                  </span>
                </div>
              </Marker>
            </div>
          )
      ),
    [allPositions]
  );

  return <span>{markers.length > 0 && markers}</span>;
};

export default MovingObjectLayer;
