// AUTH
export const SET_TOKEN = "SET_TOKEN";

// MENU
export const OPEN_VIEW = "OPEN_VIEW";

// MAP
export const SET_VIEW_STATE = "SET_VIEW_STATE";

export const SET_MAP_STYLE = "SET_MAP_STYLE";

export const SET_MAP_BOUNDS = "SET_MAP_BOUNDS";

export const SET_TRACK_LAYER_DATA = "SET_TRACK_LAYER_DATA";

export const SET_POINT_LAYER_DATA = "SET_POINT_LAYER_DATA";

export const MAP_SELECT_FEATURE = "MAP_SELECT_FEATURE";

export const MAP_DESELECT_FEATURE = "MAP_DESELECT_FEATURE";

export const MAP_ALARM_TRACKS = "MAP_ALARM_TRACKS";

export const MAP_OTHER_TRACKS = "MAP_OTHER_TRACKS";

export const MAP_SERVICE_LOCATION_TRACKS = "MAP_SERVICE_LOCATION_TRACKS";

export const MAP_TRACK_CAPACITY_TRACKS = "MAP_TRACK_CAPACITY_TRACKS";

export const MAP_ISFORNOW_ALARM_TRACKS = "MAP_ISFORNOW_ALARM_TRACKS";

export const MAP_ISFORNOW_OTHER_TRACKS = "MAP_ISFORNOW_OTHER_TRACKS";

export const MAP_ISFORNOW_SERVICE_LOCATION_TRACKS =
  "MAP_ISFORNOW_SERVICE_LOCATION_TRACKS";

export const MAP_ISFORNOW_TRACK_CAPACITY_TRACKS =
  "MAP_ISFORNOW_TRACK_CAPACITY_TRACKS";

export const MAP_STOP_TRACKS = "MAP_STOP_TRACKS";

export const MAP_RESTRICTED_TRACKS = "MAP_RESTRICTED_TRACKS";

export const MAP_ISFORNOW_STOP_TRACKS = "MAP_ISFORNOW_STOP_TRACKS";

export const MAP_ISFORNOW_RESTRICTED_TRACKS = "MAP_ISFORNOW_RESTRICTED_TRACKS";

export const TRACKS_DATA = "TRACKS_DATA";

export const POINTS_DATA = "POINTS_DATA";

export const FOLLOW_UNIT = "FOLLOW_UNIT";

// UI
export const ADD_TO_LOADING = "APP_LOADING";
export const REMOVE_FROM_LOADING = "REMOVE_FROM_LOADING";

export const EVENTS_DATA = "EVENTS_DATA";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const UPDATE_SINGLE_EVENT = "UPDATE_SINGLE_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const UPDATE_EVENT_COMMENTS = "UPDATE_EVENT_COMMENTS";
export const SELECTED_EVENT_ID = "SELECTED_EVENT_ID";
export const FILTER_LIST_EVENT_IDS = "FILTER_LIST_EVENT_IDS";
export const TRACK_EVENTS = "TRACK_EVENTS";
export const FETCH_EVENTS = "FETCH_EVENTS";

export const TRAINS_DATA = "TRAINS_DATA";
export const UPDATE_TRAINS = "UPDATE_TRAINS";

export const EVENTS_TIME_FILTER = "EVENTS_TIME_FILTER";
export const RESET_TIME_FILTER = "RESET_TIME_FILTER";
export const ADD_TO_SELECTED = "ADD_TO_SELECTED";

export const ADD_SITE_ROLES = "ADD_SITE_ROLES";
export const CHANGE_SELECTED_SITE = "CHANGE_SELECTED_SITE";
export const AVAILABLE_SITES = "AVAILABLE_SITES";
export const SET_USER_ORG = "SET_USER_ORG";

export const LOADING_STATE = "LOADING_STATE";

// MQTT
export const MQTT_STATUS = "MQTT_STATUS";
export const MQTT_POSITION = "MQTT_POSITION";
