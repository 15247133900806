import React from "react";
import Map from "./Map";

const MapChooser = (props) => {
  let map = null;

  if (
    props.desktop &&
    props.view !== "account" &&
    props.view !== "control" &&
    props.view !== "events"
  ) {
    map = (
      <Map
        view={props.view}
        selectView={props.selectView}
        siteId={props.siteId}
      />
    );
  } else {
    if (props.view.length === 0) {
      map = (
        <Map
          view={props.view}
          selectView={props.selectView}
          siteId={props.siteId}
        />
      );
    }
  }

  return map;
};

export default MapChooser;
