import React from "react";
import { makeStyles } from "@mui/styles";
import { TRACK_HEIGHT } from "../../../../../../constants";
import { getEventPositionalProps } from "../../../../../../utils/schedulerUtils";
import { Flex } from "../../../../Layout";
import {
  setSelectedEventId,
  setFilterListEventIds,
} from "../../../../../../store/actions";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Typography, Tooltip } from "@mui/material";
import i18n from "i18next";
import moment from "moment";
import EventHoverTooltip from "../../../../../UI/EventToolTip";

const useStyles = makeStyles(() => ({
  myTooltip: {
    "&.MuiTooltip-tooltip": {
      maxWidth: "500px",
    },
  },
}));

function Event({
  uuid,
  eventState,
  startTime,
  eventType,
  endTime,
  criticality,
  isForNow,
  onClick,
  onHover,
  eventComments,
  eventId,
  filterIds,
  openview,
  myEvent,
  events,
  hoverDate,
}) {
  const classes = useStyles();
  const { x, width } = getEventPositionalProps(startTime, endTime);
  const newComments = eventComments[uuid]?.newComments;
  let clsName = "event";
  if (eventType === "maintenance") {
    clsName = "event " + criticality + " " + isForNow + " " + eventState;
  } else {
    clsName = "event " + eventType + " " + isForNow + " " + eventState;
  }

  let style = { left: x + "px", width: width + "px" };

  if (
    eventId === uuid ||
    (filterIds.includes(uuid) &&
      [
        "filterListFull",
        "filterListHalf",
        "eventListFull",
        "eventListHalf",
      ].includes(openview))
  ) {
    style = {
      left: x + "px",
      width: width + "px",
      outline: "none",
      borderColor: "#959595",
      opacity: 1,
    };
  }

  let eventDiv = (
    <div
      className={clsName}
      style={style}
      onClick={onClick}
      onMouseOver={onHover}
    />
  );

  if (newComments > 0) {
    eventDiv = (
      <div
        className={clsName}
        style={style}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <div style={{ display: "flex" }}>
          {/* <Icon>chat_bubble</Icon> */}
          <Icon style={{ color: "white" }}>
            {i18n.t("schedulerPage.newCommentsIcon")}
          </Icon>
          <Typography
            align="center"
            style={{ color: "white", fontWeight: 600 }}
          >
            {newComments}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      classes={{ tooltip: classes.myTooltip }}
      title={
        <EventHoverTooltip
          event={myEvent}
          events={events}
          hoverDate={hoverDate}
          clsName={clsName}
        />
      }
    >
      {eventDiv}
    </Tooltip>
  );
}

const Track = ({ trackName, myLeft }) => {
  return (
    <div className={"track"} style={{ left: myLeft }}>
      {trackName}
    </div>
  );
};

const checkIfEventStack = (event, events, clickDate) => {
  let stackedEvents = [event.uuid];
  if (events.length > 1) {
    for (let ev of events) {
      if (ev.uuid !== event.uuid) {
        if (ev.isForNow) {
          if (moment(ev.startTime).isBefore(clickDate)) {
            stackedEvents.push(ev.uuid);
          }
        } else {
          if (
            moment(ev.startTime).isBefore(clickDate) &&
            moment(ev.endTime).isAfter(clickDate)
          ) {
            stackedEvents.push(ev.uuid);
          }
        }
      }
    }
  }
  return stackedEvents;
};

export default function TrackEvent(props) {
  const {
    trackName,
    showViewAction,
    events,
    myLeft,
    clickDate,
    hoverDate,
    onHover,
    openview,
  } = props;

  const dispatch = useDispatch();
  const eventId = useSelector((state) => state.userinterface.eventId);
  const filterIds = useSelector(
    (state) => state.userinterface.filterListEventIds
  );
  const eventComments = useSelector(
    (state) => state.userinterface.eventComments
  );

  function handleEventClick(event) {
    const stackedEvents = checkIfEventStack(event, events, clickDate);
    if (stackedEvents.length > 1) {
      dispatch(setSelectedEventId(null));
      dispatch(setFilterListEventIds(stackedEvents));
      if (
        [
          "events",
          "eventInfoCreateFull",
          "eventInfoEditFull",
          "filterListFull",
        ].includes(openview)
      ) {
        showViewAction("eventListFull");
      }

      if (
        [
          "halfevents",
          "eventInfoCreateHalf",
          "eventInfoEditHalf",
          "filterListHalf",
        ].includes(openview)
      ) {
        showViewAction("eventListHalf");
      }
    } else {
      dispatch(setSelectedEventId(event.uuid));
      if (
        [
          "events",
          "eventInfoCreateFull",
          "filterListFull",
          "eventListFull",
        ].includes(openview)
      ) {
        showViewAction("eventInfoEditFull");
      }

      if (
        [
          "halfevents",
          "eventInfoCreateHalf",
          "filterListHalf",
          "eventListHalf",
        ].includes(openview)
      ) {
        showViewAction("eventInfoEditHalf");
      }
    }
  }

  return (
    <Flex className="event-cont" style={{ height: TRACK_HEIGHT + "px" }}>
      <Track trackName={trackName} myLeft={myLeft} />
      {events &&
        events.map((event) => (
          <Event
            key={`${event.siteId}_${event.uuid}`}
            eventComments={eventComments}
            eventId={eventId}
            filterIds={filterIds}
            openview={openview}
            {...event}
            myEvent={event}
            events={events}
            hoverDate={hoverDate}
            onClick={() => handleEventClick(event)}
            onHover={onHover}
          />
        ))}
    </Flex>
  );
}
