import React from "react";
import "./index.css";
import { Flex } from "../../../../../../Layout";
import { COLUMN_WIDTH, TRACK_HEIGHT } from "../../../../../../../../constants";

const Column = React.forwardRef(function DayColumn(
  { day, date, month, myTracks, ...rest },
  ref
) {
  const height = myTracks.length * TRACK_HEIGHT + "px";

  return (
    <Flex
      key={`${day}_${date}_${month}`}
      ref={ref}
      justify="flex-start"
      self="stretch"
      className="column"
      style={{ flex: "0 0 " + (COLUMN_WIDTH - 1) + "px", height: height }}
      {...rest}
    />
  );
});

export default Column;
