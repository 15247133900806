import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import * as actions from "../../store/actions/index";
import { isMobile } from "react-device-detect";
import ReactMapGL, { NavigationControl } from "react-map-gl";
import maplibregl from "!maplibre-gl"; // ! is important here
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
maplibregl.workerClass = maplibreglWorker;
import "maplibre-gl/dist/maplibre-gl.css";

import TrackLayer from "./MapLayers/TrackLayer/TrackLayer";
import PointLayer from "./MapLayers/PointLayer/PointLayer";
import AlarmLayer from "./MapLayers/AlarmLayer/AlarmLayer";
import ServiceLocationLayer from "./MapLayers/ServiceLocationLayer/ServiceLocationLayer";
import TrackCapacityLayer from "./MapLayers/TrackCapacityLayer/TrackCapacityLayer";
import StopLayer from "./MapLayers/StopLayer/StopLayer";
import RestrictedLayer from "./MapLayers/RestrictedLayer/RestrictedLayer";
import moment from "moment";
import MovingObjectLayer from "./MapLayers/MovingObjectLayer/MovingObjectLayer";
import IsForNowAlarm from "./MapLayers/IsForNowAlarm/IsForNowAlarm";
import IsForNowOther from "./MapLayers/IsForNowOther/IsForNowOther";
import IsForNowStop from "./MapLayers/IsForNowStop/IsForNowStop";
import IsForNowRestricted from "./MapLayers/IsForNowRestricted/IsForNowRestricted";
import IsForNowServiceLocation from "./MapLayers/IsForNowServiceLocation/IsForNowServiceLocation";
import IsForNowTrackCapacity from "./MapLayers/IsForNowTrackCapacity/IsForNowTrackCapacity";
import OtherLayer from "./MapLayers/OtherLayer/OtherLayer";
import { showTrackElements } from "../../utils/licenses";
import HookMqtt from "../../hooks/hookMqtt";
import "./index.css";
import i18next from "i18next";
import { isOutOfBounds } from "../../utils/movingObjectHelper";

const MAP_MIN_ZOOM = 5;
const MAP_MAX_ZOOM = 20;

const Map = (props) => {
  const navigationStyle = {
    position: "fixed",
    right: 10,
    top: 65,
  };

  /**
   * Available locales:
      * "AttributionControl.ToggleAttribution": "Toggle attribution",
        "AttributionControl.MapFeedback": "Map feedback",
        "FullscreenControl.Enter": "Enter fullscreen",
        "FullscreenControl.Exit": "Exit fullscreen",
        "GeolocateControl.FindMyLocation": "Find my location",
        "GeolocateControl.LocationNotAvailable": "Location not available",
        "LogoControl.Title": "Mapbox logo",
        "Map.Title": "Map",
        "NavigationControl.ResetBearing": "Reset bearing to north",
        "NavigationControl.ZoomIn": "Zoom in",
        "NavigationControl.ZoomOut": "Zoom out",
        "ScrollZoomBlocker.CtrlMessage": "Use ctrl + scroll to zoom the map",
        "ScrollZoomBlocker.CmdMessage": "Use ⌘ + scroll to zoom the map",
        "TouchPanBlocker.Message": "Use two fingers to move the map",
   */
  const locale = {
    "NavigationControl.ZoomIn": i18next.t("mapControls.zoomIn") || "Zoom in",
    "NavigationControl.ZoomOut": i18next.t("mapControls.zoomOut") || "Zoom out",
    "NavigationControl.ResetBearing":
      i18next.t("mapControls.resetBearing") || "Reset bearing to north",
  };

  let alarmTracks = [];
  let isForNowAlarm = [];
  let otherTracks = [];
  let isForNowOther = [];
  let serviceLocationTracks = [];
  let isForNowServiceLocation = [];
  let trackCapacityTracks = [];
  let isForNowTrackCapacity = [];
  let stopTracks = [];
  let restrictedTracks = [];
  let isForNowStop = [];
  let isForNowRestricted = [];
  const mapRef = React.useRef();
  const trackLayerData = useSelector((s) => s.map.trackLayerData);
  const pointLayerData = useSelector((s) => s.map.pointLayerData);
  const mapStyle = useSelector((s) => s.map.mapStyle);
  const viewState = useSelector((s) => s.map.viewState);
  const events = useSelector((s) => s.userinterface.events);
  const mapEvents = useSelector((s) => s.userinterface.mapEvents);
  const isFiltered = useSelector((s) => s.userinterface.isFiltered);
  const dispatch = useDispatch();
  const today = new Date();
  const until = moment(today).add(15, "minutes");

  const roles = useSelector((s) => s.permissions.roles);

  const followedUnit = useSelector((s) => s.map.followUnit);
  const allPositions = useSelector((state) => state.mqtt.allPositions);
  const followPosition = React.useRef();
  const followInterval = React.useRef();

  // These settings make panning less sensitive and clicking easier on mobile
  const options = {
    pan: { threshold: 10 },
    tap: { threshold: 5 },
  };
  const eventRecognizerOptions = isMobile ? options : {};

  let controls = <NavigationControl style={navigationStyle} />;

  // Starts MQTT listener
  HookMqtt();

  if (
    props.view === "account" ||
    props.view === "control" ||
    props.view === "events"
  ) {
    controls = null;
  }

  React.useEffect(() => {
    alarmTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "alarm";
        } else {
          return (
            (k.eventType === "alarm" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "alarm" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "alarm" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    isForNowAlarm.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "alarm" && k.isForNow === true;
        } else {
          return (
            k.eventType === "alarm" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    otherTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "other";
        } else {
          return (
            (k.eventType === "other" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "other" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "other" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    isForNowOther.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "other" && k.isForNow === true;
        } else {
          return (
            k.eventType === "other" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    serviceLocationTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "servicelocationreservation";
        } else {
          return (
            (k.eventType === "servicelocationreservation" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "servicelocationreservation" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "servicelocationreservation" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    isForNowServiceLocation.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return (
            k.eventType === "servicelocationreservation" && k.isForNow === true
          );
        } else {
          return (
            k.eventType === "servicelocationreservation" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    trackCapacityTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.eventType === "trackcapacityreservation";
        } else {
          return (
            (k.eventType === "trackcapacityreservation" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "trackcapacityreservation" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "trackcapacityreservation" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    isForNowTrackCapacity.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return (
            k.eventType === "trackcapacityreservation" && k.isForNow === true
          );
        } else {
          return (
            k.eventType === "trackcapacityreservation" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    stopTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return k.criticality === "stop" && k.eventType === "maintenance";
        } else {
          return (
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    restrictedTracks.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return (
            k.criticality === "restricted" && k.eventType === "maintenance"
          );
        } else {
          return (
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        }
      })
    );
    isForNowStop.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return (
            k.criticality === "stop" &&
            k.eventType === "maintenance" &&
            k.isForNow === true
          );
        } else {
          return (
            k.criticality === "stop" &&
            k.eventType === "maintenance" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    isForNowRestricted.push(
      mapEvents.filter(function (k) {
        if (isFiltered) {
          return (
            k.criticality === "restricted" &&
            k.eventType === "maintenance" &&
            k.isForNow === true
          );
        } else {
          return (
            k.criticality === "restricted" &&
            k.eventType === "maintenance" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        }
      })
    );
    dispatch(actions.mapAlarmTracks(alarmTracks));
    dispatch(actions.mapIsForNowAlarm(isForNowAlarm));
    dispatch(actions.mapOtherTracks(otherTracks));
    dispatch(actions.mapIsForNowOther(isForNowOther));
    dispatch(actions.mapServiceLocationTracks(serviceLocationTracks));
    dispatch(actions.mapIsForNowServiceLocation(isForNowServiceLocation));
    dispatch(actions.mapTrackCapacityTracks(trackCapacityTracks));
    dispatch(actions.mapIsForNowTrackCapacity(isForNowTrackCapacity));
    dispatch(actions.mapStopTracks(stopTracks));
    dispatch(actions.mapRestrictedTracks(restrictedTracks));
    dispatch(actions.mapIsForNowStop(isForNowStop));
    dispatch(actions.mapIsForNowRestricted(isForNowRestricted));
  }, [mapEvents]);

  React.useEffect(() => {
    if (!isFiltered) {
      alarmTracks.push(
        events.filter(function (k) {
          return (
            (k.eventType === "alarm" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "alarm" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "alarm" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      isForNowAlarm.push(
        events.filter(function (k) {
          return (
            k.eventType === "alarm" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      otherTracks.push(
        events.filter(function (k) {
          return (
            (k.eventType === "other" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "other" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "other" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      isForNowOther.push(
        events.filter(function (k) {
          return (
            k.eventType === "other" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      serviceLocationTracks.push(
        events.filter(function (k) {
          return (
            (k.eventType === "servicelocationreservation" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "servicelocationreservation" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "servicelocationreservation" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      isForNowServiceLocation.push(
        events.filter(function (k) {
          return (
            k.eventType === "servicelocationreservation" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      trackCapacityTracks.push(
        events.filter(function (k) {
          return (
            (k.eventType === "trackcapacityreservation" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.eventType === "trackcapacityreservation" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.eventType === "trackcapacityreservation" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      isForNowTrackCapacity.push(
        events.filter(function (k) {
          return (
            k.eventType === "trackcapacityreservation" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      stopTracks.push(
        events.filter(function (k) {
          return (
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.criticality === "stop" &&
              k.eventType === "maintenance" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      restrictedTracks.push(
        events.filter(function (k) {
          return (
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              moment(until).isBetween(today, k.endTime, undefined, "[]") &&
              moment(until).isAfter(k.startTime)) ||
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              moment(k.endTime).isBetween(today, until, undefined, "[]")) ||
            (k.criticality === "restricted" &&
              k.eventType === "maintenance" &&
              k.isForNow === true &&
              moment(until).isAfter(k.startTime))
          );
        })
      );
      isForNowStop.push(
        events.filter(function (k) {
          return (
            k.criticality === "stop" &&
            k.eventType === "maintenance" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      isForNowRestricted.push(
        events.filter(function (k) {
          return (
            k.criticality === "restricted" &&
            k.eventType === "maintenance" &&
            k.isForNow === true &&
            moment(until).isAfter(k.startTime)
          );
        })
      );
      dispatch(actions.mapAlarmTracks(alarmTracks));
      dispatch(actions.mapIsForNowAlarm(isForNowAlarm));
      dispatch(actions.mapOtherTracks(otherTracks));
      dispatch(actions.mapIsForNowOther(isForNowOther));
      dispatch(actions.mapServiceLocationTracks(serviceLocationTracks));
      dispatch(actions.mapIsForNowServiceLocation(isForNowServiceLocation));
      dispatch(actions.mapTrackCapacityTracks(trackCapacityTracks));
      dispatch(actions.mapIsForNowTrackCapacity(isForNowTrackCapacity));
      dispatch(actions.mapStopTracks(stopTracks));
      dispatch(actions.mapRestrictedTracks(restrictedTracks));
      dispatch(actions.mapIsForNowStop(isForNowStop));
      dispatch(actions.mapIsForNowRestricted(isForNowRestricted));
    }
  }, [events]);

  const onMove = useCallback((evt) => {
    dispatch({
      type: actionTypes.SET_VIEW_STATE,
      payload: evt.viewState,
    });
  }, []);

  const getCorrectPosition = () => {
    for (let position of allPositions) {
      if (position.deliveryStatus.sentBy === followedUnit) {
        return position;
      }
    }
  };

  React.useEffect(() => {
    if (followedUnit) {
      followPosition.current = getCorrectPosition();
    }
  }, [allPositions]);

  React.useEffect(() => {
    function handleFollowInterval() {
      if (!followPosition.current) {
        // Can be null if device inactive, init
        followPosition.current = getCorrectPosition();
      }
      followInterval.current = setInterval(() => {
        if (followPosition.current.deliveryStatus.sentBy === followedUnit) {
          if (
            isOutOfBounds(
              followPosition.current.coordinates.lat,
              followPosition.current.coordinates.lon
            )
          ) {
            dispatch(actions.followUnit(null));
            if (followInterval && followInterval.current) {
              clearInterval(followInterval.current);
            }
          } else {
            mapRef.current.fitBounds(
              [
                {
                  lat: followPosition.current.coordinates.lat,
                  lng: followPosition.current.coordinates.lon,
                },
                {
                  lat: followPosition.current.coordinates.lat,
                  lng: followPosition.current.coordinates.lon,
                },
              ],
              {
                padding: 20,
                maxZoom: mapRef.current.getZoom(),
                duration: 15,
              }
            );
          }
        }
      }, 1000);
      return () => clearInterval(followInterval.current);
    }
    if (followedUnit && allPositions && allPositions.length > 0) {
      if (followInterval && followInterval.current) {
        clearInterval(followInterval.current);
      }
      handleFollowInterval();
    } else {
      if (followInterval && followInterval.current) {
        clearInterval(followInterval.current);
      }
    }
  }, [followedUnit]);

  const onClick = useCallback((evt) => {
    const features = mapRef.current.queryRenderedFeatures(evt.point);
    const feature = features && features[0];
    if (feature) {
      dispatch({
        type: actionTypes.MAP_SELECT_FEATURE,
        payload: feature,
      });
      // // This centers map to Imatra when clicking on a feature
      // let bound1 = { lng: 28.983221256800505, lat: 61.251805976200046 };
      // let bound2 = { lng: 28.721795865104326, lat: 61.21502877275282 };
      // let bounds = [bound1, bound2];

      // mapRef.current.fitBounds(bounds, {
      //   padding: 20,
      //   maxZoom: 15,
      //   duration: 0,
      // });
    } else {
      dispatch({
        type: actionTypes.MAP_DESELECT_FEATURE,
      });
    }
  });

  const isValidLayerData = (layerData) => {
    return layerData.features && layerData.features.length > 0;
  };

  const showMapLayer = () => {
    return (
      isValidLayerData(trackLayerData) &&
      roles[props.siteId] !== undefined &&
      showTrackElements(roles[props.siteId])
    );
  };

  return (
    <ReactMapGL
      ref={mapRef}
      {...viewState}
      keyboard={false}
      maxPitch={0}
      mapLib={maplibregl}
      onMove={onMove}
      onClick={onClick}
      className="prox-map"
      reuseMaps={true}
      mapStyle={mapStyle}
      minZoom={MAP_MIN_ZOOM}
      maxZoom={MAP_MAX_ZOOM}
      eventRecognizerOptions={eventRecognizerOptions}
      locale={locale}
    >
      {controls}
      {showMapLayer() && (
        <>
          {/* For debugging geofencing:
          <Marker key={"sw"} longitude={28.7931} latitude={61.2011}>
            SW
          </Marker>
          <Marker key={"ne"} longitude={28.87754} latitude={61.2557}>
            NE
          </Marker> */}
          <TrackLayer />
          {isValidLayerData(pointLayerData) && <PointLayer />}
          <StopLayer />
          <RestrictedLayer />
          <IsForNowStop />
          <IsForNowRestricted />
          <AlarmLayer />
          <IsForNowAlarm />
          <OtherLayer />
          <IsForNowOther />
          <ServiceLocationLayer />
          <IsForNowServiceLocation />
          <TrackCapacityLayer />
          <IsForNowTrackCapacity />
          <MovingObjectLayer />
        </>
      )}
    </ReactMapGL>
  );
};

export default Map;
