import React from "react";
import i18n from "i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { slice2LongItems } from "../../../../../../utils/dynamicFields";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: "white !important",
    borderWidth: "1px !important",
    borderStyle: "solid !important",
    borderColor: "rgba(128,128,128, 0.3) !important",
    color: "black !important",
    paddingBottom: "2px !important",
  },
  chipSelect: {
    backgroundColor: "rgba(0, 159, 218, 1.0) !important",
    borderWidth: "1px !important",
    borderStyle: "solid !important",
    borderColor: "rgba(128,128,128, 0.3) !important",
    color: "white !important",
    paddingBottom: "2px !important",
  },
  MuiAccordionroot: {
    margin: "0px 0px 0px 0px !important",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  textField: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    marginLeft: "0px !important",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    "& .MuiOutlinedInput-notchedOutline legend": {
      display: "none",
    },
  },
  panelSummary: {
    marginRight: "0px",
    backgroundColor: theme.palette.common.white,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.white,
      minHeight: "42px !important",
      maxHeight: "42px !important",
    },
  },
  componentContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "5px",
    marginBottom: "8px",
    backgroundColor: theme.palette.common.white,
  },
  orgFieldContainer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "12px",
    marginBottom: "20px",
    backgroundColor: theme.palette.common.white,
  },
  column: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    display: "table",
    float: "left",
    marginBottom: "-12px",
  },
  title: {
    fontSize: "12px !important",
    fontWeight: 500,
    marginTop: "-10px !important",
    marginBottom: "4px !important",
  },
  headTitle: {
    ...theme.typography,
    fontSize: "14px !important",
    marginTop: "-15px !important",
    marginBottom: "7px",
    fontWeight: 400,
    marginLeft: "-15px !important",
    color: theme.palette.common.black,
  },
  titleGrey: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    marginTop: "4px !important",
    marginLeft: "8px !important",
    color: "rgba(0, 0, 0, 0.5)",
  },
  dropDown: {
    width: 389,
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
  },
}));

const OrgFieldComponent = (props) => {
  const {
    index,
    fieldId,
    type,
    defaults,
    customValue,
    lockedForEdit,
    setValues,
  } = props;
  const classes = useStyles();
  let defaults2 = null;
  if (defaults) {
    defaults2 = defaults.replaceAll(/(['"])/g, "").split(",");
  }
  let customValue2 = null;
  let uuid = null;
  if (customValue) {
    customValue2 = customValue.value;
    uuid = customValue.uuid;
  }
  const [selectDropdown, setSelectDropdown] = React.useState(
    customValue2 ? customValue2.split(",") : []
  );
  const [num, setNum] = React.useState(customValue2 ? customValue2 : "");
  const [inputText, setInputText] = React.useState(
    customValue2 ? customValue2 : ""
  );
  const [textAreaInput, setTextAreaInput] = React.useState(
    customValue2 ? customValue2 : ""
  );
  const [selection, setSelection] = React.useState(
    customValue2 ? customValue2 : ""
  );
  const [checkbox, setCheckbox] = React.useState(
    customValue2
      ? customValue2 === "true"
      : defaults2
      ? defaults2 === "true"
      : false
  );
  const [switchState, setSwitchState] = React.useState(
    customValue2
      ? customValue2 === "true"
      : defaults2
      ? defaults2 === "true"
      : false
  );

  React.useEffect(() => {
    if (customValue) {
      if (type === "dropdown") {
        setValues(
          index,
          customValue.uuid,
          fieldId,
          customValue.value.split(",")
        );
      } else if (type === "switch") {
        setValues(
          index,
          customValue.uuid,
          fieldId,
          customValue.value === "true"
        );
      } else if (type === "inputText") {
        setValues(index, customValue.uuid, fieldId, customValue.value);
      } else if (type === "textArea") {
        setValues(index, customValue.uuid, fieldId, customValue.value);
      } else if (type === "inputNumeric") {
        setValues(index, customValue.uuid, fieldId, customValue.value);
      } else if (type === "selection") {
        setValues(index, customValue.uuid, fieldId, customValue.value);
      } else if (type === "checkbox") {
        setValues(
          index,
          customValue.uuid,
          fieldId,
          customValue.value === "true"
        );
      }
    }
  }, [customValue]);

  React.useEffect(() => {
    if (type === "dropdown") setValues(index, uuid, fieldId, selectDropdown);
    else if (type === "switch") setValues(index, uuid, fieldId, switchState);
    else if (type === "inputText") setValues(index, uuid, fieldId, inputText);
    else if (type === "textArea")
      setValues(index, uuid, fieldId, textAreaInput);
    else if (type === "inputNumeric") setValues(index, uuid, fieldId, num);
    else if (type === "selection") setValues(index, uuid, fieldId, selection);
    else if (type === "checkbox") setValues(index, uuid, fieldId, checkbox);
  }, [
    selectDropdown,
    switchState,
    inputText,
    textAreaInput,
    num,
    selection,
    checkbox,
  ]);

  const handleDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setSelectDropdown(typeof value === "string" ? value.split(",") : value);
  };

  const handleInputText = (event) => {
    setInputText(event.target.value);
  };

  const handleTextAreaInput = (event) => {
    setTextAreaInput(event.target.value);
  };

  const handleSwitch = () => {
    setSwitchState(!switchState);
  };

  const handleNumericChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const handleSelection = (item) => {
    setSelection(item);
  };

  if (type === "dropdown") {
    return (
      <FormControl sx={{ m: 0 }} classes={{ root: classes.dropDown }}>
        <Select
          disabled={!lockedForEdit}
          multiple
          value={selectDropdown}
          onChange={handleDropdown}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {defaults2.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === "switch") {
    return (
      <Switch
        disabled={!lockedForEdit}
        color="primary"
        checked={switchState}
        onChange={() => handleSwitch()}
      />
    );
  } else if (type === "inputText") {
    return (
      <TextField
        disabled={!lockedForEdit}
        size="small"
        variant="outlined"
        value={inputText}
        onChange={handleInputText}
        label={null}
        className={classes.textField}
      />
    );
  } else if (type === "textArea") {
    return (
      <TextField
        disabled={!lockedForEdit}
        className={classes.textField}
        id="InputTextfield"
        variant="outlined"
        label={null}
        multiline
        rows={2}
        value={textAreaInput}
        onChange={handleTextAreaInput}
      />
    );
  } else if (type === "inputNumeric") {
    return (
      <TextField
        disabled={!lockedForEdit}
        className={classes.textField}
        size="small"
        variant="outlined"
        label={null}
        value={num}
        type="text"
        onChange={(e) => handleNumericChange(e)}
      />
    );
  } else if (type === "selection") {
    const itemRows = slice2LongItems(defaults2);

    return (
      <Stack direction="column" spacing={2}>
        {itemRows.map((row, index) => (
          <Stack key={index} direction="row" spacing={1} wrap="wrap">
            {row.map((item) => (
              <Chip
                disabled={!lockedForEdit}
                key={item}
                className={
                  selection === item ? classes.chipSelect : classes.chip
                }
                label={item}
                onClick={() => handleSelection(item)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    );
  } else if (type === "checkbox") {
    return (
      <Checkbox
        disabled={!lockedForEdit}
        checked={checkbox}
        onClick={() => handleCheckbox()}
      />
    );
  }
};

const OrgFieldDiv = (props) => {
  const { index, orgField, customValue, lockedForEdit, setValues } = props;
  const classes = useStyles();

  return (
    <div className={classes.orgFieldContainer}>
      <Typography className={classes.title}>{orgField.label}</Typography>
      <OrgFieldComponent
        index={index}
        fieldId={orgField.uuid}
        type={orgField.type}
        defaults={orgField.defaultValues}
        customValue={customValue}
        lockedForEdit={lockedForEdit}
        setValues={setValues}
      />
      {orgField.required && (
        <Typography className={classes.titleGrey}>
          {i18n.t("navigation.eventInfo.mandatory")}
        </Typography>
      )}
    </div>
  );
};

const EventOrgFields = (props) => {
  const {
    orgFields,
    customValues,
    lockedForEdit,
    customValuesInput,
    setCustomValuesInput,
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  let temp = [...customValuesInput];

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const setValues = (index, uuid, fieldId, value) => {
    const customValue = {
      uuid: uuid,
      fieldId: fieldId,
      value: value,
    };
    temp[index] = customValue;
    setCustomValuesInput(temp);
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <Accordion
          id="Accordion"
          expanded={expanded}
          onChange={handleChange}
          elevation={0}
          classes={{
            root: classes.MuiAccordionroot,
          }}
        >
          <AccordionSummary
            id="accordionSummary"
            className={classes.panelSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.headTitle}>
              {i18n.t("navigation.eventInfo.locationFields")}
            </Typography>
          </AccordionSummary>
          {orgFields.map((item, index) => (
            <OrgFieldDiv
              key={item.uuid + item.fieldName}
              index={index}
              orgField={item}
              customValue={
                customValues.filter(function (k) {
                  return k.fieldId === item.uuid;
                })[0]
              }
              lockedForEdit={lockedForEdit}
              setValues={setValues}
            />
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default EventOrgFields;
