import { forwardRef } from "react";

const Flex = forwardRef(function Flex(
  {
    direction = "column",
    align = "center",
    justify = "flex-start",
    self = "flex-start",
    content,
    wrap,
    flex,
    style,
    ...rest
  },
  ref
) {
  const styles = {
    display: "flex",
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    alignSelf: self,
    alignContent: content,
    flexWrap: wrap,
    flex,
    width: "100%",
    ...style,
  };

  return <div ref={ref} style={styles} {...rest} />;
});

export default Flex;
