import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index";
import {
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { authenticationService } from "../../../Auth/_services";

import { showCreateEvent } from "../../../../utils/licenses";

// TODO: read from redux store
const availableUserActions = [
  {
    action: "map",
    icon: i18n.t("navigation.map.icon"),
    text: i18n.t("navigation.map.text"),
  },
  {
    action: "movingObjects",
    icon: i18n.t("navigation.movingObjects.icon"),
    text: i18n.t("navigation.movingObjects.text"),
  },
  {
    action: "events",
    icon: i18n.t("navigation.timeline.icon"),
    text: i18n.t("navigation.timeline.text"),
  },
  {
    action: "_createEvent",
    icon: i18n.t("navigation.eventInfoCreate.icon"),
    text: i18n.t("navigation.eventInfoCreate.text"),
  },
  {
    action: "userManual",
    icon: i18n.t("navigation.userManual.icon"),
    text: i18n.t("navigation.userManual.text"),
  },
];

const NavigationItems = () => {
  const dispatch = useDispatch();
  const openView = useSelector((state) => state.userinterface.openview);
  const roles = useSelector((state) => state.permissions.roles);
  const selectedSite = useSelector((state) => state.permissions.selectedSite);
  const handleLogout = () => {
    dispatch(actions.openView("map"));
    authenticationService.logout();
  };

  const handleViewChange = (newView) => {
    // Create event action is handled by handleCreateEventOpen()
    if (newView === "_createEvent") {
      handleCreateEventOpen();
      return;
    }

    if (openView !== newView) {
      dispatch(actions.openView(newView));
      if (newView === "events") {
        dispatch(actions.resetEventsByTime());
      }
    }
  };

  const handleCreateEventOpen = () => {
    if (
      openView === "map" ||
      openView === "events" ||
      openView === "eventInfoEditFull" ||
      openView === "filterListFull" ||
      openView === "eventListFull"
    ) {
      handleViewChange("eventInfoCreateFull");
      dispatch(actions.resetEventsByTime());
    } else if (openView === "eventInfoCreateFull") {
      // Don't open the same view
      return;
    } else {
      handleViewChange("eventInfoCreateHalf");
    }
  };

  return (
    <>
      <Grid
        id="navigation-items-main-grid"
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid id="navigation-items-child-grid" item style={{ width: "100%" }}>
          <List>
            {availableUserActions.map((item, index) => {
              let listItem = null;

              // We need to check that user have rights to create events
              if (item.action === "_createEvent") {
                if (selectedSite && showCreateEvent(roles[selectedSite])) {
                  listItem = (
                    <ListItem disablePadding key={item.text}>
                      <ListItemButton
                        variant="navigation"
                        tabIndex={index + 1}
                        onClick={() => handleViewChange(`${item.action}`)}
                      >
                        <ListItemIcon>
                          <Icon
                            variant="navigation"
                            baseClassName="material-icons-outlined"
                            className={
                              openView === item.action ? "navItemActive" : ""
                            }
                          >
                            {item.icon}
                          </Icon>
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          variant="navigation"
                          className={
                            openView === item.action ? "navItemActive" : ""
                          }
                          primary={item.text}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              } else if (item.action === "userManual") {
                listItem = (
                  <ListItem disablePadding key={item.text}>
                    <ListItemButton
                      variant="navigation"
                      tabIndex={index + 1}
                      onClick={() =>
                        window.open(
                          "https://www.proxion.fi/ratkaisumme/prox-rail/ukk/",
                          "_blank",
                          "noreferrer"
                        )
                      }
                    >
                      <ListItemIcon>
                        <Icon
                          variant="navigation"
                          baseClassName="material-icons-outlined"
                        >
                          {item.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        variant="navigation"
                        primary={item.text}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              } else {
                listItem = (
                  <ListItem disablePadding key={item.text}>
                    <ListItemButton
                      variant="navigation"
                      tabIndex={index + 1}
                      onClick={() => handleViewChange(`${item.action}`)}
                    >
                      <ListItemIcon>
                        <Icon
                          variant="navigation"
                          baseClassName="material-icons-outlined"
                          className={
                            openView === item.action ? "navItemActive" : ""
                          }
                        >
                          {item.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        variant="navigation"
                        className={
                          openView === item.action ? "navItemActive" : ""
                        }
                        primary={item.text}
                        sx={{
                          wordWrap: "break-word",
                          textAlign: "center",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }

              return listItem;
            })}
          </List>
        </Grid>
      </Grid>
      {/* flex 1 drops remaining item(s) to the bottom */}
      <Divider style={{ flex: 1 }} />
      <List>
        <ListItem key="navigation_logout" disablePadding>
          <ListItemButton
            variant="navigation"
            id="logout-btn"
            onClick={() => handleLogout()}
            tabIndex={availableUserActions.length + 2}
          >
            <ListItemIcon>
              <Icon variant="navigation">
                {i18n.t("navigation.logout.icon")}
              </Icon>
            </ListItemIcon>
            <ListItemText
              disableTypography
              variant="navigation"
              primary={i18n.t("navigation.logout.text")}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default NavigationItems;
