import * as actionTypes from "../actions/actionTypes";
import moment from "moment";
import { findTrainArrivalAndDepartures } from "../../utils/trains";

const initialState = {
  loading: false,
  popup: {
    latitude: 0,
    longitude: 0,
    properties: {},
  },
  openview: "map",
  appLoading: [],
  events: [],
  mapEvents: [],
  eventId: undefined,
  filterListEventIds: [],
  fetchEvents: [],
  trains: [],
  trainArrivals: [],
  trainDepartures: [],
  isFiltered: false,
  startFilter: "",
  endFilter: "",
  selected: [],

  // Structure
  // eventComments: {
  //   "b8f27525-ab0b-47a8-ad29-46a890c938c9": {
  //     comments: ["Jeb jeb"],
  //     newComments: 0,
  //   },
  // },
  eventComments: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_VIEW:
      return {
        ...state,
        openview: action.openview,
      };

    case actionTypes.ADD_TO_LOADING:
      return {
        ...state,
        appLoading: [...state.appLoading, action.loadingItem],
      };

    case actionTypes.REMOVE_FROM_LOADING: {
      const currentLoadingState = [...state.appLoading];
      currentLoadingState.splice(
        currentLoadingState.indexOf(action.loadingItem),
        1
      );
      return {
        ...state,
        appLoading: currentLoadingState,
      };
    }

    case actionTypes.EVENTS_DATA:
      return {
        ...state,
        events: action.events,
      };

    case actionTypes.UPDATE_EVENTS: {
      const receivedEvents = action.events;
      const copyEvents = [...state.events];

      const eventUuid = copyEvents.map((e) => {
        return e.uuid;
      });

      receivedEvents.map((e) => {
        const index = eventUuid.indexOf(e.uuid);
        if (index === -1) {
          copyEvents.push(e);
        } else {
          copyEvents.splice(index, 1, e);
        }
      });

      return {
        ...state,
        events: copyEvents.filter(function (k) {
          return k.state === "active";
        }),
      };
    }

    case actionTypes.UPDATE_SINGLE_EVENT: {
      const receivedEvent = action.event;
      const copyEvents = [...state.events];

      const eventUuid = copyEvents.map((e) => {
        return e.uuid;
      });

      const index = eventUuid.indexOf(receivedEvent.uuid);
      if (index === -1) {
        copyEvents.push(receivedEvent);
      } else {
        copyEvents.splice(index, 1, receivedEvent);
      }

      return {
        ...state,
        events: copyEvents,
      };
    }

    case actionTypes.REMOVE_EVENT: {
      const removedEvent = action.event;
      const copyEvents = [...state.events];

      const eventUuid = copyEvents.map((e) => {
        return e.uuid;
      });

      const index = eventUuid.indexOf(removedEvent.uuid);
      if (index !== -1) {
        copyEvents.splice(index, 1);
      }

      return {
        ...state,
        events: copyEvents,
      };
    }

    case actionTypes.UPDATE_EVENT_COMMENTS: {
      const eventCommentsCopy = { ...state.eventComments };
      const eventId = action.eventId;
      const comments = action.comments;

      eventCommentsCopy[eventId] = comments;

      return {
        ...state,
        eventComments: eventCommentsCopy,
      };
    }

    case actionTypes.SELECTED_EVENT_ID:
      return {
        ...state,
        eventId: action.eventId,
      };

    case actionTypes.FILTER_LIST_EVENT_IDS:
      return {
        ...state,
        filterListEventIds: action.filterListEventIds,
      };

    case actionTypes.FETCH_EVENTS:
      return {
        ...state,
        fetchEvents: action.fetchEvents,
      };

    case actionTypes.TRAINS_DATA: {
      const { trainArrivals, trainDepartures } = findTrainArrivalAndDepartures(
        action.trains
      );

      return {
        ...state,
        trains: action.trains,
        trainArrivals: trainArrivals,
        trainDepartures: trainDepartures,
      };
    }

    case actionTypes.UPDATE_TRAINS: {
      const receivedTrains = action.events;
      const copyTrains = [...state.events];

      const trainUuid = copyTrains.map((e) => {
        return e.uuid;
      });

      receivedTrains.map((e) => {
        const index = trainUuid.indexOf(e.uuid);
        if (index === -1) {
          copyTrains.push(e);
        } else {
          copyTrains.splice(index, 1, e);
        }
      });

      const { trainArrivals, trainDepartures } =
        findTrainArrivalAndDepartures(copyTrains);

      return {
        ...state,
        trains: copyTrains,
        trainArrivals: trainArrivals,
        trainDepartures: trainDepartures,
      };
    }

    case actionTypes.ADD_TO_SELECTED: {
      let selectedCopy = [...state.selected];
      const dt = action.time;
      const index = selectedCopy.indexOf(dt);
      if (index !== -1) {
        selectedCopy.splice(index, 1);
      } else {
        selectedCopy.push(dt);
      }
      return {
        ...state,
        selected: selectedCopy,
      };
    }

    case actionTypes.EVENTS_TIME_FILTER: {
      const startFilter = moment(action.filter.start);
      const endFilter = moment(action.filter.end);

      const eventsCopy = [...state.events];
      let filteredEvents = [];
      eventsCopy.forEach((value) => {
        const startTime = moment(value.startTime);
        const endTime = moment(value.endTime);
        const isForNow = value.isForNow;

        // If endTime is null
        if (value.endTime === null && isForNow) {
          if (startTime.isSameOrBefore(endFilter)) {
            filteredEvents.push(value);
          }
        } else {
          if (
            startFilter.isSameOrBefore(endTime) &&
            endFilter.isSameOrAfter(startTime)
          ) {
            filteredEvents.push(value);
          }
        }
      });

      return {
        ...state,
        isFiltered: true,
        mapEvents: filteredEvents,
        startFilter: startFilter,
        endFilter: endFilter,
      };
    }

    case actionTypes.RESET_TIME_FILTER:
      return {
        ...state,
        mapEvents: state.events,
        isFiltered: false,
        startFilter: "",
        endFilter: "",
        selected: [],
      };

    case actionTypes.LOADING_STATE:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default reducer;
