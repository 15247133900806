import React, { useRef } from "react";
import { Flex } from "./Layout";
import SchedulerHeader from "./_components/SchedulerHeader";

import { getTodayPosition } from "../../utils/schedulerUtils";

// const schedulerStyles = {
//   width: "100%",
//   height: "100%",
// };

export default function MiniScheduler({ showViewAction, openview }) {
  const dayColumnRef = useRef(null);
  function executeScroll() {
    if (dayColumnRef.current) {
      dayColumnRef.current.scrollLeft = getTodayPosition();
    }
  }

  return (
    <Flex>
      <SchedulerHeader
        dayColumnRef={dayColumnRef}
        executeScroll={executeScroll}
        openview={openview}
        onChangeView={showViewAction}
      />
    </Flex>
  );
}
