import React from "react";
import { useSelector } from "react-redux";

import TrackRow from "./TrackRow";
// PRX-154 - Point features disabled for now
// import PointRow from "./PointRow";
import ArrivalTrainRow from "./ArrivalTrainRow";
import DepartureTrainRow from "./DepartureTrainRow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EventTarget = (props) => {
  // PRX-154 - Point features disabled for now
  // const { trackName, pointName, arrivalTrain, departureTrain } = props;
  const { trackName, arrivalTrain, departureTrain } = props;
  const selectedSiteId = useSelector((state) => state.permissions.selectedSite);
  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  const pointLayerData = useSelector((state) => state.map.pointLayerData);
  const trainArrivals = useSelector(
    (state) => state.userinterface.trainArrivals
  );
  const trainDepartures = useSelector(
    (state) => state.userinterface.trainDepartures
  );
  let tracks = [];
  let points = [];

  trackLayerData.features.map((element) => {
    element.properties.siteId === selectedSiteId &&
      element.properties.trackName &&
      tracks.push(element.properties.trackName);
  });
  tracks.sort();

  pointLayerData.features.map((element) => {
    element.properties.siteId === selectedSiteId &&
      element.properties.pointName &&
      points.push(element.properties.pointName);
  });
  points.sort();

  const handleTrackChange = (event) => {
    const {
      target: { value },
    } = event;
    props.handleTrackChange(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChangeArrivalTrain = (event) => {
    const {
      target: { value },
    } = event;
    props.changeArrivalTrain(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChangeDepartureTrain = (event) => {
    const {
      target: { value },
    } = event;
    props.changeDepartureTrain(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // PRX-154 - Point features disabled for now
  // const handlePointChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   props.handlePointChange(
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  return (
    <div>
      <TrackRow
        trackName={trackName}
        handleTrackChange={handleTrackChange}
        tracks={tracks}
        menu={MenuProps}
      />

      <ArrivalTrainRow
        arrivalTrain={arrivalTrain}
        onChangeArrivalTrain={onChangeArrivalTrain}
        trains={trainArrivals}
        menu={MenuProps}
      />

      <DepartureTrainRow
        departureTrain={departureTrain}
        onChangeDepartureTrain={onChangeDepartureTrain}
        trains={trainDepartures}
        menu={MenuProps}
      />
      {/* PRX-154 - Point features disabled for now
      <PointRow
        pointName={pointName}
        handlePointChange={handlePointChange}
        points={points}
        menu={MenuProps}
      />
      */}
    </div>
  );
};

export default EventTarget;
