import * as actionTypes from "../actions/actionTypes";

const initialState = {
  roles: {},
  availableSites: {},
  selectedSite: "",
  userOrg: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SITE_ROLES:
      return {
        ...state,
        roles: action.roles,
      };

    case actionTypes.CHANGE_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.selectedSite,
      };

    case actionTypes.AVAILABLE_SITES: {
      return {
        ...state,
        availableSites: action.availableSites,
      };
    }

    case actionTypes.SET_USER_ORG:
      return {
        ...state,
        userOrg: action.userOrg,
      };

    default:
      return state;
  }
};

export default reducer;
