import "./index.css";
import MapChooser from "../../../../../Map/MapChooser";
import Scheduler from "../../../../../Scheduler";

const HalfSchedulerMap = (props) => {
  const { schedulerRef, openview, siteId } = props;

  return (
    <div className="content">
      <div className="map">
        <MapChooser view={openview} desktop={true} siteId={siteId} />
      </div>
      <div className="scheduler">
        <Scheduler
          showViewAction={props.showViewAction}
          openview={openview}
          schedulerRef={schedulerRef}
        />
      </div>
    </div>
  );
};

export default HalfSchedulerMap;
