import jwt_decode from "jwt-decode";
import { authenticationService } from "../components/Auth/_services";

const parseRoles = () => {
  const user = authenticationService.currentUserValue;
  let obj = {};
  let rolesObj = {};
  let sitesObj = {};
  let org = null;
  if (user) {
    const decoded = jwt_decode(user.token);
    if (decoded[process.env.REACT_APP_AUTH_NAMESPACE_ROLES]) {
      const roles = decoded[process.env.REACT_APP_AUTH_NAMESPACE_ROLES];

      roles.forEach((role) => {
        const sites = role.org.sites;
        org = role.org.id;
        sites.forEach((site) => {
          let licenses = [];
          const siteRoles = site.roles;
          siteRoles.forEach((siteRole) => {
            licenses.push(siteRole.name);
          });
          sitesObj[site.id] = site.name;
          rolesObj[site.name] = licenses;
        });
      });
    }
    obj["sites"] = sitesObj;
    obj["roles"] = rolesObj;
    obj["org"] = org;
  }

  return obj;
};

const parseProfile = () => {
  const user = authenticationService.currentUserValue;
  let siteId = null;
  if (user) {
    const decoded = jwt_decode(user.token);
    if (decoded[process.env.REACT_APP_AUTH_NAMESPACE_PROFILE]) {
      const profile = decoded[process.env.REACT_APP_AUTH_NAMESPACE_PROFILE];
      siteId = profile.defaultsite;
    }
  }
  return siteId;
};

const parseMqttEmail = () => {
  const user = authenticationService.currentUserValue;
  let email = null;
  if (user) {
    const decoded = jwt_decode(user.token);
    if (decoded[process.env.REACT_APP_AUTH_NAMESPACE_EMAIL]) {
      email = decoded[process.env.REACT_APP_AUTH_NAMESPACE_EMAIL];
    }
  }
  return email;
};

const parseMqttPassword = () => {
  const user = authenticationService.currentUserValue;
  let pass = null;
  if (user) {
    const decoded = jwt_decode(user.token);
    if (decoded[process.env.REACT_APP_AUTH_NAMESPACE_PROFILE]) {
      const profile = decoded[process.env.REACT_APP_AUTH_NAMESPACE_PROFILE];
      const external = profile.external;
      if (external) {
        for (let ext of external) {
          if (ext.group === "StoraEnsoGrp") {
            pass = ext.apipass;
            break;
          }
        }
      }
    }
  }
  return pass;
};

const getUser = () => {
  return authenticationService.currentUserValue;
};

const permissionParser = {
  parseRoles,
  parseProfile,
  parseMqttEmail,
  parseMqttPassword,
  getUser,
};

export default permissionParser;
