import { useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "75px",
    marginTop: "5px",
    borderStyle: "none",
    backgroundColor: "transparent",
  },
  contentContainer: {
    float: "left",
    maxWidth: "375px",
    width: "390px",
    marginTop: "-2px",
    backgroundColor: "transparent",
    position: "fixed",
    zIndex: "5",
  },
  alertBox: {
    height: "47px",
    width: "375px",
    [theme.breakpoints.down("md")]: {
      width: "85vw",
    },
  },
  alertText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
  },
  arrowButton: {
    width: "32px",
    marginLeft: "25px",
    marginTop: "6px",
    marginBottom: "8px",
    color: theme.palette.common.white,
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
  title: {
    fontSize: "large",
    marginLeft: "10px",
    marginBottom: "-2px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
      marginBottom: "0px",
    },
  },
  header: {
    float: "left",
    marginLeft: "25px",
    marginBottom: "-2px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
    },
  },
  headerRight: {
    float: "right",
    marginTop: "14px",
    marginRight: "14px",
    color: theme.palette.common.white,
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const { severity, text, closable, autoClose = false } = props;

  useEffect(() => {
    if (severity && autoClose) {
      const max = 5;
      let i = 0;
      const interval = setInterval(() => {
        i++;
        if (i >= max) {
          props.setSeverity(false);
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [severity, autoClose]);

  if (severity) {
    return (
      <div id="mainContainer" className={classes.mainContainer}>
        <div id="contentContainer" className={classes.contentContainer}>
          {closable ? (
            <Alert
              className={classes.alertBox}
              severity={severity}
              position="fixed"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    props.setSeverity(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>
                <Typography className={classes.alertText}>{text}</Typography>
              </AlertTitle>
            </Alert>
          ) : (
            <Alert
              className={classes.alertBox}
              severity={severity}
              position="fixed"
            >
              <AlertTitle>
                <Typography className={classes.alertText}>{text}</Typography>
              </AlertTitle>
            </Alert>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Notification;
