export function slice2LongItems(items) {
  const itemWidth = 80; // adjust this value to your desired item width
  const containerWidth = 250; // adjust this value to your desired container width
  const itemsPerRow = Math.floor(containerWidth / itemWidth);

  let itemRows = [];
  for (let i = 0; i < items.length; i += itemsPerRow) {
    itemRows.push(items.slice(i, i + itemsPerRow));
  }
  return itemRows;
}
