import React from "react";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    margin: "0px 0px 0px 0px !important",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  panelSummary: {
    marginRight: "0px",
    backgroundColor: theme.palette.common.white,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.white,
      minHeight: "42px !important",
      maxHeight: "42px !important",
    },
  },
  descriptionContainer: {
    backgroundColor: theme.palette.common.white,
    marginTop: "8px",
    marginBottom: "0px",
    fontSize: "16px",
    fontWeight: 500,
  },
  inputTextFieldContainer: {
    marginBottom: "12px",
  },
  accordionTextFieldContainer: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    marginTop: "8px",
    marginBottom: "0px",
    fontSize: "16px",
    fontWeight: 500,
    paddingBottom: "0px",
  },
  eventDescriptionTitle: {
    ...theme.typography,
    fontSize: "12px !important",
    marginTop: "5px",
    marginBottom: "5px",
    fontWeight: 400,
    marginLeft: "-6px !important",
    color: theme.palette.common.black,
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    marginLeft: "0px !important",
    width: "100%",
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  formControlRoot2: {
    borderRadius: "4px !important",
    border: "none",
    marginTop: "2px !important",
    marginLeft: "2px !important",
    marginBottom: "2px !important",
    paddingBottom: "8px !important",
    width: "99%",
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  inputRootText: {
    height: "40px",
    marginLeft: "10px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  inputRootAccordion: {
    height: "40px",
    marginRight: "10px",
    marginLeft: "10px !important",
    marginTop: "4px !important",
    marginBottom: "2px !important",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  helperText: {
    marginLeft: "10px !important",
  },
}));

const EventDescription = (props) => {
  const classes = useStyles();
  const { description, shortDescription, isEditable, lockedForEdit } = props;
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  const handleShortDescriptionChange = (event) => {
    props.changeShortDesc(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    props.changeDesc(event.target.value);
  };

  return (
    <div className={classes.descriptionContainer}>
      <div
        id="event-description-container"
        className={classes.inputTextFieldContainer}
      >
        <TextField
          id="event-description-field"
          classes={{ root: classes.formControlRoot }}
          helperText={`${description.length}/${80}`}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          inputProps={{
            maxLength: 80,
          }}
          InputProps={{
            classes: {
              underline: classes.underline,
              root: classes.inputRootText,
            },
          }}
          variant="standard"
          placeholder={i18n.t("navigation.eventInfoChips.specify")}
          value={description !== null && description ? description : ""}
          onChange={handleDescriptionChange}
          multiline
          rows={2}
          disabled={!isEditable || !lockedForEdit}
        />
      </div>
      <div
        id="event-short-description-container"
        className={classes.accordionTextFieldContainer}
      >
        <Accordion
          id="Accordion"
          expanded={expanded === "panel"}
          onChange={handleChange("panel")}
          elevation={0}
          classes={{
            root: classes.MuiAccordionroot,
          }}
        >
          <AccordionSummary
            id="accordionSummary"
            className={classes.panelSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.eventDescriptionTitle}>
              {i18n.t("navigation.eventInfo.eventDescriptionTitle")}
            </Typography>
          </AccordionSummary>
          <TextField
            id="event-short-description-field"
            InputProps={{
              classes: {
                underline: classes.underline,
                root: classes.inputRootAccordion,
              },
            }}
            classes={{ root: classes.formControlRoot2 }}
            variant="standard"
            multiline
            rows={2}
            value={
              shortDescription !== null && shortDescription
                ? shortDescription
                : ""
            }
            onChange={handleShortDescriptionChange}
            disabled={!isEditable || !lockedForEdit}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default EventDescription;
