import i18n from "i18next";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    width: "100%",
    marginTop: "12px",
    backgroundColor: theme.palette.common.white,
  },
  severityContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginTop: "8px",
    marginBottom: "18px",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "6px",
  },
  column: {
    display: "table",
    marginBottom: "-28px",
  },
  severityTitle: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 600,
    marginBottom: "6px !important",
    color: theme.palette.common.black,
  },
  severityTitleGrey: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    marginTop: "4px !important",
    marginLeft: "8px !important",
    color: "rgba(0, 0, 0, 0.5)",
  },
  inputTextFieldContainer: {
    marginBottom: "32px",
    marginTop: "12px",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    marginTop: "2px !important",
    marginBottom: "2px !important",
    marginLeft: "0px !important",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    paddingBottom: "2px !important",
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  inputRootText: {
    color: "rgba(128,128,128, 0.5) !important",
    height: "40px",
    marginRight: "10px",
    marginLeft: "10px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  inputRootAccordion: {
    height: "50px",
    marginRight: "10px",
    marginLeft: "10px !important",
    marginTop: "2px !important",
    marginBottom: "2px !important",
  },
  inputLabelRoot: {
    border: "none",
  },
  formHelperTextRoot: {
    border: "none",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  chipStop: {
    display: "table-cell",
    float: "left",
    width: "195px",
    height: "50px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
  chipLimited: {
    display: "table-cell",
    float: "right",
    width: "195px",
    height: "50px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
  chipStopText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    "&:active": {
      cursor: "default",
      pointerEvents: "none",
    },
  },
  chipLimitedText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    "&:active": {
      cursor: "default",
      pointerEvents: "none",
    },
  },
  selectedStop: {
    "&&": {
      backgroundColor: "rgba(199, 63, 0, 1) !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "rgba(199, 63, 0, 0.5) !important",
      color: "white !important",
    },
  },
  selectedLimited: {
    "&&": {
      backgroundColor: "rgba(255, 195, 0, 1) !important",
      color: "black !important",
    },
    "&:disabled": {
      backgroundColor: "rgba(255, 195, 0, 0.5) !important",
      color: "black !important",
    },
  },
  btnGroup: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
  },
}));

const SeverityPicker = (props) => {
  const { criticality, restriction } = props;
  const classes = useStyles();

  const onChangeCriticality = (event) => {
    props.changeCriticality(event.target.value);
  };

  const onChangeRestriction = (event) => {
    props.changeRestriction(event.target.value);
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <Typography className={classes.severityTitle}>
          {i18n.t("navigation.eventInfoSeverities.title")}
        </Typography>
        <div className={classes.chipContainer}>
          <ToggleButtonGroup
            id="event-severity-toggle"
            classes={{ root: classes.btnGroup }}
            color="primary"
            value={criticality}
            exclusive
            onChange={onChangeCriticality}
          >
            <ToggleButton
              id="event-severity-stop"
              disableRipple
              classes={{ selected: classes.selectedStop }}
              selectedcolor="rgba(204, 51, 0, 1.0)"
              className={classes.chipStop}
              value="stop"
            >
              <Typography className={classes.chipStopText}>
                {i18n.t("navigation.eventInfoSeverities.stop")}
              </Typography>
            </ToggleButton>
            <ToggleButton
              id="event-severity-restricted"
              disableRipple
              classes={{ selected: classes.selectedLimited }}
              selectedcolor="rgba(255, 195, 0, 0.4)"
              className={classes.chipLimited}
              value="restricted"
            >
              <Typography className={classes.chipLimitedText}>
                {i18n.t("navigation.eventInfoSeverities.limited")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div
          id="event-restriction-container"
          className={classes.inputTextFieldContainer}
        >
          <Typography className={classes.severityTitle}>
            {i18n.t("navigation.eventInfoSeverities.restrictedTitle")}
          </Typography>
          <TextField
            id="event-restriction-field"
            InputProps={{
              classes: {
                underline: classes.underline,
                root: classes.inputRootAccordion,
              },
            }}
            classes={{ root: classes.formControlRoot }}
            InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
            FormHelperTextProps={{
              classes: { root: classes.formHelperTextRoot },
            }}
            variant="standard"
            placeholder={i18n.t(
              "navigation.eventInfoSeverities.restrictedDefaultText"
            )}
            multiline
            rows={2}
            value={restriction}
            onChange={onChangeRestriction}
          />
          <Typography className={classes.severityTitleGrey}>
            {i18n.t("navigation.eventInfoSeverities.mandatoryText")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SeverityPicker;
