import * as React from "react";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setSelectedEventId, openView } from "../../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "content",
    width: "100%",
    marginTop: "-3px",
    backgroundColor: theme.palette.common.white,
  },
  panelSummary: {
    backgroundColor: theme.palette.common.proxBlue,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.proxBlue,
      minHeight: "42px !important",
      maxHeight: "42px !important",
      color: "white",
      fontSize: "13px !important",
      fontWeight: "600 !important",
    },
  },
  column: {
    display: "block",
    float: "left",
    width: "97.2%",
    height: "122px",
    marginLeft: "-16px",
  },
  sendButton: {
    color: "white",
  },
  iconButtonPosition: {
    alignItems: "center",
    margin: "0 !important",
  },
  iconButtonBackground: {
    backgroundColor: theme.palette.common.proxBlue,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    width: "32px",
    height: "32px",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    width: "100%",
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  inputRootText: {
    width: "320px",
    height: "40px",
    marginRight: "4px !important",
    marginLeft: "8px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  cardItem: {
    width: "406px !important",
    border: "none !important",
    boxShadow: "none !important",
    marginLeft: "-16px",
    marginBottom: "-16px",
  },
  title: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: theme.palette.common.black,
  },
  smalltitle: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: theme.palette.common.black,
  },
  text: {
    ...theme.typography,
    marginTop: "3px",
    marginLeft: "30px",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: theme.palette.common.black,
  },
}));

const EventTypeDiv = (props) => {
  const eventType = props.eventType;
  const colors = {
    maintenance: "#ffc300",
    trackcapacityreservation: "#59a863",
    servicelocationreservation: "#b340ff",
    alarm: "#ff0000",
    other: "#959595",
  };
  return (
    <div
      style={{
        float: "left",
        width: "10px",
        height: "100%",
        color: colors[eventType],
        backgroundColor: colors[eventType],
      }}
    ></div>
  );
};

const EventCriticalityDiv = (props) => {
  const criticality = props.criticality;
  const colors = {
    stop: "#c73f00",
    restricted: "#ffc300",
  };
  return (
    <div
      style={{
        float: "left",
        width: "10px",
        height: "100%",
        color: colors[criticality],
        backgroundColor: colors[criticality],
      }}
    ></div>
  );
};

const DateDiv = (props) => {
  const { start, end } = props;
  const formatStart = new Date(start).toLocaleString([], {
    dateStyle: "short",
    timeStyle: "short",
  });
  let formatEnd = null;
  if (end) {
    formatEnd = new Date(end).toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });
  }
  return (
    <div
      style={{
        fontWeight: "600",
      }}
    >
      {formatStart} {" - "} {formatEnd}
    </div>
  );
};

const TextDiv = (props) => {
  const text = props.text;
  return <div>{text}</div>;
};

const MessageDiv = (props) => {
  const { messages } = props;
  return (
    <div>
      {i18n.t("navigation.eventList.messages")} {messages}{" "}
      {/* {i18n.t("navigation.eventList.unread")} {newMessages} */}
    </div>
  );
};

const EventList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEventClick = (uuid) => {
    dispatch(setSelectedEventId(uuid));
    if (["filterListFull", "eventListFull"].includes(props.openView)) {
      dispatch(openView("eventInfoEditFull"));
    }

    if (["filterListHalf", "eventListHalf"].includes(props.openView)) {
      dispatch(openView("eventInfoEditHalf"));
    }
  };

  const getMsgCount = (messages, eventId) => {
    if (messages.length > 0) {
      let msg = messages.filter(function (k) {
        return k[0] === eventId;
      });
      if (msg !== undefined && msg !== null && msg.length > 0) {
        msg = msg[0];
        return msg[1].comments.length;
      }
    }
    return 0;
  };

  // TODO: Implement this when unread messages supported
  // const getUnreadCount = (messages, eventId) => {
  //   const msg = messages.filter(function (k) {
  //     return k[0] === eventId;
  //   })[0];
  //   return msg[1].newComments;
  // };

  const colors = {
    maintenance: "#ffc300",
    trackcapacityreservation: "#59a863",
    servicelocationreservation: "#b340ff",
    alarm: "#ff0000",
    other: "#959595",
  };

  return (
    <div className={classes.componentContainer}>
      <Accordion defaultExpanded={props.expanded}>
        <AccordionSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Icon sx={{ fontSize: "25px", marginRight: "10px" }}>event</Icon>
          {i18n.t("navigation.filterList.events")}
        </AccordionSummary>
        <AccordionDetails
          style={{
            marginTop: "-8px",
            width: "450px",
          }}
        >
          {props.events.map((item) => (
            <div
              className={classes.column}
              key={item.uuid}
              onClick={() => handleEventClick(item.uuid)}
              style={{ borderBottom: "2px solid " + colors[item.eventType] }}
            >
              <EventTypeDiv eventType={item.eventType}></EventTypeDiv>
              <EventCriticalityDiv
                criticality={item.criticality}
              ></EventCriticalityDiv>
              <div className={classes.text}>
                <DateDiv start={item.startTime} end={item.endTime}></DateDiv>
                <TextDiv
                  text={i18n.t("navigation.eventInfoChips." + item.eventType)}
                ></TextDiv>
                <TextDiv text={item.description}></TextDiv>
                <MessageDiv
                  messages={getMsgCount(props.messages, item.uuid)}
                  // newMessages={getUnreadCount(props.messages, item.uuid)}
                ></MessageDiv>
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EventList;
