import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateEventComments } from "../../../../store/actions";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import { Button, Box, Tooltip, useTheme } from "@mui/material";
import EventHeader from "./EventHeader";
import EventState from "./EventInfoCreate/EventStateCreate/EventStateCreate";
import DateTimePicker from "./EventInfoCreate/DateTimePickerCreate/DateTimePickerCreate";
import EventPicker from "./EventInfoCreate/EventPickerCreate/EventPickerCreate";
import EventDescription from "./EventInfoCreate/DescriptionsCreate/EventDescriptionCreate";
import SeverityPicker from "./EventInfoCreate/SeverityPickerCreate/SeverityPickerCreate";
import EventTarget from "./EventInfoCreate/EventTargetCreate";
import EventOrgFields from "./EventInfoCreate/EventOrgFieldsCreate/EventOrgFieldsCreate";
import {
  postEvents,
  postEventMessage,
  getOrgFields,
} from "../../../../services/events";
// PRX-166
// import EventPhases from "./EventInfoCreate/EventPhasesCreate/EventPhasesCreate";
import moment from "moment";
import Notification from "./Notification";
import {
  calculateDurationInMs,
  convertMsToHoursMinutes,
  roundToClosest15,
  calculateEndTimeByStart,
  formatDt,
} from "../../../../utils/times";
import { showEventHistoryAndMessages } from "../../../../utils/licenses";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    marginTop: "0px",
    backgroundColor: theme.palette.common.proxBlue,
  },
  eventContainer: {
    height: "100vh",
    paddingTop: "0px",
  },
  alertBox: {
    height: "47px",
    maxWidth: "390px",
    width: "100vw",
    [theme.breakpoints.down("md")]: {
      width: "85vw",
    },
    marginBottom: "3px",
    backgroundColor: "transparent",
    marginTop: "-18px",
    zIndex: "5",
  },
  alertText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
  },
  iconButton: {
    marginLeft: "10px",
    marginTop: "8px !important",
    color: theme.palette.common.white + " !important",
    display: "inline-flex !important",
  },
  header: {
    display: "contents",
    float: "left",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  divider: {
    display: "inline-block",
    width: "218px !important",
  },
  title: {
    fontSize: "16px !important",
    marginLeft: "15px !important",
    marginBottom: "5px !important",
  },
  column: {
    display: "inherit",
    marginTop: "6px",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.common.white,
    padding: "8px",
    paddingBottom: "10vh",
    transition: "opacity 1s",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100vh",
  },
  row: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    padding: "10px",
    fontSize: "22px",
  },
  rowNoBorders: {
    backgroundColor: theme.palette.common.white,
    padding: "10px",
    fontSize: "22px",
  },
  rowLocationBased: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    padding: "10px",
    fontSize: "22px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "22px",
  },
  saveButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  removeButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  buttonRow: {
    backgroundColor: theme.palette.common.white,
    // This should be same as mobile navigation bar height
    marginBottom: "56px",
    padding: "10px",
    fontSize: "22px",
  },
  alertDiv: {
    marginTop: "20px",
  },
}));

const EventInfoCreate = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const { openview, siteId, orgId } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalDuration, setTotalDuration] = useState({
    apiFormat: 0,
    uiFormat: "00:00",
  });
  const [validDuration, setValidDuration] = React.useState(true);
  const [saveToolTip, setSaveToolTip] = useState("");
  const [eventState, setEventState] = useState("planned");
  const [eventPicker, setEventPicker] = useState("");
  const [criticality, setCriticality] = useState("stop");
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [specify, setSpecify] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [restriction, setRestriction] = useState("");
  const [trackName, setTrackName] = useState([]);
  const [pointName, setPointName] = useState([]);
  const [arrivalTrain, setArrivalTrain] = useState([]);
  const [arrivalsIds, setArrivalsIds] = useState([]);
  const [departureTrain, setDepartureTrain] = useState([]);
  const [departuresIds, setDeparturesIds] = useState([]);
  // const [phases, setPhases] = useState([]);
  const [customValues, setCustomValues] = useState([]);
  const [resetCustomValuesFlag, setResetCustomValuesFlag] = useState(false);
  const [isForNow, setIsForNow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const [showWarning, setShowWarning] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [autoClose, setAutoClose] = useState(true);
  const [text, setText] = useState("");
  // const [durationSum, setDurationSum] = useState(0);
  const [orgFields, setOrgFields] = useState([]);
  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  const pointLayerData = useSelector((state) => state.map.pointLayerData);
  const roles = useSelector((state) => state.permissions.roles);
  let trackFilteredData = [];
  let locationTracks = [];
  let pointFilteredData = [];
  const locationPoints = [];

  const resetForm = () => {
    const startDateObj = roundToClosest15(new Date());
    const endDateObj = calculateEndTimeByStart(startDateObj);
    setStartDate(startDateObj);
    setEndDate(endDateObj);
    const { ms, d } = calculateDurationInMs(startDateObj, endDateObj);
    const hoursMins = convertMsToHoursMinutes(ms, d);
    setTotalDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
    setEventPicker("");
    setCriticality("stop");
    setSpecify("");
    setShortDesc("");
    setMessage("");
    setRestriction("");
    setTrackName([]);
    setPointName([]);
    setArrivalTrain([]);
    setArrivalsIds([]);
    setDepartureTrain([]);
    setDeparturesIds([]);
    // PRX-166
    // setPhases([]);
    setIsForNow(false);
    setDisabled(false);
    setResetCustomValuesFlag(!resetCustomValuesFlag);
    setAutoClose(true);
  };

  useEffect(() => {
    updateDatesIsForNow(true);
    const requestOrgFields = getOrgFields(orgId);
    requestOrgFields
      .then((response) => {
        let ordered = response.data.orgfields;
        ordered.sort(function compare(a, b) {
          return a.position - b.position;
        });
        setOrgFields(ordered);
      })
      .catch((error) => {
        console.log("Event OrgFields " + error);
      });
  }, []);

  // PRX-166
  // useEffect(() => {
  //   let sum = 0;
  //   phases.forEach((phase) => {
  //     sum = sum + phase.duration;
  //   });

  //   if (!isNaN(sum)) {
  //     setDurationSum(sum);
  //   }
  // }, [phases]);

  const requiredOrgFieldMissing = () => {
    let required = orgFields.filter(function (k) {
      return k.required && k.type !== "checkbox" && k.type !== "switch";
    });

    if (customValues.length < 1 && required.length > 0) {
      return true;
    }

    for (let orgField of required) {
      let vals = customValues.filter(function (k) {
        return (
          k !== undefined && k.fieldId === orgField.uuid && k.value.length > 0
        );
      });
      if (vals.length < 1) return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      startDate === null ||
      (endDate === null && !isForNow) ||
      trackName.length === 0 ||
      (criticality === "restricted" && restriction.length === 0) ||
      moment(startDate).isAfter(endDate) ||
      moment(endDate).isBefore(startDate) ||
      (totalDuration.apiFormat < 15 && !isForNow) ||
      (totalDuration.uiFormat.length < 5 && !isForNow) ||
      eventPicker === "" ||
      requiredOrgFieldMissing() ||
      !eventState
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    trackName,
    criticality,
    restriction,
    startDate,
    endDate,
    totalDuration,
    eventPicker,
    customValues,
    eventState,
  ]);

  useEffect(() => {
    if (disabled) {
      let str = i18n.t("navigation.eventInfoEdit.checkFields");
      if (startDate === null) {
        str = str + i18n.t("navigation.eventInfoEdit.startTime");
      }
      if (endDate === null && !isForNow) {
        str = str + i18n.t("navigation.eventInfoEdit.endTime");
      }
      if (moment(startDate).isAfter(endDate)) {
        str = str + i18n.t("navigation.eventInfoEdit.dates");
      }
      if (
        (totalDuration.apiFormat < 15 && !isForNow) ||
        (totalDuration.uiFormat.length < 5 && !isForNow)
      ) {
        str = str + i18n.t("navigation.eventInfoEdit.duration");
      }
      if (eventPicker === "") {
        str = str + i18n.t("navigation.eventInfoEdit.eventType");
      }
      if (criticality === "restricted" && restriction.length === 0) {
        str = str + i18n.t("navigation.eventInfoEdit.restriction");
      }
      if (trackName.length === 0) {
        str = str + i18n.t("navigation.eventInfoEdit.locationTracks");
      }
      if (requiredOrgFieldMissing()) {
        str = str + i18n.t("navigation.eventInfoEdit.customValues");
      }

      if (!eventState) {
        str = str + i18n.t("navigation.eventInfoEdit.eventState");
      }
      str = str.slice(0, -2);
      setSaveToolTip(str);
    } else {
      setSaveToolTip("");
    }
  }, [
    disabled,
    trackName,
    eventState,
    criticality,
    restriction,
    startDate,
    endDate,
    totalDuration,
    eventPicker,
    customValues,
  ]);

  // PRX-166
  // useEffect(() => {
  //   if (!isForNow && totalDuration.apiFormat > 0 && durationSum > 0) {
  //     if (durationSum > totalDuration.apiFormat) {
  //       setShowWarning(true);
  //     } else {
  //       setShowWarning(false);
  //     }
  //   }
  // }, [totalDuration, durationSum]);

  const updateDatesIsForNow = (initial) => {
    let dateObj = null;
    if (startDate === null) {
      dateObj = new Date();
    } else {
      dateObj = startDate;
    }
    const startDateObj = roundToClosest15(dateObj);
    const endDateObj = calculateEndTimeByStart(startDateObj);
    setStartDate(startDateObj);
    setEndDate(endDateObj);
    if (initial) {
      const { ms, d } = calculateDurationInMs(startDateObj, endDateObj);
      const hoursMins = convertMsToHoursMinutes(ms, d);
      setTotalDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
    }
  };

  const onChangeStart = (newStartDate) => {
    setStartDate(newStartDate);
    if (
      moment(newStartDate).isAfter(endDate) ||
      moment(endDate).isBefore(newStartDate)
    ) {
      setSeverity("error");
      setText(i18n.t("notifications.checkDates"));
      setAutoClose(false);
    } else {
      setAutoClose(true);
      const { ms, d } = calculateDurationInMs(newStartDate, endDate);
      const hoursMins = convertMsToHoursMinutes(ms, d);
      setTotalDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
      setValidDuration(true);
    }
  };

  const onChangeEnd = (newEndDate) => {
    setEndDate(newEndDate);
    if (
      moment(startDate).isAfter(newEndDate) ||
      moment(newEndDate).isBefore(startDate)
    ) {
      setSeverity("error");
      setText(i18n.t("notifications.checkDates"));
      setAutoClose(false);
    } else {
      setAutoClose(true);
      const { ms, d } = calculateDurationInMs(startDate, newEndDate);
      const hoursMins = convertMsToHoursMinutes(ms, d);
      setTotalDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
      setValidDuration(true);
    }
  };

  const onChangeIsForNow = (newIsForNow) => {
    setIsForNow(newIsForNow);
    if (newIsForNow) {
      setEndDate("");
      setValidDuration(true);
    } else {
      updateDatesIsForNow(false);
    }
  };

  for (let i = 0; i < trackName.length; i++) {
    trackFilteredData.push(
      trackLayerData.features.filter(function (k) {
        if (k.properties.siteId === siteId) {
          return k.properties.trackName === trackName[i].toString();
        }
      })
    );
  }
  for (let i = 0; i < trackFilteredData.length; i++) {
    locationTracks.push(trackFilteredData[i][0].properties.uuid);
  }

  for (let i = 0; i < pointName.length; i++) {
    pointFilteredData.push(
      pointLayerData.features.filter(function (k) {
        if (k.properties.siteId === siteId) {
          return k.properties.pointName === pointName[i].toString();
        }
      })
    );
  }
  for (let i = 0; i < pointFilteredData.length; i++) {
    locationPoints.push(pointFilteredData[i][0].properties.uuid);
  }

  const handleClose = () => {
    if (openview === "eventInfoCreateFull") {
      props.showView("events");
    }
    if (openview === "eventInfoCreateHalf") {
      props.showView("halfevents");
    }
  };

  const handleArrivalChange = (trains) => {
    setArrivalTrain(trains);
    const ids = trains.map((o) => {
      return o.trainId;
    });
    setArrivalsIds(ids);
  };

  const handleDepartureChange = (trains) => {
    setDepartureTrain(trains);
    const ids = trains.map((o) => {
      return o.trainId;
    });
    setDeparturesIds(ids);
  };

  const cleanCustomVals = (customVals) => {
    for (var idx in customVals) {
      if (customVals[idx] === undefined) {
        delete customVals[idx];
      } else if (Array.isArray(customVals[idx].value)) {
        customVals[idx].value = customVals[idx].value
          .toString()
          .replaceAll(", ", ",");
      } else if (typeof customVals[idx].value == "boolean") {
        customVals[idx].value = customVals[idx].value.toString();
      }
    }
    return customVals.filter((n) => n);
  };

  const check = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName].length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleSaving = () => {
    const eventObj = {
      eventState: eventState,
      duration: totalDuration.apiFormat,
      locationTracks,
      startTime: formatDt(startDate),
      isForNow: isForNow,
      criticality: criticality,
      shortDescription: shortDesc,
      description: specify,
      arrivals: arrivalsIds,
      departures: departuresIds,
      locationPoints: locationPoints,
      // PRX-166
      // phases: phases,
      phases: [],
      customValues: cleanCustomVals(customValues),
      endTime: formatDt(endDate),
      restriction: restriction,
      eventType: eventPicker,
    };

    const request = postEvents(siteId, check(eventObj));

    setDisabled(true);

    request
      .then((response) => {
        if (messageList.length > 0) {
          for (let message of messageList) {
            if (message !== null) {
              const request2 = postEventMessage(
                siteId,
                response.data.uuid,
                check({ comment: message.msg })
              );
              request2
                .then((response2) => {
                  let comments = [response2.data];
                  const eventComment = {
                    comments: comments,
                    newComments: 0,
                  };
                  dispatch(
                    updateEventComments(response.data.uuid, eventComment)
                  );
                  setSeverity("success");
                  setText(i18n.t("notifications.savedSuccessfully"));
                })
                .catch((error) => {
                  setDisabled(false);
                  setSeverity("error");
                  if (error.response.status === 403) {
                    setText(i18n.t("notifications.savedFailedRights"));
                  } else if (error.response.status === 400) {
                    setText(
                      i18n.t("notifications.savedFailedContent") +
                        i18n.t("notifications." + error.response.data[0].name)
                    );
                  } else {
                    setText(i18n.t("notifications.savedFailed"));
                  }
                  console.log("Erro error" + error);
                });
            }
          }
          if (severity === "success") {
            resetForm();
            handleClose();
          }
        } else {
          setSeverity("success");
          setText(i18n.t("notifications.savedSuccessfully"));
          resetForm();
          handleClose();
        }
      })
      .catch((error) => {
        setSeverity("error");
        setDisabled(false);
        if (error.response.status === 403) {
          setText(i18n.t("notifications.savedFailedRights"));
        } else if (error.response.status === 400) {
          setText(
            i18n.t("notifications.savedFailedContent") +
              i18n.t("notifications." + error.response.data[0].name)
          );
        } else {
          setText(i18n.t("notifications.savedFailed"));
        }
        console.log("Erro error" + error);
      });
  };

  const handleCommentSave = () => {
    if (message !== null && message.length > 0) {
      let msgs = [...messageList];
      msgs.push({ date: new Date(), msg: message });
      setMessageList(msgs);
      setMessage("");
    }
  };

  return (
    <div id="create-event-main" className={classes.mainContainer}>
      <EventHeader
        title={i18n.t("navigation.eventInfoCreate.text")}
        handleCloseClick={handleClose}
      />
      <div id="create-event-container" className={classes.eventContainer}>
        {/* <div id="create-event-column" className={classes.column}> */}
        <Box
          id="create-event-column"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
            maxHeight: {
              xs: `calc(${height}px - 56px)`,
              md: `${height}px`,
            },
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div id="event-status-history-row" className={classes.row}>
            <Notification
              severity={severity}
              text={text}
              closable
              setSeverity={setSeverity}
              autoClose={autoClose}
            />
            {/* PRX-166  {showWarning && (
              <Notification
                severity="warning"
                text={i18n.t("notifications.durationWarning")}
                closable={false}
                setSeverity={setSeverity}
              />
            )} */}
            <EventState
              setEventState={setEventState}
              message={message}
              setMessage={setMessage}
              handleCommentSave={handleCommentSave}
              messageList={messageList}
              showHistoryAndMsg={
                roles[siteId] !== undefined &&
                showEventHistoryAndMessages(roles[siteId])
              }
            />
          </div>
          <div id="event-date-row" className={classes.rowNoBorders}>
            <DateTimePicker
              startDate={startDate}
              endDate={endDate}
              isForNow={isForNow}
              handleChangeStart={onChangeStart}
              handleChangeEnd={onChangeEnd}
              handleIsForNow={onChangeIsForNow}
              totalDuration={totalDuration}
              onDurationChange={setTotalDuration}
              validDuration={validDuration}
              setValidDuration={setValidDuration}
            />
          </div>
          <div
            id="event-picker-description-severity-row"
            className={classes.row}
          >
            <EventPicker
              eventPicker={eventPicker}
              handleChange={setEventPicker}
            />
            <EventDescription
              specify={specify}
              shortDesc={shortDesc}
              handleSpecify={setSpecify}
              handleShortDesc={setShortDesc}
            />
            <SeverityPicker
              criticality={criticality}
              restriction={restriction}
              changeCriticality={setCriticality}
              changeRestriction={setRestriction}
            />
          </div>
          <div id="event-target-row" className={classes.row}>
            <EventTarget
              trackName={trackName}
              pointName={pointName}
              arrivalTrain={arrivalTrain}
              departureTrain={departureTrain}
              handleTrackChange={setTrackName}
              handlePointChange={setPointName}
              changeArrivalTrain={handleArrivalChange}
              changeDepartureTrain={handleDepartureChange}
            />
          </div>

          {orgFields.length > 0 && (
            <div
              id="create-event-dynamicfields"
              className={classes.rowLocationBased}
            >
              <EventOrgFields
                orgFields={orgFields}
                customValues={customValues}
                setCustomValues={setCustomValues}
                resetCustomValuesFlag={resetCustomValuesFlag}
              />
            </div>
          )}
          <div id="create-event-button-row" className={classes.buttonRow}>
            <div className={classes.buttonContainer}>
              <Tooltip title={saveToolTip}>
                <span>
                  <Button
                    id="save-event-btn"
                    className={classes.saveButton}
                    style={{ width: "100px" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={handleSaving}
                    disabled={disabled}
                  >
                    {i18n.t("navigation.eventInfoButtons.saveEvent")}
                  </Button>
                </span>
              </Tooltip>

              <Button
                id="empty-event-btn"
                className={classes.removeButton}
                style={{ width: "100px" }}
                variant="contained"
                color="primary"
                component="span"
                onClick={resetForm}
              >
                {i18n.t("navigation.eventInfoButtons.emptyEvent")}
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default EventInfoCreate;
