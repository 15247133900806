import * as React from "react";
import { useSelector } from "react-redux";
import { Source, Layer } from "react-map-gl";
import { useEffect } from "react";
import { useState } from "react";

// put wide transparent lines under actual tracks
// this helps users to select a track
const hitBufferStyle = {
  id: "isForNowStopLayer-hitbuffer",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "transparent",
    "line-width": 15,
  },
};

const lineStyle = {
  id: "isForNowStopLayer-lines",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "rgba(199, 63, 0, 0.3)",
    "line-width": 3,
  },
};

const labelStyle = {
  id: "isForNowStopLayer-labels",
  type: "symbol",
  minzoom: 12,
  filter: ["==", "$type", "LineString"],
  layout: {
    "symbol-placement": "line",
    "text-font": ["Noto Sans Bold"],
    "text-field": "{trackName}",
    "text-size": ["interpolate", ["linear"], ["zoom"], 12, 11, 20, 15],
  },
  //PRX-101 show only black text without halo
  //Note, changed halo from blue / 2.75 to white / 2. this change is commented on JIRA
  paint: {
    "text-color": "#000000", //"#ffffff",
    "text-halo-color": "#ffffff", //"#0000ff",
    "text-halo-width": 2, //2.75, // max 1/4 of font size
  },
};

const IsForNowStop = () => {
  const trackLayerData = useSelector((s) => s.map.trackLayerData);
  const isForNowStop = useSelector((s) => s.map.isForNowStop);
  const finalData = [];
  const features = [];
  const featuresFilter = [];
  var featuresFinal = [];
  const [stop, setStop] = useState();
  useEffect(() => {
    if (isForNowStop !== undefined) {
      for (let i = 0; i < isForNowStop[0].length; i++) {
        for (let t = 0; t < isForNowStop[0][i].locationTracks.length; t++) {
          features.push(
            trackLayerData.features.filter(function (k) {
              return (
                k.properties.siteId === isForNowStop[0][i].siteId &&
                k.properties.trackName ===
                  isForNowStop[0][i].locationTracks[t].trackName
              );
            })
          );
        }
      }
      for (let i = 0; i < features.length; i++) {
        if (features[i].length !== 0) {
          featuresFilter.push(features[i][0]);
        }
      }
      featuresFinal = [...new Set(featuresFilter)];
      finalData.push({
        crs: trackLayerData.crs,
        features: featuresFinal,
        name: trackLayerData.name,
        type: trackLayerData.type,
      });
      setStop(finalData[0]);
    }
  }, [isForNowStop]);

  if (stop !== undefined) {
    return (
      <Source id="isForNowStopLayer" type="geojson" data={stop}>
        <Layer {...hitBufferStyle} />
        <Layer {...lineStyle} />
        <Layer {...labelStyle} />
      </Source>
    );
  } else {
    return <div />;
  }
};

export default IsForNowStop;
