import React from "react";
import { Flex } from "../../Layout";
import TrainEvent from "./_components/TrainEvent";
import TrackEvent from "./_components/TrackEvent";
import "./index.css";
import { COLUMN_WIDTH } from "../../../../constants";
import {
  monthInfo,
  firstDayOfScheduler,
} from "../../../../utils/schedulerUtils";
import DayColumns from "./_components/DayColumns";
import useDeepCompareEffect from "../../../../utils/useDeepCompareEffect";
import { useSelector } from "react-redux";
import moment from "moment";

// Group events by the track they belong to
// Filter events that fall into the current, previous and next month only
function mapEventsToTracks(events) {
  const tracks = {};
  events.forEach((event) => {
    event.locationTracks.forEach((track) => {
      if (event.siteId === track.siteId) {
        const id = `${track.siteId}_${track.trackName}`;
        if (tracks[id] === undefined) {
          tracks[id] = [event];
        } else {
          tracks[id].push(event);
        }
      }
    });
  });
  return tracks;
}

export default function DaySchedule({
  events,
  sideBarRef,
  onScroll,
  schedulerRef,
  myTracks,
  openview,
  showViewAction,
}) {
  const currentDate = new Date();
  const { prevThree, prevTwo, prev, current, next, nextTwo, nextThree } =
    monthInfo(currentDate);
  const trackEventWidth =
    (prevThree.days +
      prevTwo.days +
      prev.days +
      current.days +
      next.days +
      nextTwo.days +
      nextThree.days) *
    (COLUMN_WIDTH - 1);
  let tracks = React.useRef({});
  const [left, setLeft] = React.useState();
  const [clickDate, setClickDate] = React.useState();
  const [hoverDate, setHoverDate] = React.useState();
  const trainArrivals = useSelector(
    (state) => state.userinterface.trainArrivals
  );
  const trainDepartures = useSelector(
    (state) => state.userinterface.trainDepartures
  );
  useDeepCompareEffect(() => {
    tracks.current = mapEventsToTracks(events);
    // setTrackNames(Object.keys(tracks.current));
  }, [events]);

  const handleOnScroll = () => {
    onScroll();
    setLeft(schedulerRef.current.scrollLeft);
  };

  const handleOnMouseDown = (event) => {
    const offset =
      openview === "events" || openview === "halfevents"
        ? event.clientX + schedulerRef.current.scrollLeft - 110
        : event.clientX + schedulerRef.current.scrollLeft - 550;
    const dayOffset = offset / (COLUMN_WIDTH - 1);
    const hourOffset = (dayOffset % 1) / 0.0416666666666667;
    const minuteOffset = (hourOffset % 1) / 0.0166666666666667;
    let first = firstDayOfScheduler();
    first.setDate(first.getDate() + dayOffset);
    first.setHours(hourOffset);
    first.setMinutes(minuteOffset);
    setClickDate(moment(first));
  };

  // eslint-disable-next-line no-unused-vars
  const handleOnMouseOver = (event) => {
    const offset =
      openview === "events" || openview === "halfevents"
        ? event.clientX + schedulerRef.current.scrollLeft - 110
        : event.clientX + schedulerRef.current.scrollLeft - 550;
    const dayOffset = offset / (COLUMN_WIDTH - 1);
    const hourOffset = (dayOffset % 1) / 0.0416666666666667;
    const minuteOffset = (hourOffset % 1) / 0.0166666666666667;
    let first = firstDayOfScheduler();
    first.setDate(first.getDate() + dayOffset);
    first.setHours(hourOffset);
    first.setMinutes(minuteOffset);
    setHoverDate(moment(first));
  };

  return (
    <Flex
      ref={schedulerRef}
      onScroll={handleOnScroll}
      onMouseDown={handleOnMouseDown}
      // onMouseOver={handleOnMouseOver}
      align="flex-start"
      className="calendar"
      id="calendar"
      style={{ height: "100%" }}
    >
      <Flex align="flex-start" className="cont">
        <Flex
          direction="column"
          id="train-events"
          className="train-events"
          style={{ width: "100%" }}
        >
          <TrainEvent
            id="train-arrivals"
            schedules={trainArrivals}
            type="arrival"
            left={left}
          />
          <TrainEvent
            id="train-departures"
            schedules={trainDepartures}
            type="departure"
            left={left}
          />
        </Flex>
        <Flex className="divider" style={{ left }} />
        <DayColumns
          style={{ flex: 1, marginTop: "5px" }}
          monthInfo={{
            prevThree,
            prevTwo,
            prev,
            current,
            next,
            nextTwo,
            nextThree,
          }}
          myTracks={myTracks}
        />
        <Flex
          self="stretch"
          id="track-events"
          className="track-events"
          style={{ width: trackEventWidth }}
        >
          {myTracks &&
            myTracks.map((track) => {
              const id = `${track.siteId}_${track.trackName}`;
              return (
                <TrackEvent
                  key={id}
                  sideBarRef={sideBarRef}
                  schedulerRef={schedulerRef}
                  showViewAction={showViewAction}
                  myLeft={left}
                  clickDate={clickDate}
                  hoverDate={hoverDate}
                  trackName={track.trackName}
                  openview={openview}
                  onHover={handleOnMouseOver}
                  events={tracks.current[id]}
                />
              );
            })}
        </Flex>
      </Flex>
    </Flex>
  );
}
