import React from "react";
import { Flex } from "../../../../Layout";
import Column from "./_components/Column";

export default function DayColumns({
  monthInfo,
  dayColumnRef,
  onScroll,
  myTracks,
  ...rest
}) {
  const currentDate = new Date();

  const Columns = React.useCallback(() => {
    let months = [
      monthInfo.prevThree,
      monthInfo.prevTwo,
      monthInfo.prev,
      monthInfo.current,
      monthInfo.next,
      monthInfo.nextTwo,
      monthInfo.nextThree,
    ];
    let columns = [];

    for (let i = 0; i < 7; i++) {
      let month = months[i];
      let day = month.dod;

      // Convert sunday to proper int for localization
      if (day === 0) {
        day = 7;
      }

      for (let k = 1; k <= month.days; k++) {
        if (day > 7) {
          day = 1;
        }

        // For scroll to today functionality
        // Ensure that it's the current month and current date
        columns.push(
          <Column
            key={`${k}_${month.num}`}
            day={day}
            date={k}
            month={month.num + 1}
            myTracks={myTracks}
          />
        );

        day++;
      }
    }

    return columns;
  }, [currentDate]);

  return (
    <Flex
      ref={dayColumnRef}
      onScroll={onScroll}
      direction="row"
      className="day-columns-cont"
      {...rest}
    >
      <Columns />
    </Flex>
  );
}
