const MqttConfig = {
  url: process.env.REACT_APP_MQTT_URL,
  options: {
    clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
    keepalive: 30,
    protocolId: "MQTT",
    protocolVersion: 4,
    useSSL: true,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    will: {
      topic: "WillMsg",
      payload: "Connection Closed abnormally..!",
      qos: 0,
      retain: false,
    },
    rejectUnauthorized: false,
    checkServerIdentity: () => {
      return null;
    },
  },
};

export default MqttConfig;
