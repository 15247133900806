import axiosEvent from "../utils/axiosEvent";

const API_URL = "/site/";
const TRAIN = "/trains";

export const getTrains = async (siteId, lastFetch) => {
  // TODO: If/when start and end date params are wanted, add startDate and endDate to function call params
  // const url = `${API_URL}${siteId}${TRAIN}?startTime=${startDate}&endTime=${endDate}`;
  const url = lastFetch
    ? `${API_URL}${siteId}${TRAIN}?lastFetch=${lastFetch}`
    : `${API_URL}${siteId}${TRAIN}`;
  return await axiosEvent.get(url);
};

export const getTrainById = async (trainId, siteId) => {
  const url = `${API_URL}${siteId}${TRAIN}${trainId}`;
  return await axiosEvent.get(url);
};
