/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import i18n from "i18next";
import { styled } from "@mui/material/styles";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ProxionBackground from "../../../assets/proxion_background.svg";
import ProxionLogo from "../../../assets/prox_x.svg";
import { Formik, Field, Form } from "formik";
import { authenticationService } from "../_services";
import { Link } from "react-router-dom";

const Logo = styled("img")(() => ({
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "1rem",
  width: "100%",
  maxHeight: "20vh",
  height: "auto",
}));

const FormikField = styled(Field)(({ theme }) => ({
  display: "block",
  width: "calc(100% - 1rem)",
  height: "calc(1em + 0.5rem + 2px)",
  padding: "0.25rem 0.5rem",
  fontFamily: "inherit",
  fontSize: { sm: "1rem", xs: "0.875rem" },
  fontWeight: "400",
  lineHeight: 1.5,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.proxBorderGrey}`,
  borderRadius: "0.25rem",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
}));

const FormGroup = styled("div")(() => ({
  marginBottom: "1rem",
}));

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${ProxionBackground})`,
        backgroundSize: "100% 100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: { md: "32rem", sm: "24rem", xs: "16rem" },
          fontSize: { sm: "1rem", xs: "0.875rem" },
        }}
      >
        <Logo alt="Logo" src={ProxionLogo} />
        <Formik
          initialValues={{
            username: email,
          }}
          onSubmit={(
            // These are somehow needed so cant remove
            // eslint-disable-next-line no-unused-vars
            { username, password },
            { setStatus, setSubmitting }
          ) => {
            setStatus();
            authenticationService.forgotPassword(email).then(
              () => {
                const { from } = props.history.state || {
                  from: { pathname: "/login" },
                };
                props.history.push(from);
              },
              (error) => {
                setSubmitting(false);
                setStatus(error);
              }
            );
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormGroup>
                <Typography>
                  {i18n.t("loginPage.sendResetPasswordLabel")}
                </Typography>
                <FormikField
                  id="username"
                  name="username"
                  value={email}
                  type="text"
                  className={
                    "form-control" +
                    (errors.username && touched.username ? " is-invalid" : "")
                  }
                  onChange={onEmailChange}
                />
              </FormGroup>
              <FormGroup id="button" align="right">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    fontFamily: "inherit",
                    fontWeight: 600,
                    borderRadius: "1rem",
                    backgroundColor: (theme) => theme.palette.common.proxBlue,
                    color: (theme) => theme.palette.common.white,
                    padding: "0.325rem 1rem",
                    fontSize: "0.75rem",
                  }}
                  disabled={isSubmitting}
                >
                  {i18n.t("loginPage.sendResetPasswordBtn")}
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size="1.96rem"
                    sx={{
                      position: "absolute",
                      left: 0,
                    }}
                  />
                )}
              </FormGroup>
              <Link id="go-back-link" to="/login">
                {i18n.t("loginPage.goBack.text")}
              </Link>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
