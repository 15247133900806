import * as React from "react";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Card,
  CardContent,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../../store/actions";
import { minutesToHoursAndMinutes } from "../../../../../../utils/times";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "content",
    marginTop: "-20px",
    backgroundColor: theme.palette.common.white,
  },
  panelSummary: {
    marginRight: "0px",
    backgroundColor: theme.palette.common.white,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.white,
      minHeight: "42px !important",
      maxHeight: "42px !important",
    },
  },
  column: {
    display: "table",
    float: "left",
    marginBottom: "-12px",
  },
  accordionDiv: {
    width: "380px",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "20px",
  },
  sendButton: {
    color: "white",
  },
  iconButtonPosition: {
    float: "right",
    alignItems: "center",
    margin: "0 !important",
  },
  iconButtonBackground: {
    backgroundColor: theme.palette.common.proxBlue,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    width: "32px",
    height: "32px",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  inputRootText: {
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    height: "40px",
    marginRight: "4px !important",
    marginLeft: "8px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  cardItem: {
    width: "406px",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    border: "none !important",
    boxShadow: "none !important",
    marginLeft: "-16px",
    marginBottom: "-16px",
  },
  title: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: theme.palette.common.black,
  },
  smalltitle: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: theme.palette.common.black,
  },
  text: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: theme.palette.common.black,
  },
}));

const chat = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 24,
        width: 50,
        height: 25,
        marginLeft: "-15px",
        marginTop: "-4px",
        marginBottom: "-8px",
      }}
    >
      {i18n.t("navigation.eventInfoIcons.chat")}
    </Icon>
  </Box>
);

const ChangeHistory = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eventId = useSelector((state) => state.userinterface.eventId);
  const eventComments = useSelector(
    (state) => state.userinterface.eventComments
  );

  const onMessageChange = (event) => {
    props.setMessage(event.target.value);
  };

  const onClicked = () => {
    props.handleCommentSave();
  };

  const resetNewMsgCount = (event, expanded) => {
    if (expanded) {
      const copyEventComments = { ...eventComments };
      const eventComment = copyEventComments[eventId];
      if (eventComment?.newComments > 0) {
        eventComment["newComments"] = 0;
        eventComment["comments"] = props.pastMessages;
        dispatch(actions.updateEventComments(eventId, eventComment));
      }
    }
  };

  React.useEffect(() => {
    let combinedArr = [];
    for (let idx in props.pastMessages) {
      let msg = props.pastMessages[idx];
      let d = new Date(msg.createdDate);
      combinedArr.push({
        time: moment(d),
        value: makeChatItem(d, msg.comment),
      });
    }

    combinedArr = combinedArr.concat(makeDiff());
    // Sort items by createdDate / modifiedDate time
    combinedArr
      .sort(function (a, b) {
        return new Date(a.time._d) - new Date(b.time._d);
      })
      .reverse();

    if (props.history.length > 0) {
      let first = props.history[0];
      let d = new Date(first.createdDate);
      let id = first.uuid;
      combinedArr.push({
        time: moment(d),
        value: makeFirstItem(d, id),
      });
    }

    props.setPastMessagesArray(combinedArr);
  }, [props.pastMessages, props.history]);

  const compareValues = (prev, current) => {
    let arr = [];
    if (prev !== current) {
      arr.push(prev);
      arr.push(current);
    }

    return arr;
  };

  const compareArrayValues = (prev, current, fields) => {
    let arr = [];
    let uuidP = [];
    let uuidC = [];
    let uuidFieldIdMap = [];
    if (prev !== undefined) {
      for (let objectIdx in prev) {
        if (prev[objectIdx] !== undefined) {
          let uuid;
          let fieldId;
          for (let field of Object.keys(prev[objectIdx])) {
            if (field === "uuid") {
              uuid = prev[objectIdx][field];
              uuidP.push(prev[objectIdx][field]);
            }
            if (field === "fieldId") {
              fieldId = prev[objectIdx][field];
            }
          }
          if (uuid && fieldId) {
            uuidFieldIdMap.push({ uuid: uuid, fieldId: fieldId });
          }
        }
      }
    }
    if (current !== undefined) {
      let uuid;
      let fieldId;
      for (let objectIdx in current) {
        if (current[objectIdx] !== undefined) {
          for (let field of Object.keys(current[objectIdx])) {
            if (field === "uuid") {
              uuid = current[objectIdx][field];
              uuidC.push(current[objectIdx][field]);
            }
            if (field === "fieldId") {
              fieldId = current[objectIdx][field];
            }
          }
          if (uuid && fieldId) {
            uuidFieldIdMap.push({ uuid: uuid, fieldId: fieldId });
          }
        }
      }
    }

    if (uuidP.length === 0 && uuidC.length > 0) {
      for (let id of uuidC) {
        let event = current.filter((obj) => {
          return obj.uuid === id;
        })[0];
        for (let field of fields) {
          const val = uuidFieldIdMap.filter((obj) => {
            return obj.uuid === id;
          });
          let fieldId = val[0] ? val[0].fieldId : undefined;
          if (fieldId === undefined) {
            arr.push([id, "", event[field], field]);
          } else {
            arr.push([id, "", event[field], field, fieldId]);
          }
        }
      }
    } else if (uuidP.length > 0 && uuidC.length === 0) {
      for (let id of uuidP) {
        let event = prev.filter((obj) => {
          return obj.uuid === id;
        })[0];
        for (let field of fields) {
          const val = uuidFieldIdMap.filter((obj) => {
            return obj.uuid === id;
          });
          let fieldId = val[0] ? val[0].fieldId : undefined;
          if (fieldId === undefined) {
            arr.push([id, event[field], "", field]);
          } else {
            arr.push([id, event[field], "", field, fieldId]);
          }
        }
      }
    } else {
      // Both prev and current populated
      for (let id of uuidP) {
        let eventP = prev.filter((obj) => {
          return obj.uuid === id;
        })[0];
        let eventC = current.filter((obj) => {
          return obj.uuid === id;
        })[0];
        if (eventC !== undefined) {
          for (let field of fields) {
            if (fields.length === 1 || eventP[field] !== eventC[field]) {
              // No duplicate rows wanted for phases
              const val = uuidFieldIdMap.filter((obj) => {
                return obj.uuid === id;
              });
              let fieldId = val[0] ? val[0].fieldId : undefined;
              if (fieldId === undefined) {
                arr.push([id, eventP[field], eventC[field], field]);
              } else if (eventP[field] !== eventC[field]) {
                arr.push([id, eventP[field], eventC[field], field, fieldId]);
              }
            }
          }
        } else {
          for (let field of fields) {
            const val = uuidFieldIdMap.filter((obj) => {
              return obj.uuid === id;
            });
            let fieldId = val[0] ? val[0].fieldId : undefined;
            if (fieldId === undefined) {
              arr.push([id, eventP[field], "", field]);
            } else {
              arr.push([id, eventP[field], "", field, fieldId]);
            }
          }
        }
      }
      for (let id of uuidC) {
        let eventC = current.filter((obj) => {
          return obj.uuid === id;
        })[0];
        let eventP = prev.filter((obj) => {
          return obj.uuid === id;
        })[0];
        // Same IDs already handled in previous loop
        if (eventP === undefined) {
          for (let field of fields) {
            const val = uuidFieldIdMap.filter((obj) => {
              return obj.uuid === id;
            });
            let fieldId = val[0] ? val[0].fieldId : undefined;
            if (fieldId === undefined) {
              arr.push([id, "", eventC[field], field]);
            } else {
              arr.push([id, "", eventC[field], field, fieldId]);
            }
          }
        }
      }
    }
    return arr;
  };

  const translateValues = (fieldName, values) => {
    if (
      fieldName.toLowerCase().includes("description") ||
      fieldName.toLowerCase().includes("restriction")
    ) {
      return values;
    }
    if (fieldName.toLowerCase().includes("time")) {
      if (
        values[0] !== null &&
        values[0] !== undefined &&
        values[0].length > 0
      ) {
        let d = new Date(values[0]);
        values[0] = d.toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        });
      }
      if (
        values[1] !== null &&
        values[1] !== undefined &&
        values[1].length > 0
      ) {
        let d = new Date(values[1]);
        values[1] = d.toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        });
      }
      return values;
    }
    if (values[0] !== undefined && values[0].length > 0) {
      values[0] = i18n.t("navigation.eventInfoHistory." + values[0]);
    }
    if (values[1] !== undefined && values[1].length > 0) {
      values[1] = i18n.t("navigation.eventInfoHistory." + values[1]);
    }
    return values;
  };

  const translateArrayValues = (values) => {
    if (values[3] !== undefined && values[3].length > 0) {
      if (values[3].toLowerCase().includes("name")) {
        values[3] = "name";
      }
      if (values[3] === "phaseState") {
        if (values[1] !== undefined && values[1].length > 0) {
          values[1] = i18n.t("navigation.eventInfoHistory." + values[1]);
        }
        if (values[2] !== undefined && values[2].length > 0) {
          values[2] = i18n.t("navigation.eventInfoHistory." + values[2]);
        }
      }
      if (values[3] === "duration") {
        if (values[1] !== undefined && values[1] > 0) {
          values[1] = minutesToHoursAndMinutes(values[1]);
        }
        if (values[2] !== undefined && values[2] > 0) {
          values[2] = minutesToHoursAndMinutes(values[2]);
        }
      }
    }
    return values;
  };

  const makeItem = (fieldName, date, diff) => {
    diff = translateValues(fieldName, diff);
    return (
      <div>
        <Card key={fieldName + date + diff} className={classes.cardItem}>
          <CardContent>
            <Typography component={"div"} className={classes.title}>
              {date.toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </Typography>
            <Typography component={"div"} className={classes.smalltitle}>
              {i18n.t("navigation.eventInfoHistory." + fieldName) + ":"}
            </Typography>
            <Typography component={"div"} className={classes.text}>
              {diff[0]} {" -> "} {diff[1]}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  const makeArrayItem = (fieldName, date, diff) => {
    for (let i in diff) {
      diff[i] = translateArrayValues(diff[i]);
    }
    return (
      <div>
        <Card key={fieldName + date + diff} className={classes.cardItem}>
          <CardContent>
            <Typography component={"div"} className={classes.title}>
              {date.toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </Typography>
            <Typography component={"div"} className={classes.title}>
              {i18n.t("navigation.eventInfoHistory." + fieldName) + ":"}
            </Typography>
            {diff.map((item) => (
              <div key={fieldName + item[0] + item[1] + item[2] + item[3]}>
                <Typography component={"div"} className={classes.smalltitle}>
                  {getSubItemName(fieldName, item)}
                </Typography>
                <Typography component={"div"} className={classes.text}>
                  {item[1]} {" -> "}
                  {item[2]}
                </Typography>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  };

  const makeChatItem = (date, value) => {
    return (
      <Card key={date + value} className={classes.cardItem}>
        <CardContent>
          <Typography component={"div"} className={classes.title}>
            {date.toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </Typography>
          <Typography component={"div"} className={classes.smalltitle}>
            {i18n.t("navigation.eventInfoHistory.message") + ": "}
          </Typography>
          <Typography component={"div"} className={classes.text}>
            {value}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const makeFirstItem = (date, id) => {
    return (
      <Card key={date + id} className={classes.cardItem}>
        <CardContent>
          <Typography component={"div"} className={classes.title}>
            {date.toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </Typography>
          <Typography component={"div"} className={classes.text}>
            {i18n.t("navigation.eventInfoHistory.created")}
            {date.toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </Typography>
          <Typography component={"div"} className={classes.text}>
            {"UUID: " + id}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const makeInnerItem = (fieldName, diff) => {
    diff = translateValues(fieldName, diff);
    return (
      <div key={fieldName + diff}>
        <Typography component={"div"} className={classes.smalltitle}>
          {i18n.t("navigation.eventInfoHistory." + fieldName) + ":"}
        </Typography>
        <Typography component={"div"} className={classes.text}>
          {diff[0]} {" -> "}
          {diff[1]}
        </Typography>
      </div>
    );
  };

  const getSubItemName = (fieldName, item) => {
    if (fieldName === "customValues") {
      let orgField = props.orgFields.filter(function (k) {
        return k.uuid === item[4];
      });
      return orgField[0].label;
    } else {
      return i18n.t("navigation.eventInfoHistory." + item[3]) + ":";
    }
  };

  const makeInnerArrayItem = (fieldName, itemNames, diff) => {
    for (let i in diff) {
      diff[i] = translateArrayValues(diff[i]);
    }
    return (
      <div key={fieldName + itemNames + diff}>
        <Typography component={"div"} className={classes.title}>
          {i18n.t("navigation.eventInfoHistory." + fieldName) + ":"}
        </Typography>
        {diff.map((item) => (
          <div key={fieldName + item[0] + item[1] + item[2] + item[3]}>
            <Typography component={"div"} className={classes.text}>
              {getSubItemName(fieldName, item)}
            </Typography>
            <Typography component={"div"} className={classes.text}>
              {item[1]} {" -> "} {item[2]}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const checkArrayFields = (difference, current, prev, fieldName) => {
    if (difference.includes(fieldName)) {
      let fieldC = current[fieldName];
      let fieldP = prev[fieldName];
      let sortedC = [];
      let sortedP = [];
      for (let phaseC of fieldC) {
        for (let field in phaseC) {
          sortedC.push([field, phaseC[field]]);
        }
      }
      for (let phaseP of fieldP) {
        for (let field in phaseP) {
          sortedP.push([field, phaseP[field]]);
        }
      }
      if (sortedC.sort().join(",") === sortedP.sort().join(",")) {
        const index = difference.indexOf(fieldName);
        difference.splice(index, 1);
      }
    }
  };

  const compareItem = (fieldName, difference, prev, current, array) => {
    if (
      difference.includes(fieldName) &&
      prev[fieldName] !== undefined &&
      current[fieldName] !== undefined
    ) {
      const values = compareValues(prev[fieldName], current[fieldName]);
      let d = new Date(current.modifiedDate);
      array.push({
        time: moment(d),
        value: makeItem(fieldName, d, values),
      });
    }
  };

  const condenseMultiRowArrayItem = (values) => {
    let id;
    let pre = "";
    let cur = "";
    let field;
    for (let val of values) {
      id = val[0];
      pre = pre + val[1] + " ";
      cur = cur + val[2] + " ";
      field = val[3];
    }
    return [[id, pre, cur, field]];
  };

  const compareArrayItem = (fieldName, difference, prev, current, array) => {
    if (
      difference.includes(fieldName) &&
      prev[fieldName] !== undefined &&
      current[fieldName] !== undefined
    ) {
      if (fieldName === "locationTracks") {
        // trackName
        let values = compareArrayValues(prev[fieldName], current[fieldName], [
          "trackName",
        ]);
        values = condenseMultiRowArrayItem(values);
        if (values.length > 0) {
          let d = new Date(current.modifiedDate);
          array.push({
            time: moment(d),
            value: makeArrayItem(fieldName, d, values),
          });
        }
      } else if (fieldName === "locationPoints") {
        // pointName
        let values = compareArrayValues(prev[fieldName], current[fieldName], [
          "pointName",
        ]);
        values = condenseMultiRowArrayItem(values);
        if (values.length > 0) {
          let d = new Date(current.modifiedDate);
          array.push({
            time: moment(d),
            value: makeArrayItem(fieldName, d, values),
          });
        }
      } else if (fieldName === "customValues") {
        // value
        const values = compareArrayValues(prev[fieldName], current[fieldName], [
          "value",
        ]);
        if (values.length > 0) {
          let d = new Date(current.modifiedDate);
          array.push({
            time: moment(d),
            value: makeArrayItem(fieldName, d, values),
          });
        }
      }
      // else if (fieldName === "phases") {
      //   // name
      //   // phaseState
      //   const values = compareArrayValues(prev[fieldName], current[fieldName], [
      //     "name",
      //     "phaseState",
      //     "duration",
      //   ]);
      //   if (values.length > 0) {
      //     let d = new Date(current.modifiedDate);
      //     array.push({
      //       time: moment(d),
      //       value: makeArrayItem(fieldName, d, values),
      //     });
      //   }
      // }
      else {
        // trainNumber
        let values = compareArrayValues(prev[fieldName], current[fieldName], [
          "trainNumber",
        ]);
        values = condenseMultiRowArrayItem(values);
        if (values.length > 0) {
          let d = new Date(current.modifiedDate);
          array.push({
            time: moment(d),
            value: makeArrayItem(fieldName, d, values),
          });
        }
      }
    }
  };

  const makeDiff = () => {
    let combinedArr = [];
    for (let index in props.history) {
      const current = props.history[index];
      const prevIndex = index - 1;
      if (prevIndex !== -1) {
        const prev = props.history[prevIndex];
        // Compare by object keys what is changed. Result is array which have changed fields excluding modifiedDate and modifiedUser
        const difference = Object.keys(current).filter(
          (k) =>
            k !== "modifiedDate" &&
            k !== "modifiedUser" &&
            k !== "phases" &&
            current[k] !== prev[k] &&
            prev[k] !== null &&
            current[k] !== ""
        );

        // Try to filter out identical array fields
        checkArrayFields(difference, current, prev, "locationTracks");
        checkArrayFields(difference, current, prev, "locationPoints");
        checkArrayFields(difference, current, prev, "arrivals");
        checkArrayFields(difference, current, prev, "departures");
        checkArrayFields(difference, current, prev, "customValues");
        // checkArrayFields(difference, current, prev, "phases");

        if (difference.length === 1) {
          compareArrayItem(
            "locationTracks",
            difference,
            prev,
            current,
            combinedArr
          );
          compareItem("startTime", difference, prev, current, combinedArr);
          compareItem("isForNow", difference, prev, current, combinedArr);
          compareItem("criticality", difference, prev, current, combinedArr);
          compareItem(
            "shortDescription",
            difference,
            prev,
            current,
            combinedArr
          );
          compareItem("description", difference, prev, current, combinedArr);
          compareArrayItem("arrivals", difference, prev, current, combinedArr);
          compareArrayItem(
            "departures",
            difference,
            prev,
            current,
            combinedArr
          );
          compareArrayItem(
            "locationPoints",
            difference,
            prev,
            current,
            combinedArr
          );
          compareArrayItem(
            "customValues",
            difference,
            prev,
            current,
            combinedArr
          );
          // compareArrayItem("phases", difference, prev, current, combinedArr);
          compareItem("endTime", difference, prev, current, combinedArr);
          compareItem("restriction", difference, prev, current, combinedArr);
          compareItem("eventType", difference, prev, current, combinedArr);
          compareItem("eventState", difference, prev, current, combinedArr);
        }

        // More than one field have been changed so generate one Card which contains all the changes at the same time
        if (difference.length > 1) {
          // Compare fields
          let locationTracksDiff = compareArrayValues(
            prev.locationTracks,
            current.locationTracks,
            ["trackName"]
          );
          locationTracksDiff = condenseMultiRowArrayItem(locationTracksDiff);
          const startTimeDiff = compareValues(
            prev.startTime,
            current.startTime
          );
          const isForNowDiff = compareValues(prev.isForNow, current.isForNow);
          const criticalityDiff = compareValues(
            prev.criticality,
            current.criticality
          );
          const shortDescriptionDiff = compareValues(
            prev.shortDescription,
            current.shortDescription
          );
          const descriptionDiff = compareValues(
            prev.description,
            current.description
          );
          let arrivalsDiff = compareArrayValues(
            prev.arrivals,
            current.arrivals,
            ["trainNumber"]
          );
          arrivalsDiff = condenseMultiRowArrayItem(arrivalsDiff);
          let departuresDiff = compareArrayValues(
            prev.departures,
            current.departures,
            ["trainNumber"]
          );
          departuresDiff = condenseMultiRowArrayItem(departuresDiff);
          let locationPointsDiff = compareArrayValues(
            prev.locationPoints,
            current.locationPoints,
            ["pointName"]
          );
          locationPointsDiff = condenseMultiRowArrayItem(locationPointsDiff);
          const customValuesDiff = compareArrayValues(
            prev.customValues,
            current.customValues,
            ["value"]
          );
          // const phasesDiff = compareArrayValues(prev.phases, current.phases, [
          //   "name",
          //   "phaseState",
          //   "duration",
          // ]);
          const endTimeDiff = compareValues(prev.endTime, current.endTime);
          const restrictionDiff = compareValues(
            prev.restriction,
            current.restriction
          );
          const eventTypeDiff = compareValues(
            prev.eventType,
            current.eventType
          );
          const eventStateDiff = compareValues(
            prev.eventState,
            current.eventState
          );

          const d = new Date(current.modifiedDate);
          combinedArr.push({
            time: moment(current.modifiedDate),
            value: (
              <Card
                key={d + "combinedValues" + index}
                className={classes.cardItem}
              >
                <CardContent>
                  <Typography component={"div"} className={classes.title}>
                    {d.toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </Typography>
                  <Typography component={"div"} className={classes.title}>
                    {difference.includes("locationTracks") &&
                    locationTracksDiff.length > 0
                      ? makeInnerArrayItem(
                          "locationTracks",
                          ["trackName"],
                          locationTracksDiff
                        )
                      : null}
                    {difference.includes("startTime")
                      ? makeInnerItem("startTime", startTimeDiff)
                      : null}
                    {difference.includes("isForNow")
                      ? makeInnerItem("isForNow", isForNowDiff)
                      : null}
                    {difference.includes("criticality")
                      ? makeInnerItem("criticality", criticalityDiff)
                      : null}
                    {difference.includes("shortDescription")
                      ? makeInnerItem("shortDescription", shortDescriptionDiff)
                      : null}
                    {difference.includes("description")
                      ? makeInnerItem("description", descriptionDiff)
                      : null}
                    {difference.includes("arrivals") && arrivalsDiff.length > 0
                      ? makeInnerArrayItem(
                          "arrivals",
                          ["trainNumber"],
                          arrivalsDiff
                        )
                      : null}
                    {difference.includes("departures") &&
                    departuresDiff.length > 0
                      ? makeInnerArrayItem(
                          "departures",
                          ["trainNumber"],
                          departuresDiff
                        )
                      : null}
                    {difference.includes("locationPoints") &&
                    locationPointsDiff.length > 0
                      ? makeInnerArrayItem(
                          "locationPoints",
                          ["pointName"],
                          locationPointsDiff
                        )
                      : null}
                    {/* {difference.includes("phases") && phasesDiff.length > 0
                      ? makeInnerArrayItem(
                          "phases",
                          ["name", "phaseState", "duration"],
                          phasesDiff
                        )
                      : null} */}
                    {difference.includes("customValues") &&
                    customValuesDiff.length > 0
                      ? makeInnerArrayItem(
                          "customValues",
                          ["value"],
                          customValuesDiff
                        )
                      : null}
                    {difference.includes("endTime")
                      ? makeInnerItem("endTime", endTimeDiff)
                      : null}
                    {difference.includes("restriction")
                      ? makeInnerItem("restriction", restrictionDiff)
                      : null}
                    {difference.includes("eventType")
                      ? makeInnerItem("eventType", eventTypeDiff)
                      : null}
                    {difference.includes("eventState")
                      ? makeInnerItem("eventState", eventStateDiff)
                      : null}
                  </Typography>
                </CardContent>
              </Card>
            ),
          });
        }
      }
    }
    return combinedArr;
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <div id="accordionDiv" className={classes.accordionDiv}>
          <Accordion onChange={resetNewMsgCount}>
            <AccordionSummary
              className={classes.panelSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.text}>
                {chat} {i18n.t("navigation.eventInfoHistory.changeHistory")}
              </Typography>
            </AccordionSummary>
            <Stack marginLeft="6px" direction="row" spacing={2}>
              <Box m={1}>
                <TextField
                  id="msg-field"
                  classes={{ root: classes.formControlRoot }}
                  InputProps={{
                    classes: {
                      underline: classes.underline,
                      root: classes.inputRootText,
                    },
                  }}
                  variant="standard"
                  placeholder={i18n.t("navigation.eventInfoHistory.message")}
                  multiline
                  rows={2}
                  value={props.message}
                  onChange={onMessageChange}
                />
              </Box>
              <IconButton
                id="msg-btn"
                color="primary"
                variant="contained"
                className={classes.iconButtonPosition}
                onClick={onClicked}
              >
                <div
                  id="iconButtonBackground"
                  className={classes.iconButtonBackground}
                >
                  <Icon
                    baseClassName="material-icons-outlined"
                    className={classes.sendButton}
                    sx={{
                      transform: "rotate(90deg)",
                    }}
                  >
                    {i18n.t("navigation.eventInfo.send")}
                  </Icon>
                </div>
              </IconButton>
            </Stack>
            <AccordionDetails id="history-list">
              {props.pastMessagesArray.map((item) => item.value)}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ChangeHistory;
