export const allowFetchEvents = (names) => {
  return (
    names.includes("ProXviewerUser") ||
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXexternalUser") ||
    names.includes("ProXexternalEditor") ||
    names.includes("ProXprofileAdmin")
  );
};

export const allowFecthTracks = (names) => {
  return (
    names.includes("ProXviewerUser") ||
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXexternalUser") ||
    names.includes("ProXexternalEditor")
  );
};

export const showMap = (names) => {
  return (
    names.includes("ProXBasicUiLicense") ||
    names.includes("ProXviewerUser") ||
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXprofileAdmin") ||
    names.includes("ProXexternalUser") ||
    names.includes("ProXexternalEditor") ||
    names.includes("ProXlevelCrossingBasic")
  );
};

export const showTrackElements = (names) => {
  return (
    names.includes("ProXviewerUser") ||
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXprofileAdmin") ||
    names.includes("ProXexternalUser") ||
    names.includes("ProXexternalEditor") ||
    names.includes("ProXlevelCrossingBasic")
  );
};

export const showScheduler = (names) => {
  return names.includes("ProXEventsUiLicense");
};

export const showCreateEvent = (names) => {
  return (
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXexternalEditor") ||
    names.includes("ProXprofileAdmin")
  );
};

export const showEditEvent = (names) => {
  return (
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXprofileAdmin") ||
    names.includes("ProXexternalEditor")
  );
};

export const showDeleteEvent = (names) => {
  return names.includes("ProXadvancedUser");
};

export const showEventHistoryAndMessages = (names) => {
  return (
    names.includes("ProXviewerUser") ||
    names.includes("ProXbasicUser") ||
    names.includes("ProXadvancedUser") ||
    names.includes("ProXprofileAdmin") ||
    names.includes("ProXexternalUser") ||
    names.includes("ProXexternalEditor")
  );
};
