import { makeStyles } from "@mui/styles";
import { Box, Button, Icon, Typography } from "@mui/material";
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.proxBlue,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  iconButton: {
    marginRight: "5px",
    color: theme.palette.common.white + " !important",
  },
  title: {
    fontSize: "16px !important",
    textTransform: "uppercase",
    marginLeft: "15px !important",
    color: theme.palette.common.white + " !important",
  },
}));

const closeIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.close")}
    </Icon>
  </Box>
);

const EventHeader = (props) => {
  const { title } = props;
  const classes = useStyles();
  return (
    <div id="create-event-header" className={classes.header}>
      <Typography className={classes.title}>
        {/* {i18n.t("navigation.eventInfoCreate.text")} */}
        {title}
      </Typography>
      <Button
        id="close-form-btn"
        classes={{ root: classes.iconButton }}
        onClick={props.handleCloseClick}
      >
        {closeIcon}
      </Button>
    </div>
  );
};

export default EventHeader;
