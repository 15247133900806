import { useSelector } from "react-redux";
import EventInfoCreate from "../../../../Menus/Desktop/EventInfoCreate";
import EventInfoEdit from "../../../../Menus/Desktop/EventInfoEdit";
import FilterList from "../../../../Menus/Desktop/FilterList";
import MovingObjects from "../../../../Menus/Desktop/MovingObjects";

const SideMenu = (props) => {
  const openview = useSelector((state) => state.userinterface.openview);
  const eventId = useSelector((state) => state.userinterface.eventId);
  const siteId = useSelector((state) => state.permissions.selectedSite);
  const orgId = useSelector((state) => state.permissions.userOrg);
  let menu = null;
  let menuDivStyle = { width: "0%", transition: "opacity 1s" };

  if (["eventInfoCreateHalf", "eventInfoCreateFull"].includes(openview)) {
    menu = (
      <EventInfoCreate
        showView={props.showViewAction}
        setView={props.setView}
        view={props.view}
        openview={openview}
        siteId={siteId}
        orgId={orgId}
      />
    );
    menuDivStyle = {
      minWidth: "440px",
      maxWidth: "440px",
      borderBottom: "1px solid rgba(128,128,128, 0.1)",
      borderRight: "1px solid rgba(128,128,128, 0.1)",
      borderTop: "1px solid rgba(128,128,128, 0.1)",
      borderLeft: "none",
      overflowY: "hidden",
      overflowX: "hidden",
    };
  }

  if (["eventInfoEditHalf", "eventInfoEditFull"].includes(openview)) {
    menu = (
      <EventInfoEdit
        showView={props.showViewAction}
        setView={props.setView}
        view={props.view}
        eventId={eventId}
        openview={openview}
        siteId={siteId}
        orgId={orgId}
      />
    );
    menuDivStyle = {
      minWidth: "440px",
      maxWidth: "440px",
      borderBottom: "1px solid rgba(128,128,128, 0.1)",
      borderRight: "1px solid rgba(128,128,128, 0.1)",
      borderTop: "1px solid rgba(128,128,128, 0.1)",
      borderLeft: "none",
      overflowY: "hidden",
      overflowX: "hidden",
    };
  }

  if (
    [
      "filterListHalf",
      "filterListFull",
      "eventListHalf",
      "eventListFull",
    ].includes(openview)
  ) {
    menu = (
      <FilterList
        defaultView={
          ["filterListHalf", "filterListFull"].includes(openview) ? true : false
        }
        openview={openview}
        showView={props.showViewAction}
      />
    );
    menuDivStyle = {
      minWidth: "440px",
      maxWidth: "440px",
      borderBottom: "1px solid rgba(128,128,128, 0.1)",
      borderRight: "1px solid rgba(128,128,128, 0.1)",
      borderTop: "1px solid rgba(128,128,128, 0.1)",
      borderLeft: "none",
      overflowY: "hidden",
      overflowX: "hidden",
    };
  }

  if (["movingObjects"].includes(openview)) {
    menu = <MovingObjects showView={props.showViewAction} />;
    menuDivStyle = {
      minWidth: "440px",
      maxWidth: "440px",
      borderBottom: "1px solid rgba(128,128,128, 0.1)",
      borderRight: "1px solid rgba(128,128,128, 0.1)",
      borderTop: "1px solid rgba(128,128,128, 0.1)",
      borderLeft: "none",
      overflowY: "hidden",
      overflowX: "hidden",
    };
  }

  return <div style={menuDivStyle}>{menu}</div>;
};

export default SideMenu;
