import { Flex } from "../../Layout";
import i18n from "i18next";
import "./index.css";
import { Icon } from "@mui/material";
import { monthInfo } from "../../../../utils/schedulerUtils";
import HeaderDayColumns from "./_components/HeaderDayColumns";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

export default function SchedulerHeader(props) {
  const { dayColumnRef, openview, onChangeView, executeScroll } = props;
  const currentDate = new Date();
  const { prevThree, prevTwo, prev, current, next, nextTwo, nextThree } =
    monthInfo(currentDate);
  const dispatch = useDispatch();

  const handleTodayClick = () => {
    executeScroll();
    dispatch(actions.resetEventsByTime());
  };

  return (
    <Flex>
      <Flex direction="row" justify="center" id="header" className="header">
        <Flex
          id="today-toggler"
          className="today-toggler"
          onClick={handleTodayClick}
        >
          <Icon sx={{ fontSize: "17px" }} className="today-icon">
            today
          </Icon>
        </Flex>
        <Flex
          direction="row"
          id="view-toggler-cont"
          className="view-toggler-cont"
        >
          <Flex className="view-toggler selected">
            <Icon sx={{ fontSize: "17px" }} className="calendar-vew-icon">
              calendar_view_week
            </Icon>
          </Flex>
          <Flex id="view-toggler" className="view-toggler">
            <Icon sx={{ fontSize: "17px" }} className="calendar-vew-icon">
              crop_landscape_outlined
            </Icon>
          </Flex>
        </Flex>
        <span id="header-title" className="header-title">
          {i18n.t("schedulerPage.pageTitle")}
        </span>
        <Flex id="fullscreen-toggler" className="fullscreen-toggler">
          {openview === "events" && (
            <Icon
              className="fullscreen-icon"
              onClick={() => onChangeView("halfevents")}
            >
              {"fullscreen_exit"}
            </Icon>
          )}

          {openview === "halfevents" && (
            <Icon
              className="fullscreen-icon"
              onClick={() => onChangeView("events")}
            >
              {"fullscreen"}
            </Icon>
          )}
        </Flex>
      </Flex>
      <HeaderDayColumns
        style={{ flex: 1, overflowY: "auto" }}
        monthInfo={{
          prevThree,
          prevTwo,
          prev,
          current,
          next,
          nextTwo,
          nextThree,
        }}
        dayColumnRef={dayColumnRef}
        executeScroll={executeScroll}
      />
    </Flex>
  );
}
