import "./FallBack.css";
import i18n from "i18next";
import { Box, Button, Link, Typography } from "@mui/material";

const FallBack = () => {
  const handleOnClick = () => {
    window.location.reload();
  };

  const email = i18n.t("errorPage.supportEmail");

  return (
    <Box m={1} className="fallback-main">
      <Typography>{i18n.t("errorPage.errorOccured")}</Typography>
      <Link href={`mailto: ${email}`}>{email}</Link>
      <Button
        className="reload-btn"
        onClick={handleOnClick}
        variant="contained"
        component="span"
      >
        {i18n.t("errorPage.reloadPage")}
      </Button>
    </Box>
  );
};
export default FallBack;
