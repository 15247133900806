import React from "react";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChangeHistory from "../ChangeHistoryEdit/ChangeHistoryEdit";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    backgroundColor: theme.palette.common.white,
    marginBottom: "20px",
  },
  column: {
    display: "table",
  },
  btnGroup: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
  },
  severityTitle: {
    float: "top",
    display: "block",
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 600,
    marginTop: "10px !important",
    marginBottom: "6px !important",
    color: theme.palette.common.black,
  },
  chipContainer: {
    float: "bottom",
    display: "block",
    justifyContent: "space-evenly",
    marginTop: "-10px",
    marginBottom: "18px",
    width: "412px",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
  },
  chipText: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  chipPlanned: {
    display: "table-cell",
    float: "left",
    width: "127px",
    height: "45px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
  chipStarted: {
    display: "table-cell",
    float: "right",
    width: "127px",
    height: "45px",
    padding: "5px",
  },
  chipReady: {
    display: "table-cell",
    float: "right",
    width: "127px",
    height: "45px",
    marginRight: "0px",
    marginLeft: "-5px",
    padding: "5px",
  },
}));

const EventStateEdit = (props) => {
  const classes = useStyles();

  const handleChange = (event, newAlignment) => {
    props.setEventState(newAlignment);
    props.handleEventStateChanged(newAlignment);
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <div className={classes.chipContainer}>
          <Typography className={classes.severityTitle}>
            {i18n.t("navigation.eventInfoHistory.eventState")}
          </Typography>
          <ToggleButtonGroup
            id="edit-event-event-state-toggle"
            aria-label="triggers some other action"
            classes={{ root: classes.btnGroup }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            color="primary"
            value={props.eventState}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton className={classes.chipPlanned} value="planned">
              <Typography className={classes.chipText}>
                {i18n.t("navigation.eventInfoHistory.planned")}
              </Typography>
            </ToggleButton>
            <ToggleButton className={classes.chipStarted} value="started">
              <Typography className={classes.chipText}>
                {i18n.t("navigation.eventInfoHistory.started")}
              </Typography>
            </ToggleButton>
            <ToggleButton className={classes.chipReady} value="finished">
              <Typography className={classes.chipText}>
                {i18n.t("navigation.eventInfoHistory.finished")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div id="changeHistory">
          {props.showHistoryAndMsg && (
            <ChangeHistory
              message={props.message}
              pastMessages={props.pastMessages}
              setMessage={props.setMessage}
              history={props.history}
              pastMessagesArray={props.pastMessagesArray}
              setPastMessagesArray={props.setPastMessagesArray}
              handleCommentSave={props.handleCommentSave}
              orgFields={props.orgFields}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventStateEdit;
