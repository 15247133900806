import { useSelector } from "react-redux";
import EventInfoCreate from "../../Menus/Desktop/EventInfoCreate";
import EventInfoEdit from "../../Menus/Desktop/EventInfoEdit";
import FilterList from "../../Menus/Desktop/FilterList";
import MovingObjects from "../../Menus/Desktop/MovingObjects";

import { Box } from "@mui/material";

const NavigationMenu = (props) => {
  const openview = useSelector((state) => state.userinterface.openview);
  const eventId = useSelector((state) => state.userinterface.eventId);
  const siteId = useSelector((state) => state.permissions.selectedSite);
  const orgId = useSelector((state) => state.permissions.userOrg);
  let menu = null;

  if (["eventInfoCreateHalf", "eventInfoCreateFull"].includes(openview)) {
    menu = (
      <EventInfoCreate
        showView={props.showViewAction}
        setView={props.setView}
        view={props.view}
        openview={openview}
        siteId={siteId}
        orgId={orgId}
      />
    );
  }

  if (["eventInfoEditHalf", "eventInfoEditFull"].includes(openview)) {
    menu = (
      <EventInfoEdit
        showView={props.showViewAction}
        setView={props.setView}
        view={props.view}
        eventId={eventId}
        openview={openview}
        siteId={siteId}
        orgId={orgId}
      />
    );
  }

  if (
    [
      "filterListHalf",
      "filterListFull",
      "eventListHalf",
      "eventListFull",
    ].includes(openview)
  ) {
    menu = (
      <FilterList
        defaultView={
          ["filterListHalf", "filterListFull"].includes(openview) ? true : false
        }
        openview={openview}
        showView={props.showViewAction}
      />
    );
  }

  if (["movingObjects"].includes(openview)) {
    menu = <MovingObjects showView={props.showViewAction} />;
  }

  return (
    <Box
      id="navigation-menu-main"
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {menu}
    </Box>
  );
};

export default NavigationMenu;
