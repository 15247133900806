export const findTrainArrivalAndDepartures = (trains) => {
  // const trainArrivals = [];
  // const trainDepartures = [];
  const trainArrivals = [],
    trainDepartures = [];

  trains.forEach((train) => {
    if (Array.isArray(train.timeTableRows) && !train.timeTableRows.length) {
      return;
    }
    const start = train.timeTableRows[0];
    const end = train.timeTableRows[train.timeTableRows.length - 1];
    const latestEvent = [...train.timeTableRows]
      .reverse()
      .find((ttr) => typeof ttr.actualTime === "string");

    if (
      !start.cancelled &&
      start.type === "DEPARTURE" &&
      (start.stationShortCode === "YKST" || start.stationShortCode === "YKSV")
    ) {
      start.trainId = train.uuid;
      start.trainType = train.trainType;
      start.trainNumber = train.trainNumber;
      if (latestEvent) {
        start.latestEvent = latestEvent;
      }
      trainDepartures.push(start);
    }

    if (
      !end.cancelled &&
      end.type === "ARRIVAL" &&
      (end.stationShortCode === "YKST" || end.stationShortCode === "YKSV")
    ) {
      end.trainId = train.uuid;
      end.trainType = train.trainType;
      end.trainNumber = train.trainNumber;
      if (latestEvent) {
        end.latestEvent = latestEvent;
      }
      trainArrivals.push(end);
    }
  });
  return { trainArrivals, trainDepartures };
};

export const findTrainId = (trains, trainNumbers) => {
  const trainIds = [];
  trains.forEach((o) => {
    const index = trainNumbers.indexOf(o.trainNumber);
    if (index !== -1) {
      const trainNumber = trainNumbers[index];
      if (o.trainNumber === trainNumber) {
        trainIds.push(o.trainId);
      }
    }
  });
  return trainIds;
};
