import "./index.css";

import MapChooser from "../../../../../Map/MapChooser";
import MiniScheduler from "../../../../../MiniScheduler";
import FilterInfo from "../../../../FilterInfo";
import FollowInfo from "../../../../FollowInfo";

const DefaultView = (props) => {
  const { openview, siteId, startFilter, endFilter, isFiltered, followedUnit } =
    props;

  return (
    <div className="default-content">
      <div id="default-map" className="default-map">
        {isFiltered && <FilterInfo start={startFilter} end={endFilter} />}
        {followedUnit && (
          <FollowInfo followedUnit={followedUnit} isFiltered={isFiltered} />
        )}
        <MapChooser view={openview} desktop={true} siteId={siteId} />
      </div>
      <div id="timeline" className="timeline">
        <MiniScheduler
          showViewAction={props.showViewAction}
          openview={openview}
        />
      </div>
    </div>
  );
};

export default DefaultView;
