import React from "react";
import { Flex } from "../../../../Layout";
import Column from "./_components/Column";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";

export default function DayColumns({
  monthInfo,
  dayColumnRef,
  executeScroll,
  ...rest
}) {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const isFiltered = useSelector((s) => s.userinterface.isFiltered);
  const selected = useSelector((s) => s.userinterface.selected);

  React.useEffect(() => {
    executeScroll();
  }, []);

  React.useEffect(() => {
    if (selected.length === 2) {
      const lastIndex = selected.length - 1;
      const start = selected[0];
      const end = selected[lastIndex];

      const startMoment = moment(start, "DD.MM.YYYY HH:mm");
      const endMoment = moment(end, "DD.MM.YYYY HH:mm");

      let timeFilter = {
        start: startMoment.format("YYYY-MM-DD[T]HH:mm"),
        end: endMoment.format("YYYY-MM-DD[T]HH:mm"),
      };
      if (startMoment.isAfter(endMoment)) {
        timeFilter = {
          start: endMoment.format("YYYY-MM-DD[T]HH:mm"),
          end: startMoment.format("YYYY-MM-DD[T]HH:mm"),
        };
      }

      dispatch(actions.filterEventsByTime(timeFilter));
    }
    if (selected.length === 0 && isFiltered) {
      dispatch(actions.resetEventsByTime());
    }
  }, [selected]);

  const handleAddSelected = (dt) => {
    dispatch(actions.addToSelected(dt));
  };

  const Columns = React.useCallback(() => {
    let months = [
      monthInfo.prevThree,
      monthInfo.prevTwo,
      monthInfo.prev,
      monthInfo.current,
      monthInfo.next,
      monthInfo.nextTwo,
      monthInfo.nextThree,
    ];
    let columns = [];

    for (let i = 0; i < 7; i++) {
      let month = months[i];
      let day = month.dod;
      let year = month.year;

      // Convert sunday to proper int for localization
      if (day === 0) {
        day = 7;
      }

      for (let k = 1; k <= month.days; k++) {
        if (day > 7) {
          day = 1;
        }

        // For scroll to today functionality
        // Ensure that it's the current month and current date
        columns.push(
          <Column
            key={`${k}_${month.num}`}
            day={day}
            date={k}
            month={month.num + 1}
            year={year}
            selected={selected}
            addSelected={handleAddSelected}
          />
        );

        day++;
      }
    }

    return columns;
  }, [currentDate]);

  return (
    <Flex
      id="column"
      ref={dayColumnRef}
      direction="row"
      className="day-columns-cont"
      {...rest}
    >
      <Columns />
    </Flex>
  );
}
