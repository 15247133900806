import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventMessages } from "../../../../services/events";
import i18n from "i18next";
import moment from "moment";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  setFilterListEventIds,
  updateEventComments,
} from "../../../../store/actions";
import EventHeader from "./EventHeader";
import EventList from "./FilterList/EventList";
import Filters from "./FilterList/Filters";
import MessageList from "./FilterList/MessageList";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    marginTop: "0px",
  },
  alertBox: {
    height: "47px",
    maxWidth: "390px",
    width: "100vw",
    marginBottom: "3px",
    backgroundColor: "transparent",
    marginTop: "-18px",
    zIndex: "5",
  },
  alertText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
  },
  iconButton: {
    marginLeft: "10px",
    marginTop: "8px !important",
    color: theme.palette.common.white + " !important",
    display: "inline-flex !important",
  },
  header: {
    display: "contents",
    float: "left",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  headerLock: {
    display: "contents",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  headerEdit: {
    display: "contents",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  dividerLarge: {
    display: "inline-block",
    width: "50% !important",
  },
  dividerSmall: {
    display: "inline-block",
    width: "0px !important",
    marginLeft: "-20px",
  },
  title: {
    fontSize: "16px !important",
    marginLeft: "15px !important",
    marginBottom: "5px !important",
  },
  column: {
    display: "inherit",
    marginTop: "6px",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.common.white,
    padding: "8px",
    paddingBottom: "10vh",
    transition: "opacity 1s",
    overflow: "scroll",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100vh",
  },
  row: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "22px",
  },
  rowNoBorders: {
    backgroundColor: theme.palette.common.white,
    fontSize: "22px",
  },
  lastRowNoBorders: {
    backgroundColor: theme.palette.common.white,
    marginBottom: "56px",
    fontSize: "22px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "22px",
  },
  saveButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  removeButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  buttonRow: {
    backgroundColor: theme.palette.common.white,
    marginBottom: "8px",
    padding: "10px",
    fontSize: "22px",
  },
}));

const FilterList = (props) => {
  const { defaultView, openview } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isForNowFilter, setIsForNowFilter] = useState(false);
  const [locationTracks, setLocationTracks] = useState([]);
  const [pickedTypes, setPickedTypes] = useState([
    "trackcapacityreservation",
    "servicelocationreservation",
    "maintenance",
    "alarm",
    "other",
  ]);
  const [useCriticalities, setUseCriticalities] = useState(false);
  const [criticality, setCriticality] = useState([]);
  const [startTime, setStartTime] = useState(startTimeOfFilter());
  const [endTime, setEndTime] = useState(endTimeOfFilter());
  const filterListEventIds = useSelector(
    (state) => state.userinterface.filterListEventIds
  );
  const events = useSelector((state) => state.userinterface.events);
  const eventComments = useSelector(
    (state) => state.userinterface.eventComments
  );
  const dispatch = useDispatch();

  const doDispatch = () => {
    const eventIds = filteredEvents.map(function (k) {
      return k.uuid;
    });

    if (
      eventIds.length === 0 ||
      filterListEventIds.length === 0 ||
      eventIds.length !== filterListEventIds.length
    ) {
      return true;
    }

    for (let id of eventIds) {
      if (!filterListEventIds.includes(id)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      filterListEventIds !== undefined &&
      filterListEventIds.length > 0 &&
      doDispatch()
    ) {
      let filtered = [];
      let filteredComments = [];
      let filteredTracks = [];
      let filteredTypes = [];
      let filteredCriticalities = [];
      let filteredStartTimes = [];
      let filteredEndTimes = [];

      for (let ev of events) {
        if (filterListEventIds.includes(ev.uuid)) {
          for (let track of ev.locationTracks) {
            filteredTracks.push(track.trackName);
          }
          filteredTypes.push(ev.eventType);
          filteredCriticalities.push(ev.criticality);
          if (!ev.isForNow) {
            filteredStartTimes.push(ev.startTime);
          }
          filteredEndTimes.push(ev.endTime);
          filtered.push(ev);
          for (let [key, value] of Object.entries(eventComments)) {
            if (key === ev.uuid) {
              filteredComments.push([key, value]);
            }
          }
        }
      }
      setFilteredEvents(filtered);
      setMessages([...new Set(filteredComments)]);
      setLocationTracks([...new Set(filteredTracks)]);
      setPickedTypes([...new Set(filteredTypes)]);
      if (filteredCriticalities.length > 0) {
        setUseCriticalities(true);
      }
      setCriticality([...new Set(filteredCriticalities)]);
      setIsForNowFilter(filteredEndTimes.includes(null));

      let compareStart = filteredStartTimes[0];
      for (let date of [...new Set(filteredStartTimes)]) {
        if (moment(date).isBefore(moment(compareStart))) {
          compareStart = date;
        }
      }
      let compareEnd = filteredEndTimes[0];
      for (let date of [...new Set(filteredEndTimes)]) {
        if (compareEnd === null || moment(date).isAfter(moment(compareEnd))) {
          compareEnd = date;
        }
      }
      if (compareStart !== undefined && compareStart !== null) {
        setStartTime(new Date(compareStart));
      } else {
        setStartTime(new Date());
      }
      if (compareEnd !== undefined && compareEnd !== null) {
        setEndTime(new Date(compareEnd));
      } else {
        setStartTime(new Date());
      }
    }
    setLoading(false);
  }, [filterListEventIds]);

  useEffect(() => {
    setLoading(true);
    let filteredEvents = events.filter(function (k) {
      return pickedTypes.includes(k.eventType);
    });

    if (isForNowFilter) {
      filteredEvents = filteredEvents.filter(function (k) {
        return (
          (k.endTime === null && moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrBefore(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime) &&
            moment(k.endTime).isSameOrAfter(startTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrAfter(endTime) &&
            moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime))
        );
      });
    } else {
      filteredEvents = filteredEvents.filter(function (k) {
        return (
          (moment(k.startTime).isSameOrBefore(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime) &&
            moment(k.endTime).isSameOrAfter(startTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrAfter(endTime) &&
            moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime))
        );
      });
    }

    if (locationTracks.length > 0) {
      filteredEvents = filteredEvents.filter(function (k) {
        return k.locationTracks
          .map(function (k) {
            return locationTracks.includes(k.trackName);
          })
          .includes(true);
      });
    }

    if (useCriticalities) {
      filteredEvents = filteredEvents.filter(function (k) {
        return criticality.includes(k.criticality);
      });
    }
    if (!isForNowFilter) {
      filteredEvents = filteredEvents.filter(function (k) {
        return k.isForNow === false;
      });
    }
    fetchEventComments(filteredEvents);
  }, [
    locationTracks,
    pickedTypes,
    useCriticalities,
    criticality,
    isForNowFilter,
    startTime,
    endTime,
  ]);

  useEffect(() => {
    let filteredComments = [];
    let filteredEvents = events.filter(function (k) {
      return pickedTypes.includes(k.eventType);
    });

    if (isForNowFilter) {
      filteredEvents = filteredEvents.filter(function (k) {
        return (
          (k.endTime === null && moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrBefore(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime) &&
            moment(k.endTime).isSameOrAfter(startTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrAfter(endTime) &&
            moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime))
        );
      });
    } else {
      filteredEvents = filteredEvents.filter(function (k) {
        return (
          (moment(k.startTime).isSameOrBefore(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime) &&
            moment(k.endTime).isSameOrAfter(startTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrAfter(endTime) &&
            moment(k.startTime).isSameOrBefore(endTime)) ||
          (moment(k.startTime).isSameOrAfter(startTime) &&
            moment(k.endTime).isSameOrBefore(endTime))
        );
      });
    }

    if (locationTracks.length > 0) {
      filteredEvents = filteredEvents.filter(function (k) {
        return k.locationTracks
          .map(function (k) {
            return locationTracks.includes(k.trackName);
          })
          .includes(true);
      });
    }

    if (useCriticalities) {
      filteredEvents = filteredEvents.filter(function (k) {
        return criticality.includes(k.criticality);
      });
    }
    if (!isForNowFilter) {
      filteredEvents = filteredEvents.filter(function (k) {
        return k.isForNow === false;
      });
    }

    for (let ev of filteredEvents) {
      for (let [key, value] of Object.entries(eventComments)) {
        if (key === ev.uuid) {
          filteredComments.push([key, value]);
        }
      }
    }

    const eventIds = filteredEvents.map(function (k) {
      return k.uuid;
    });
    setFilteredEvents(filteredEvents);
    setMessages([...new Set(filteredComments)]);
    if (!filterListEventIds.includes(eventIds)) {
      dispatch(setFilterListEventIds(eventIds));
    }
  }, [
    locationTracks,
    pickedTypes,
    useCriticalities,
    criticality,
    isForNowFilter,
    startTime,
    endTime,
    eventComments,
  ]);

  const fetchEventComments = (events) => {
    for (let event of events) {
      const eventMsgRequest = getEventMessages(event.siteId, event.uuid);
      eventMsgRequest
        .then((response) => {
          const commentsArr = response?.data?.comments;
          const eventComment = {
            comments: commentsArr,
            newComments: 0,
          };
          dispatch(updateEventComments(event.uuid, eventComment));
        })
        .catch((err) => {
          console.log(
            `Erro while initially fetching event comments for eventId ${event.uuid}`
          );
          console.error(err);
        });
    }
  };

  const handleClose = () => {
    if (openview === "filterListFull" || openview === "eventListFull") {
      props.showView("events");
    }
    if (openview === "filterListHalf" || openview === "eventListHalf") {
      props.showView("halfevents");
    }
  };

  function startTimeOfFilter() {
    let date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  function endTimeOfFilter() {
    let date = new Date();
    date.setDate(date.getDate() + 3);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  const reset = () => {
    setLocationTracks([]);
    setPickedTypes([
      "trackcapacityreservation",
      "servicelocationreservation",
      "maintenance",
      "alarm",
      "other",
    ]);
    setUseCriticalities(false);
    setCriticality([]);
    setIsForNowFilter(false);
    setStartTime(startTimeOfFilter());
    setEndTime(endTimeOfFilter());
  };

  return (
    <div className={classes.mainContainer}>
      <EventHeader
        title={i18n.t("navigation.filterList.text")}
        handleCloseClick={handleClose}
      />
      <div id="eventContainer" className={classes.eventContainer}>
        <Box
          id="create-event-column"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
            maxHeight: {
              xs: `calc(${height}px - 56px)`,
              md: `${height}px`,
            },
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div id="row" className={classes.rowNoBorders}>
            <Filters
              pickedTypes={pickedTypes}
              setPickedTypes={setPickedTypes}
              useCriticalities={useCriticalities}
              setUseCriticalities={setUseCriticalities}
              criticality={criticality}
              setCriticality={setCriticality}
              isForNowFilter={isForNowFilter}
              setIsForNowFilter={setIsForNowFilter}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              locationTracks={locationTracks}
              setLocationTracks={setLocationTracks}
              reset={reset}
              expanded={defaultView}
            />
          </div>
          <div id="row" className={classes.rowNoBorders}>
            <EventList
              events={filteredEvents}
              messages={messages}
              openView={openview}
              expanded={!defaultView}
            />
          </div>
          <div id="row" className={classes.lastRowNoBorders}>
            <MessageList
              events={filteredEvents}
              messages={messages}
              openView={openview}
              expanded={!defaultView && !loading}
            />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default FilterList;
