import * as actionTypes from "../actions/actionTypes";

const MAP_ZOOM = 15;

const initialState = {
  mapBounds: undefined,
  mapStyle: process.env.REACT_APP_MAP_TILE_URL,
  viewState: {
    latitude: 0,
    longitude: 0,
    zoom: process.env.REACT_APP_MAP_ZOOM || MAP_ZOOM,
  },
  trackLayerData: [],
  pointLayerData: [],
  selectedFeature: null,
  followUnit: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAP_STYLE:
      return {
        ...state,
        mapStyle: action.payload,
      };
    case actionTypes.SET_VIEW_STATE:
      return {
        ...state,
        viewState: action.payload,
      };
    case actionTypes.SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.payload,
      };
    case actionTypes.SET_TRACK_LAYER_DATA:
      return {
        ...state,
        trackLayerData: action.payload,
      };
    case actionTypes.SET_POINT_LAYER_DATA:
      return {
        ...state,
        pointLayerData: action.payload,
      };
    case actionTypes.MAP_SELECT_FEATURE:
      return {
        ...state,
        selectedFeature: action.payload,
      };
    case actionTypes.MAP_DESELECT_FEATURE:
      return {
        ...state,
        selectedFeature: null,
      };
    case actionTypes.MAP_ALARM_TRACKS:
      return {
        ...state,
        alarmFilter: action.payload,
      };
    case actionTypes.MAP_OTHER_TRACKS:
      return {
        ...state,
        otherFilter: action.payload,
      };
    case actionTypes.MAP_SERVICE_LOCATION_TRACKS:
      return {
        ...state,
        serviceLocationFilter: action.payload,
      };
    case actionTypes.MAP_TRACK_CAPACITY_TRACKS:
      return {
        ...state,
        trackCapacityFilter: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_ALARM_TRACKS:
      return {
        ...state,
        isForNowalarm: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_OTHER_TRACKS:
      return {
        ...state,
        isForNowOther: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_SERVICE_LOCATION_TRACKS:
      return {
        ...state,
        isForNowServiceLocation: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_TRACK_CAPACITY_TRACKS:
      return {
        ...state,
        isForNowTrackCapacity: action.payload,
      };
    case actionTypes.MAP_STOP_TRACKS:
      return {
        ...state,
        stopTrackFilter: action.payload,
      };
    case actionTypes.MAP_RESTRICTED_TRACKS:
      return {
        ...state,
        restrictedTrackFilter: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_STOP_TRACKS:
      return {
        ...state,
        isForNowStop: action.payload,
      };
    case actionTypes.MAP_ISFORNOW_RESTRICTED_TRACKS:
      return {
        ...state,
        isForNowRestricted: action.payload,
      };
    case actionTypes.FOLLOW_UNIT:
      return {
        ...state,
        followUnit: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
