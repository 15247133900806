import moment from "moment-timezone";
import { TRACK_HEIGHT } from "../../../../../../constants";
import { getEventPositionalProps } from "../../../../../../utils/schedulerUtils";
import { Flex } from "../../../../Layout";
import i18n from "i18next";
import "./index.css";
import { Icon, Tooltip } from "@mui/material";

function EventTooltip({
  type,
  trainType,
  trainNumber,
  scheduledTime,
  liveEstimateTime,
  actualTime,
  differenceInMinutes,
  latestEvent,
}) {
  const eventTime = actualTime
    ? actualTime
    : liveEstimateTime
    ? liveEstimateTime
    : scheduledTime;
  let eventTimeText;

  if (typeof actualTime === "string") {
    eventTimeText =
      type === "ARRIVAL"
        ? i18n.t("schedulerPage.trainHover.arrived")
        : i18n.t("schedulerPage.trainHover.departed");
  } else {
    eventTimeText =
      type === "ARRIVAL"
        ? i18n.t("schedulerPage.trainHover.arrives")
        : i18n.t("schedulerPage.trainHover.departs");
  }
  /*
  eventTimeText +=
    " " + moment.tz(scheduledTime, "Europe/Helsinki").format("HH:mm");
  if (eventTime) {
    eventTimeText +=
      " ⇒ " + moment.tz(eventTime, "Europe/Helsinki").format("HH:mm");
  }
  */

  eventTimeText +=
    " " + moment.tz(eventTime, "Europe/Helsinki").format("HH:mm");

  const bgColor = differenceInMinutes > 0 ? "red" : "green";
  return (
    <div>
      <div className="train-event-tooltip">{`${trainType} ${trainNumber}`}</div>
      <div className="train-event-tooltip">
        {eventTimeText}
        {differenceInMinutes && (
          <span
            style={{ backgroundColor: bgColor }}
            className="train-event-diff"
          >
            {differenceInMinutes}
          </span>
        )}
      </div>
      <div className="train-event-tooltip">
        <span>{i18n.t("schedulerPage.trainHover.latest")}</span>
        {`: ${
          latestEvent?.station?.shortCode ? latestEvent.station.shortCode : "-"
        }`}
      </div>
    </div>
  );
}

function Event({ train }) {
  let compareTime = train.actualTime;

  // Set the time that we compare schedule to
  if (!compareTime) {
    if (train.liveEstimateTime) {
      compareTime = train.liveEstimateTime;
    } else {
      compareTime = train.scheduledTime;
    }
  }

  let { x, width, diff } = getEventPositionalProps(
    train.scheduledTime,
    compareTime
  );

  if (width <= 0) {
    width = 1; // Minimum width
  }

  let color = "grey";
  // Check if the train is actually late
  if (diff > 0) {
    color = typeof train.actualTime === "string" ? "red" : "lightred";
  } else if (diff < 0) {
    color = typeof train.actualTime === "string" ? "green" : "lightgreen";
  }

  return (
    <Tooltip title={<EventTooltip {...train} />}>
      <div
        className="train-event"
        style={{ left: x + "px", width: width + "px", backgroundColor: color }}
      />
    </Tooltip>
  );
}

export default function TrainEvent({ schedules, type, left }) {
  const height = TRACK_HEIGHT + "px";

  let icon = null;
  if (type && type === "arrival") {
    icon = (
      <Flex
        className="train-icon-content"
        direction="row"
        align="center"
        style={{
          height: height,
          left: left,
        }}
      >
        <Icon sx={{ fontSize: "34px" }}>arrow_right_alt</Icon>
        <Icon sx={{ fontSize: "16px" }}>directions_railway</Icon>
      </Flex>
    );
  }
  if (type && type === "departure") {
    icon = (
      <Flex
        className="train-icon-content"
        direction="row"
        align="center"
        style={{ height: height, left: left, position: "absolute" }}
      >
        <Icon sx={{ fontSize: "16px" }}>directions_railway</Icon>
        <Icon sx={{ fontSize: "34px" }}>arrow_right_alt</Icon>
      </Flex>
    );
  }
  return (
    <Flex
      align="center"
      direction="row"
      className="event-cont"
      style={{ height: height }}
    >
      {icon}
      {schedules?.map((schedule, index) => (
        <Event key={index} train={schedule} />
      ))}
    </Flex>
  );
}
