import moment from "moment";
import { LngLat, LngLatBounds } from "maplibre-gl";

const getCustomizationData = () => {
  const fallback = { data: [] };
  if (process.env.REACT_APP_CUSTOMIZATION_DATA) {
    return JSON.parse(process.env.REACT_APP_CUSTOMIZATION_DATA);
  }
  return fallback;
};

export const getName = (name) => {
  for (let row of getCustomizationData()["data"]) {
    if (name === row["deviceId"]) {
      return row["name"];
    }
  }
  return name;
};

export const getColor = (name) => {
  for (let row of getCustomizationData()["data"]) {
    if (name === row["deviceId"]) {
      return row["color"];
    }
  }
  return "#49C2F1";
};

export const isInactive = (time) => {
  const curr = moment(new Date());
  const sent = moment(new Date(time));
  const diff = moment(curr, "DD.MM.YYYY HH:mm:ss").diff(
    moment(sent, "DD.MM.YYYY HH:mm:ss")
  );
  if (diff <= 0) {
    return false;
  }
  const minutes = Math.floor(diff / 1000 / 60);
  return minutes >= 30;
};

export const getLabelBackgroundColor = (time) => {
  const curr = moment(new Date());
  const sent = moment(new Date(time));
  const diff = moment(curr, "DD.MM.YYYY HH:mm:ss").diff(
    moment(sent, "DD.MM.YYYY HH:mm:ss")
  );
  if (diff <= 0) {
    return "#2C2C2C";
  }
  const seconds = Math.floor(diff / 1000);
  if (seconds <= 2) {
    return "#2C2C2C";
  } else if (seconds <= 4) {
    return "orange";
  } else {
    return "red";
  }
};

export const isOutOfBounds = (unitLat, unitLng) => {
  let sw = new LngLat(28.7931, 61.2011);
  let ne = new LngLat(28.87754, 61.2557);
  let bounds = new LngLatBounds(sw, ne);

  return !bounds.contains(new LngLat(unitLng, unitLat));
};

export const isPositionAccurate = (dop, fix) => {
  if (dop >= 2 || !fix.includes("RTK")) {
    return false;
  }
  return true;
};
