import { authenticationService } from "../_services";

export function authToken() {
  const currentUser = authenticationService.currentUserValue;
  return currentUser && currentUser.token ? currentUser.token : null;
}

export function logOut() {
  authenticationService.logout();
}
