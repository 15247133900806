import React from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import i18n from "i18next";
import * as actions from "../../../store/actions";
import { Icon, Typography } from "@mui/material";
import { ReactComponent as ObjectStop } from "../../../assets/object_stop.svg";
import { getColor, getName } from "../../../utils/movingObjectHelper";

const FollowInfo = ({ followedUnit, isFiltered }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(actions.followUnit(null));
  };

  const getFontSize = () => {
    return getName(followedUnit).length > 6 ? "1.0rem" : "1.25rem";
  };

  return (
    <div
      className={"follow-info-panel " + isFiltered}
      onClick={() => {
        handleClick();
      }}
    >
      <Icon baseClassName="material-icons-outlined">
        <ObjectStop
          style={{
            padding: "2.2px 1px 1.5px 0px",
            transform: "scale(6)",
            height: 30,
            width: 30,
            stroke: "#000000",
            fill: getColor(followedUnit),
          }}
        />
      </Icon>
      <Typography
        variant="h6"
        className="follow-title"
        style={{
          fontSize: getFontSize(),
        }}
      >
        {i18n.t("followInfo.title") + " " + getName(followedUnit)}
      </Typography>
      <Typography variant="body2" className="follow-title">
        {i18n.t("followInfo.details")}
      </Typography>
    </div>
  );
};

export default FollowInfo;
