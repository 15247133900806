import moment from "moment";

export const minutesToHoursAndMinutes = (minutes) => {
  const duration = moment.duration(minutes, "minutes");
  const ms = duration.asMilliseconds();
  const d = moment.duration(ms);
  const hoursMins = convertMsToHoursMinutes(ms, d);
  return hoursMins;
};

export const calculateDurationInMs = (startDate, endDate) => {
  const end = moment(endDate);
  const start = moment(startDate);
  const ms = moment(end, "DD.MM.YYYY HH:mm:ss").diff(
    moment(start, "DD.MM.YYYY HH:mm:ss")
  );
  const d = moment.duration(ms);
  return { ms, d };
};

export const convertMsToHoursMinutes = (ms, d) => {
  if (d.asHours() < 10) {
    return `0${Math.floor(d.asHours()) + moment.utc(ms).format(":mm")}`;
  } else {
    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm");
  }
};

/**
 * Round to closest 15 minutes
 * @param {*} dt takes in date object
 * @returns return date object
 */
export const roundToClosest15 = (dt) => {
  const momentObj = moment(dt);
  const dtMoment = moment(momentObj, "DD.MM.YYYY HH:mm:ss");
  const rest = dt.getMinutes() % 15;

  let roundedDt = null;
  if (rest < 8) {
    roundedDt = moment(dtMoment)
      .subtract(rest, "minutes")
      .format("DD.MM.YYYY HH:mm");
  } else {
    roundedDt = moment(dtMoment)
      .add(15 - rest, "minutes")
      .format("DD.MM.YYYY HH:mm");
  }

  return moment(roundedDt, "DD.MM.YYYY HH:mm").toDate();
};

/**
 * Calculate end time based on start time
 * @param {*} dt takes in date object
 * @returns return date object
 */

export const calculateEndTimeByStart = (dt) => {
  const momentObj = moment(dt);
  const dtMoment = moment(momentObj, "DD.MM.YYYY HH:mm")
    .add(15, "minutes")
    .format("DD.MM.YYYY HH:mm");
  return moment(dtMoment, "DD.MM.YYYY HH:mm").toDate();
};

export const getDefaultStartAndEnd = () => {
  const startOfMonth = moment()
    .subtract(3, "months")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment()
    .add(3, "months")
    .endOf("month")
    .format("YYYY-MM-DD");

  return { startOfMonth, endOfMonth };
};

export const formatDt = (time) => {
  if (time) {
    return moment(time).format();
  } else {
    return time;
  }
};
