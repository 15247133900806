import "../index.css";
import i18n from "i18next";
import {
  Box,
  Chip,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ArrivalTrainRow = (props) => {
  const { arrivals, handleArrivals, trains, isEditable, lockedForEdit, menu } =
    props;

  return (
    <div id="event-arrival-row" className="rowSmall">
      <Typography className="targetTextTitle2">
        {i18n.t("navigation.eventInfoTargets.trainArrival")}
      </Typography>
      <div id="columnMedium" className="columnLargeRight">
        <FormControl
          sx={{
            m: 0,
            mt: "5px",
            width: {
              xs: "73vw",
              md: "389px",
            },
          }}
        >
          <Select
            id="event-arrival-select"
            multiple
            value={arrivals}
            onChange={handleArrivals}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={menu}
            disabled={!isEditable || !lockedForEdit}
          >
            {trains.length > 0 ? (
              trains.map((train) => (
                <MenuItem key={train} value={train}>
                  {train}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                {i18n.t("navigation.eventInfoTargets.waitingForTrains")}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div id="columnSmallLeft" className="columnSmallLeft">
        <Icon className="trainIconStyleLeft">
          {i18n.t("navigation.eventInfoIcons.train")}
        </Icon>
      </div>
    </div>
  );
};

export default ArrivalTrainRow;
