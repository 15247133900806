import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Navigation from "../../../Navigation/Navigation";
import { Box } from "@mui/material";
import LoadingBackdrop from "../../../../UIKits/LoadingBackdrop";
import ViewChooser from "../index";

const MobileView = (props) => {
  const { drawerWidth } = props;
  const dispatch = useDispatch();
  const [view, setView] = useState("map");
  const siteId = useSelector((state) => state.permissions.selectedSite);

  const showViewAction = (viewToShow) => {
    dispatch(actions.openView(viewToShow));
  };

  return (
    <Box
      id="root-box"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Navigation siteId={siteId} drawerWidth={drawerWidth} />
      <Box
        id="main-box"
        sx={{
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Box
          id="first-main-child"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <ViewChooser
            view={view}
            setView={setView}
            showViewAction={showViewAction}
            siteId={siteId}
          />
        </Box>
      </Box>
      <LoadingBackdrop />
    </Box>
  );
};

export default MobileView;
