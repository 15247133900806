import axiosEvent from "../utils/axiosEvent";

const API_URL = "/site/";
const TRACKS = "/tracks";
const POINTS = "/points";

export const getTracks = async (siteId) => {
  const url = `${API_URL}${siteId}${TRACKS}`;
  return await axiosEvent.get(url);
};

export const getPoints = async (siteId) => {
  const url = `${API_URL}${siteId}${POINTS}`;
  return await axiosEvent.get(url);
};
