import React from "react";
import i18n from "i18next";
import Button from "@mui/material/Button";
import { Box, Icon, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.proxBlue,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  titleContainer: {
    marginLeft: "5px",
  },
  iconsContainer: {
    display: "flex",
  },
  iconButton: {
    marginTop: "8px !important",
    // marginLeft: "-15px !important",
    color: theme.palette.common.white + " !important",
    position: "relative",
    // display: "inline-flex !important",
  },
  firstIcon: {
    color: theme.palette.common.white + " !important",
    marginLeft: "5px",
  },

  title: {
    fontSize: "16px !important",
    textTransform: "uppercase",
    // marginLeft: "7px !important",
    // marginBottom: "5px !important",
    color: theme.palette.common.white + " !important",
  },
}));

const copyIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.copy")}
    </Icon>
  </Box>
);

const lockIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.lock")}
    </Icon>
  </Box>
);

const lockOpenIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.lockOpen")}
    </Icon>
  </Box>
);

const editIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.edit")}
    </Icon>
  </Box>
);

const closeIcon = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 22,
        width: 22,
        height: 20,
      }}
    >
      {i18n.t("navigation.eventInfoIcons.close")}
    </Icon>
  </Box>
);

const EditEventHeader = (props) => {
  const { headerColor, isEditable, lockStatus, editToolTip, showCopy } = props;
  const classes = useStyles();
  return (
    <div
      id="edit-event-header"
      style={{ backgroundColor: headerColor }}
      className={classes.header}
    >
      <div id="title-container" className={classes.titleContainer}>
        <Typography className={classes.title}>
          {i18n.t("navigation.eventInfoEdit.text")}
        </Typography>
      </div>
      <div id="icons-container" className={classes.iconsContainer}>
        <Tooltip title={i18n.t("navigation.eventInfoEdit.copy")}>
          <Button
            id="copy-event-btn"
            classes={{ root: classes.iconButton }}
            onClick={() => {
              props.handleShowCopy(!showCopy);
            }}
            disabled={!isEditable}
          >
            {copyIcon}
          </Button>
        </Tooltip>
        <Button classes={{ root: classes.iconButton }} disabled={true}>
          {lockStatus ? lockIcon : lockOpenIcon}
        </Button>
        <Tooltip title={editToolTip}>
          <Button
            id="lock-event-btn"
            classes={{ root: classes.iconButton }}
            onClick={props.onClickEdit}
            disabled={!isEditable}
          >
            {editIcon}
          </Button>
        </Tooltip>
        <Button
          id="close-form-btn"
          classes={{ root: classes.iconButton }}
          onClick={props.handleClose}
        >
          {closeIcon}
        </Button>
      </div>
    </div>
  );
};

export default EditEventHeader;
