import React from "react";
import { useSelector } from "react-redux";

import TrackRow from "./TrackRow";
// PRX-154 - Point features disabled for now
// import PointRow from "./PointRow";
import ArrivalTrainRow from "./ArrivalTrainRow";
import DepartureTrainRow from "./DepartureTrainRow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EventTargetEdit = (props) => {
  const {
    locationTracks,
    // PRX-154 - Point features disabled for now
    // locationPoints,
    arrivals,
    departures,
    isEditable,
    lockedForEdit,
  } = props;
  const selectedSiteId = useSelector((state) => state.permissions.selectedSite);
  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  const pointLayerData = useSelector((state) => state.map.pointLayerData);

  const trainArrivals = useSelector(
    (state) => state.userinterface.trainArrivals
  );

  const trainDepartures = useSelector(
    (state) => state.userinterface.trainDepartures
  );

  let tracks = [];
  let points = [];
  trackLayerData.features.map((element) => {
    element.properties.siteId === selectedSiteId &&
      element.properties.trackName &&
      tracks.push(element.properties.trackName);
  });
  tracks.sort();

  pointLayerData.features.map((element) => {
    element.properties.siteId === selectedSiteId &&
      element.properties.pointName &&
      points.push(element.properties.pointName);
  });
  points.sort();

  const trainArr = trainArrivals.map((o) => o.trainNumber);
  const trainDep = trainDepartures.map((o) => o.trainNumber);

  const handleTrackChange = (event) => {
    const {
      target: { value },
    } = event;
    props.changeLocationTracks(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleArrivals = (event) => {
    const {
      target: { value },
    } = event;
    props.changeArrivals(typeof value === "string" ? value.split(",") : value);
  };

  const handleDepartures = (event) => {
    const {
      target: { value },
    } = event;
    props.changeDepartures(
      typeof value === "string" ? value.split(",") : value
    );
  };
  // PRX-154 - Point features disabled for now
  // const handlePointChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   props.changeLocationPoints(
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  return (
    <div>
      <TrackRow
        locationTracks={locationTracks}
        handleTrackChange={handleTrackChange}
        tracks={tracks}
        isEditable={isEditable}
        lockedForEdit={lockedForEdit}
        menu={MenuProps}
      />
      <ArrivalTrainRow
        arrivals={arrivals}
        handleArrivals={handleArrivals}
        trains={trainArr}
        isEditable={isEditable}
        lockedForEdit={lockedForEdit}
        menu={MenuProps}
      />
      <DepartureTrainRow
        departures={departures}
        handleDepartures={handleDepartures}
        trains={trainDep}
        isEditable={isEditable}
        lockedForEdit={lockedForEdit}
        menu={MenuProps}
      />
      {/* PRX-154 - Disabled for now 
      <PointRow
        locationPoints={locationPoints}
        handlePointChange={handlePointChange}
        points={points}
        isEditable={isEditable}
        lockedForEdit={lockedForEdit}
        menu={MenuProps}
      /> */}
    </div>
  );
};

export default EventTargetEdit;
