import * as actionTypes from "./actionTypes";

export const addSiteRoles = (roles) => {
  return {
    type: actionTypes.ADD_SITE_ROLES,
    roles: roles,
  };
};

export const setUserOrg = (userOrg) => {
  return {
    type: actionTypes.SET_USER_ORG,
    userOrg: userOrg,
  };
};

export const changeSelectedSite = (selectedSite) => {
  return {
    type: actionTypes.CHANGE_SELECTED_SITE,
    selectedSite: selectedSite,
  };
};

export const setAvailableSites = (availableSites) => {
  return {
    type: actionTypes.AVAILABLE_SITES,
    availableSites: availableSites,
  };
};
