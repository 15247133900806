import React from "react";
import { Router, Route } from "react-router-dom";

import { history } from "../_helpers";
import { authenticationService } from "../_services";
import { PrivateRoute } from "../_components";
import LoginPage from "../LoginPage/LoginPage";
import { HomePage } from "../HomePage";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <PrivateRoute exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgotpassword" component={ForgotPassword} />
        </div>
      </Router>
    );
  }
}

export { Auth };
