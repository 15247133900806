import * as React from "react";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Card,
  CardContent,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "content",
    marginTop: "-20px",
    backgroundColor: theme.palette.common.white,
  },
  panelSummary: {
    marginRight: "0px",
    backgroundColor: theme.palette.common.white,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.white,
      minHeight: "42px !important",
      maxHeight: "42px !important",
    },
  },
  column: {
    display: "table",
    float: "left",
    marginBottom: "-12px",
  },
  accordionDiv: {
    width: "380px",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "20px",
  },
  sendButton: {
    color: "white",
  },
  iconButtonPosition: {
    alignItems: "center",
    margin: "0 !important",
  },
  iconButtonBackground: {
    backgroundColor: theme.palette.common.proxBlue,
    border: "1px solid rgba(128,128,128, 0.1)",
    borderRadius: "5px",
    width: "32px",
    height: "32px",
  },
  formControlRoot: {
    borderRadius: "4px !important",
    border: "none",
    padding: 2,
    marginTop: 10,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    backgroundColor: "rgba(128,128,128, 0.1)",
  },
  inputRootText: {
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
    height: "40px",
    marginRight: "4px !important",
    marginLeft: "8px !important",
    marginTop: "4px !important",
    marginBottom: "10px !important",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none !important",
    },
    "&&:after": {
      borderBottom: "none !important",
    },
  },
  cardItem: {
    width: "406px",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    border: "none !important",
    boxShadow: "none !important",
    marginLeft: "-16px",
    marginBottom: "-16px",
  },
  title: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: theme.palette.common.black,
  },
  smalltitle: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: theme.palette.common.black,
  },
  text: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: theme.palette.common.black,
  },
}));

const chat = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 24,
        width: 50,
        height: 25,
        marginLeft: "-15px",
        marginTop: "-4px",
        marginBottom: "-8px",
      }}
    >
      {i18n.t("navigation.eventInfoIcons.chat")}
    </Icon>
  </Box>
);

const ChangeHistory = (props) => {
  const classes = useStyles();
  const [userMessagesContent, setUserMessagesContent] = React.useState([]);

  React.useEffect(() => {
    let arr = [];
    for (let obj of props.messageList) {
      arr.push({
        time: moment(obj.date),
        value: makeChatItem(obj.date, obj.msg),
      });
    }
    arr
      .sort(function (a, b) {
        return new Date(a.time._d) - new Date(b.time._d);
      })
      .reverse();
    setUserMessagesContent(arr);
  }, [props.messageList]);

  const makeChatItem = (date, value) => {
    return (
      <Card key={date + value} className={classes.cardItem}>
        <CardContent>
          <Typography component={"div"} className={classes.title}>
            {date.toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </Typography>
          <Typography component={"div"} className={classes.smalltitle}>
            {i18n.t("navigation.eventInfoHistory.message") + ": "}
          </Typography>
          <Typography component={"div"} className={classes.text}>
            {value}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const onMessageChange = (event) => {
    props.setMessage(event.target.value);
  };

  const onClicked = () => {
    props.handleCommentSave();
  };

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <div
          id="accordion-create-event-history"
          className={classes.accordionDiv}
        >
          <Accordion>
            <AccordionSummary
              className={classes.panelSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.text}>
                {chat} {i18n.t("navigation.eventInfoHistory.changeHistory")}
              </Typography>
            </AccordionSummary>
            <Stack marginLeft="6px" direction="row" spacing={2}>
              <Box m={1}>
                <TextField
                  id="msg-field"
                  classes={{ root: classes.formControlRoot }}
                  InputProps={{
                    classes: {
                      underline: classes.underline,
                      root: classes.inputRootText,
                    },
                  }}
                  variant="standard"
                  placeholder={i18n.t("navigation.eventInfoHistory.message")}
                  multiline
                  rows={2}
                  value={props.message}
                  onChange={onMessageChange}
                />
              </Box>
              <IconButton
                id="msg-btn"
                color="primary"
                variant="contained"
                className={classes.iconButtonPosition}
                onClick={onClicked}
              >
                <div
                  id="iconButtonBackground"
                  className={classes.iconButtonBackground}
                >
                  <Icon
                    baseClassName="material-icons-outlined"
                    className={classes.sendButton}
                    sx={{
                      transform: "rotate(90deg)",
                    }}
                  >
                    {i18n.t("navigation.eventInfo.send")}
                  </Icon>
                </div>
              </IconButton>
            </Stack>
            <AccordionDetails id="msg-list">
              {userMessagesContent.map((item) => item.value)}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ChangeHistory;
