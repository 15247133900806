import { Flex } from "./Layout";
import SchedulerHeader from "./_components/SchedulerHeader";
import DaySchedule from "./_components/DaySchedule";
import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getTodayPosition } from "../../utils/schedulerUtils";

function SchedulerBody(props) {
  const { showViewAction, dayColumnRef, schedulerRef, onScroll, openview } =
    props;

  // eslint-disable-next-line no-unused-vars
  const selectedSiteId = useSelector((state) => state.permissions.selectedSite);
  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  const eventData = useSelector((state) => state.userinterface.events);
  const [tracks, setTracks] = useState([]);
  const sideBarRef = useRef(null);

  useEffect(() => {
    let trackObj = [];
    trackLayerData.features.map(
      (o) =>
        !o.properties.siteId.startsWith("Informative") &&
        o.properties.trackName &&
        trackObj.push({
          siteId: o.properties.siteId,
          trackName: o.properties.trackName,
        })
    );
    // Make the array contain only unique tracks per site
    trackObj = trackObj.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.siteId === value.siteId && t.trackName === value.trackName
        )
    );
    trackObj.sort((a, b) => {
      let fa = a.trackName.toLowerCase(),
        fb = b.trackName.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setTracks(trackObj);
  }, [eventData]);

  return (
    <Flex
      direction="row"
      style={{ flex: 1, width: "100%", height: "100%", position: "relative" }}
    >
      <DaySchedule
        events={eventData}
        sideBarRef={sideBarRef}
        onScroll={onScroll}
        schedulerRef={schedulerRef}
        myTracks={tracks}
        dayColumnRef={dayColumnRef}
        openview={openview}
        showViewAction={showViewAction}
        {...props}
      />
    </Flex>
  );
}

const schedulerStyles = {
  width: "100%",
  height: "100%",
};

export default function Scheduler({ showViewAction, openview, schedulerRef }) {
  // const schedulerRef = useRef(null);
  const dayColumnRef = useRef(null);
  // const [isFullScreen, setIsFullScreen] = useState(true);

  const onScroll = () => {
    schedulerRef.current.scrollLeft = dayColumnRef.current.scrollLeft;
  };

  function executeScroll() {
    if (dayColumnRef.current) {
      dayColumnRef.current.scrollLeft = getTodayPosition();
    }
  }

  return (
    <Flex style={schedulerStyles}>
      <SchedulerHeader
        dayColumnRef={dayColumnRef}
        onScroll={onScroll}
        executeScroll={executeScroll}
        openview={openview}
        onChangeView={showViewAction}
      />

      <SchedulerBody
        showViewAction={showViewAction}
        dayColumnRef={dayColumnRef}
        schedulerRef={schedulerRef}
        openview={openview}
        onScroll={onScroll}
      />
    </Flex>
  );
}
