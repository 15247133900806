import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import DateTimePicker from "../DateTimePickerEdit/DateTimePickerEdit";
import { updateSingleEvent } from "../../../../../../store/actions";
import {
  formatDt,
  calculateDurationInMs,
  convertMsToHoursMinutes,
} from "../../../../../../utils/times";
import { postEvents } from "../../../../../../services/events";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  column: {
    display: "table",
  },
  panelSummary: {
    marginRight: "0px",
    backgroundColor: theme.palette.common.white,
    minHeight: "42px !important",
    maxHeight: "42px !important",
    "&.Mui-expanded": {
      backgroundColor: theme.palette.common.white,
      minHeight: "42px !important",
      maxHeight: "42px !important",
    },
  },
  accordionDiv: {
    width: "380px",
  },
  text: {
    ...theme.typography,
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color: theme.palette.common.black,
  },
  textLabel: {
    ...theme.typography,
    fontSize: "14px !important",
    fontWeight: "400 !important",
    marginBottom: "15px !important",
    color: theme.palette.common.black,
  },
  copyButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
}));

const copy = (
  <Box component="span">
    <Icon
      baseClassName="material-icons-outlined"
      sx={{
        textAlign: "center",
        fontSize: 24,
        width: 50,
        height: 25,
        marginLeft: "-15px",
        marginTop: "-4px",
        marginBottom: "-8px",
      }}
    >
      {i18n.t("navigation.eventInfoIcons.copy")}
    </Icon>
  </Box>
);

const EventCopyEdit = (props) => {
  const { startDate, endDate } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [startTime, setStartTime] = React.useState(startDate);
  const [endTime, setEndTime] = React.useState(endDate);
  const [duration, setDuration] = React.useState({
    apiFormat: 0,
    uiFormat: "00:00",
  });
  const [validDuration, setValidDuration] = React.useState(true);
  const [isForNow, setIsForNow] = React.useState(false);

  React.useEffect(() => {
    if (
      moment(startTime).isAfter(endTime) ||
      moment(endTime).isBefore(startTime)
    ) {
      // props.setHidden(false);
    } else {
      // props.setHidden(true);
      if (!isForNow && endTime) {
        const { ms, d } = calculateDurationInMs(startTime, endTime);
        const hoursMins = convertMsToHoursMinutes(ms, d);
        if (
          duration.uiFormat.includes(":") &&
          duration.uiFormat.split(":")[1].length == 2
        ) {
          setDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
          setValidDuration(true);
        }
      }
    }
  }, [endTime, startTime]);

  const check = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName].length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleSaving = () => {
    const cleanedPhases = props.cleanPhases(props.phases);
    const eventObj = {
      eventState: props.eventState,
      duration: duration.apiFormat,
      locationTracks: props.locationTracksData,
      startTime: formatDt(startTime),
      isForNow: isForNow,
      criticality: props.criticality,
      shortDescription: props.shortDescription,
      description: props.description,
      arrivals: props.arrivalsIds,
      departures: props.departuresIds,
      locationPoints: props.locationPointsData,
      phases: cleanedPhases,
      endTime: isForNow ? null : formatDt(endTime),
      restriction: props.restriction,
      eventType: props.eventPicker,
    };

    const request = postEvents(props.siteId, check(eventObj));
    request
      .then((response) => {
        props.setSeverity("success");
        props.setText(i18n.t("notifications.savedSuccessfully"));
        dispatch(updateSingleEvent(response.data));
        props.setShowCopy(false);
      })
      .catch((error) => {
        props.setSeverity("error");
        if (error.response.status === 403) {
          props.setText(i18n.t("notifications.savedFailedRights"));
        } else if (error.response.status === 400) {
          props.setText(
            i18n.t("notifications.savedFailedContent") +
              i18n.t("notifications." + error.response.data[0].name)
          );
        } else {
          props.setText(i18n.t("notifications.savedFailed"));
        }
        console.log("Erro error" + error);
      });
  };

  return (
    <div className={classes.componentContainer}>
      <div id="event-copy-column" className={classes.column}>
        <div id="event-copy-accordion-div" className={classes.accordionDiv}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              className={classes.panelSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.text}>
                {copy} {i18n.t("navigation.eventCopy.copy")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.textLabel}>
                {i18n.t("navigation.eventCopy.copyInfo")}
              </Typography>
              <DateTimePicker
                id="copy-event-date-picker"
                startTime={startTime}
                handleChangeStart={setStartTime}
                endTime={endTime}
                handleChangeEnd={setEndTime}
                handleIsForNow={setIsForNow}
                isForNow={isForNow}
                duration={duration}
                onDurationChange={setDuration}
                isEditable={true}
                lockedForEdit={true}
                validDuration={validDuration}
                setValidDuration={setValidDuration}
              />
              <Button
                id="save-copy-event-btn"
                className={classes.copyButton}
                style={{ width: "140px" }}
                variant="contained"
                color="primary"
                component="span"
                onClick={handleSaving}
              >
                {copy} {i18n.t("navigation.eventCopy.createCopy")}
              </Button>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default EventCopyEdit;
