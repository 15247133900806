import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import logo from "../../assets/latausanimaatio.gif";
import { useSelector } from "react-redux";

export default function LoadingBackdrop() {
  const loading = useSelector((state) => state.userinterface.loading);
  const handleClose = () => {
    // to hide backdrop call:
    // dispatch(actions.setAppLoading(false));
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(loading)}
        onClick={handleClose}
      >
        <img src={logo} alt="loading..." />
      </Backdrop>
    </div>
  );
}
