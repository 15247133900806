import React from "react";
import i18n from "i18next";
import "./index.css";
import { Flex } from "../../../../../../Layout";
import { COLUMN_WIDTH } from "../../../../../../../../constants";

const Column = React.forwardRef(function DayColumn(
  { day, date, month, year, selected, addSelected, ...rest },
  ref
) {
  const handleClick = (dt) => {
    addSelected(dt);
  };

  const Hours = React.useCallback(() => {
    let x = [];
    for (let i = 0; i <= 24; i++) {
      if (i % 2 !== 0) {
        const dateTime =
          i < 10
            ? `${dateString}.${monthString}.${year} 0${i}:00`
            : `${dateString}.${monthString}.${year} ${i}:00`;

        x.push(
          <span
            key={i}
            // className="hour-label"
            style={{
              color: selected.includes(dateTime) ? "red" : "grey",
              fontWeight: selected.includes(dateTime) ? 700 : "normal",
            }}
            onClick={() => handleClick(dateTime)}
          >
            {i < 10 ? "0" + i : i}
          </span>
        );
      } else {
        x.push(<span key={i} className="hour-label"></span>);
      }
    }

    return x;
  }, [selected]);

  const dateString = React.useMemo(() => {
    return date < 10 ? "0" + date : date;
  }, [date]);
  const monthString = React.useMemo(() => {
    return month < 10 ? "0" + month : month;
  }, [month]);

  return (
    <Flex
      key={`${day}_${date}_${month}`}
      ref={ref}
      justify="flex-start"
      self="stretch"
      className="column"
      style={{ flex: "0 0 " + (COLUMN_WIDTH - 1) + "px" }}
      {...rest}
    >
      <span className="day-label">
        {i18n.t(`schedulerPage.daySymbols.${day}`)} {dateString}.{monthString}.
      </span>
      <Flex direction="row" justify="space-between" className="hours-cont">
        <Hours />
      </Flex>
    </Flex>
  );
});

export default Column;
