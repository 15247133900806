import { useState } from "react";
import { AppBar, Box, Drawer, IconButton, Toolbar } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import NavigationItems from "./NavigationItems";
import ProXlogo from "../../../assets/prox_x.svg";

export default function Navigation(props) {
  const { window, drawerWidth } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {/* AppBar visible only on mobile */}
      <AppBar
        id="app-bar"
        position="static"
        color="secondary"
        sx={{
          display: { sm: "flex", md: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box
            id="mobile-image-div"
            sx={{ mx: "auto", width: { xs: "1.5rem", sm: "2rem" } }}
          >
            <img alt="logo" src={ProXlogo} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        id="nav-box"
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation"
      >
        {/* Mobile navigation */}
        <Drawer
          id="mobile-drawer"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <NavigationItems />
        </Drawer>
        {/* Desktop navigation */}
        <Drawer
          id="desktop-drawer"
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box
            id="desktop-image-div"
            sx={{ mx: "auto", mt: "1.5rem", width: "3rem" }}
          >
            <img alt="logo" src={ProXlogo} />
          </Box>
          <NavigationItems />
        </Drawer>
      </Box>
    </>
  );
}
