import i18n from "i18next";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    width: "100%",
    marginTop: "0px",
    backgroundColor: theme.palette.common.white,
  },
  column: {
    display: "table",
    marginBottom: "-12px",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "93vw",
    },
    marginTop: "12px",
    marginBottom: "18px",
  },
  eventTitle: {
    ...theme.typography,
    fontSize: "12px !important",
    fontWeight: 500,
    marginBottom: "-2px !important",
    color: theme.palette.common.black,
  },
  selected: {
    backgroundColor: "rgba(0, 154, 225, 0.08)",
    color: "rgba(0, 154, 225, 1.0)",
  },
  notSelected: {
    backgroundColor: "white",
    borderColor: "rgba(128,128,128, 0.5)",
    color: "black",
  },
}));

const EventPicker = (props) => {
  const classes = useStyles();
  const { eventPicker } = props;
  const handleClick = (item) => {
    props.handleChange(item);
  };

  let trackCapacityReservationStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let serviceLocationReservationStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let alarmStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let maintenanceStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  let otherStyle = {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(128,128,128, 0.3)",
    color: "black",
    paddingBottom: "2px",
  };
  if (eventPicker === "trackcapacityreservation") {
    trackCapacityReservationStyle = {
      backgroundColor: "rgba(89, 168, 99, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (eventPicker === "servicelocationreservation") {
    serviceLocationReservationStyle = {
      backgroundColor: "rgba(179, 64, 255, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (eventPicker === "alarm") {
    alarmStyle = {
      backgroundColor: "rgba(255, 0, 0, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (eventPicker === "maintenance") {
    maintenanceStyle = {
      backgroundColor: "rgba(241, 177, 67, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }
  if (eventPicker === "other") {
    otherStyle = {
      backgroundColor: "rgba(149, 149, 149, 1.0)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(128,128,128, 0.3)",
      color: "rgba(255, 255, 255, 1.0)",
      paddingBottom: "2px",
    };
  }

  return (
    <div className={classes.componentContainer}>
      <div id="column" className={classes.column}>
        <Typography className={classes.eventTitle}>
          {i18n.t("navigation.eventInfoChips.eventType")}
        </Typography>
        <div className={classes.chipContainer}>
          <Stack spacing={1} alignItems="left">
            <Stack direction="row" spacing={1}>
              <Chip
                id="trackcapacityreservation-chip"
                style={trackCapacityReservationStyle}
                label={i18n.t(
                  "navigation.eventInfoChips.trackcapacityreservation"
                )}
                onClick={() => handleClick("trackcapacityreservation")}
              />
              <Chip
                id="servicelocationreservation-chip"
                style={serviceLocationReservationStyle}
                label={i18n.t(
                  "navigation.eventInfoChips.servicelocationreservation"
                )}
                onClick={() => handleClick("servicelocationreservation")}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <Chip
                id="maintenance-chip"
                style={maintenanceStyle}
                label={i18n.t("navigation.eventInfoChips.maintenance")}
                onClick={() => handleClick("maintenance")}
              />
              <Chip
                id="alarm-chip"
                style={alarmStyle}
                label={i18n.t("navigation.eventInfoChips.alarm")}
                onClick={() => handleClick("alarm")}
              />
              <Chip
                id="other-chip"
                style={otherStyle}
                label={i18n.t("navigation.eventInfoChips.other")}
                onClick={() => handleClick("other")}
              />
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default EventPicker;
