import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import i18n from "i18next";
import { Auth } from "./components/Auth/Auth";
import theme from "./components/UI/Theme";
import FallBack from "./components/FallBack/FallBack";
import * as Sentry from "@sentry/react";
import "./App.css";

const myFallback = <FallBack />;

const App = () => {
  useEffect(() => {
    document.title = i18n.t("appTitle");
  }, []);
  return (
    <Sentry.ErrorBoundary fallback={myFallback}>
      <ThemeProvider theme={theme}>
        <Auth />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
