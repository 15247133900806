import { createTheme } from "@mui/material/styles";

const proxFonts = ["'Titillium Web'", "Tahoma", "sans-serif"];
const proxBlue = "rgb(0, 159, 218)";
const proxBlueTransparent = "rgb(0, 159, 218, 0.25)";
const proxGrey = "rgb(96, 96, 96)";
const proxGreen = "#73AD21";
const proxBorderGrey = "rgb(211, 211, 211)";
const proxOrange = "#f0ad4e";
const greyText = "#949494";
const greyBackground = "#E0E0E0";
const customBlue = "rgba(0, 154, 225, 1)";
const customLightBlue = "#49C2F1";
const customWhite = "#FFFFFF";
const customRed = "rgba(199, 63, 0, 1)";
const customRedTransparent = "rgba(199, 63, 0, 0.25)";
const customYellow = "rgba(255, 195, 0, 1)";
const customGreen = "rgba(141, 203, 109, 1)";
const customBlack = "#000000";
const buttonInactive = "#616365";

const theme = createTheme({
  components: {
    MuiListItemButton: {
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      ],
    },
    MuiListItemText: {
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            fontSize: "0.75rem",
            color: `${proxGrey}`,
            "&.navItemActive": { color: `${proxBlue}` },
            lineHeight: 1,
            textTransform: "uppercase",
          },
        },
      ],
    },
    MuiIcon: {
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            fontSize: "3.5rem",
            color: `${proxGrey}`,
            "&.navItemActive": { color: `${proxBlue}` },
            marginLeft: "auto",
            marginRight: "auto",
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    common: {
      buttonInactive: `${buttonInactive}`,
      proxBlue: `${proxBlue}`,
      proxGrey: `${proxGrey}`,
      proxGreen: `${proxGreen}`,
      proxBorderGrey: `${proxBorderGrey}`,
      proxOrange: `${proxOrange}`,
      greyTXT: `${greyText}`,
      greyBG: `${greyBackground}`,
      blue: `${customBlue}`,
      lightBlue: `${customLightBlue}`,
      white: `${customWhite}`,
      red: `${customRed}`,
      yellow: `${customYellow}`,
      green: `${customGreen}`,
      black: `${customBlack}`,
    },
    transparent: {
      proxBlue: `${proxBlueTransparent}`,
      red: `${customRedTransparent}`,
    },
    primary: {
      main: `${customBlue}`,
    },
    secondary: {
      main: `${customWhite}`,
    },
  },
  typography: {
    fontFamily: proxFonts.join(","),
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    tab: {
      fontSize: "1.25rem",
      lineHeight: 1.6,
    },
    filter: {
      fontFamily: proxFonts.join(","),
      fontWeight: 700,
      fontSize: "0.875rem",
    },
  },
  drawer: {
    paper: {
      background: `${customBlue}`,
    },
  },
  chip: {
    severity: {
      color: `${customWhite}`,
      opacity: 0.15,
      marginTop: "5px",
    },
    state: {
      backgroundColor: `${customWhite}`,
      marginTop: "5px",
      "&:hover, &:focus": {
        backgroundColor: `${customBlue}`,
        opacity: 0.15,
        cursor: "pointer",
      },
    },
  },
  issueSeverity: {
    color: `${customWhite}`,
    margin: "1px 1px 1px",
  },
});

export default theme;
