import { BehaviorSubject } from "rxjs";
import { handleResponse, history } from "../_helpers";
import jwt_decode from "jwt-decode";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const auth0UuidSubject = new BehaviorSubject(localStorage.getItem("auth0Uuid"));

export const authenticationService = {
  login,
  logout,
  forgotPassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  auth0Uuid: auth0UuidSubject.asObservable(),
  get auth0UuidValue() {
    return auth0UuidSubject.value;
  },
};

function login(email, password, remember) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${process.env.REACT_APP_LOGIN_URL}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (remember) {
        localStorage.setItem("currentUser", JSON.stringify(user));
      }
      var decoded = jwt_decode(user.token);
      var auth0Uuid = decoded.sub.split("|")[1];
      localStorage.setItem("auth0Uuid", auth0Uuid);
      currentUserSubject.next(user);
      auth0UuidSubject.next(auth0Uuid);
      return user;
    });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  localStorage.removeItem("auth0Uuid");
  auth0UuidSubject.next(null);
  history.push("/login");
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${process.env.REACT_APP_FORGOT_PASSWORD_URL}`,
    requestOptions
  ).then((response) => response.json());
}
