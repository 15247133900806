import React, { useState, useRef } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Navigation from "../../../Navigation/Navigation";
import logo from "../../../../../assets/proxion_x_RGB.png";

import Scheduler from "../../../../Scheduler";
import SideMenu from "./SideMenu";
import DefaultView from "./DefaultView";
import HalfSchedulerMap from "./HalfSchedulerMap";
import LoadingBackdrop from "../../../../UIKits/LoadingBackdrop";

const DesktopView = (props) => {
  const { drawerWidth } = props;
  const dispatch = useDispatch();
  const schedulerRef = useRef(null);
  const [view, setView] = useState("map");
  const openview = useSelector((state) => state.userinterface.openview);
  const followedUnit = useSelector((s) => s.map.followUnit);
  const isFiltered = useSelector((state) => state.userinterface.isFiltered);
  const startFilter = useSelector((state) => state.userinterface.startFilter);
  const endFilter = useSelector((state) => state.userinterface.endFilter);
  const siteId = useSelector((state) => state.permissions.selectedSite);

  const contentOverrideEvents = {
    width: "calc(100% - 110px)",
  };

  const contentOverrideEventsBar = {
    width: "calc(100% - 550px)",
  };

  const contentOverrideHalfEvents = {
    width: "100%",
  };

  const contentOverrideHalfEventsBar = {
    width: "calc(100% - 440px)",
  };

  const showViewAction = (viewToShow) => {
    dispatch(actions.openView(viewToShow));
  };

  return (
    <div id="main-content" className="main">
      <div id="nav-bar" className="navigation">
        <img alt="Logo" src={logo} className="logo" />
        <Navigation siteId={siteId} drawerWidth={drawerWidth} />
      </div>
      <SideMenu view={view} setView={setView} showViewAction={showViewAction} />
      {["map", "movingObjects"].includes(openview) && (
        <DefaultView
          openview={openview}
          siteId={siteId}
          startFilter={startFilter}
          endFilter={endFilter}
          isFiltered={isFiltered}
          showViewAction={showViewAction}
          followedUnit={followedUnit}
        />
      )}

      {[
        "events",
        "eventInfoEditFull",
        "eventInfoCreateFull",
        "filterListFull",
        "eventListFull",
      ].includes(openview) && (
        <div
          className="content"
          style={
            openview === "events"
              ? contentOverrideEvents
              : contentOverrideEventsBar
          }
        >
          <Scheduler
            showViewAction={showViewAction}
            openview={openview}
            schedulerRef={schedulerRef}
          />
        </div>
      )}

      {[
        "halfevents",
        "eventInfoEditHalf",
        "eventInfoCreateHalf",
        "filterListHalf",
        "eventListHalf",
      ].includes(openview) && (
        <div
          className="content"
          style={
            openview === "halfevents"
              ? contentOverrideHalfEvents
              : contentOverrideHalfEventsBar
          }
        >
          <HalfSchedulerMap
            schedulerRef={schedulerRef}
            openview={openview}
            siteId={siteId}
            showViewAction={showViewAction}
          />
        </div>
      )}
      <LoadingBackdrop />
    </div>
  );
};

export default DesktopView;
