import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedEventId,
  updateEventComments,
  updateSingleEvent,
} from "../../../../store/actions";
import i18n from "i18next";
import Button from "@mui/material/Button";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditEventHeader from "./EventInfoEdit/EditEventHeader/EditEventHeader";
import EventState from "./EventInfoEdit/EventStateEdit/EventStateEdit";
import DateTimePicker from "./EventInfoEdit/DateTimePickerEdit/DateTimePickerEdit";
import EventCopy from "./EventInfoEdit/EventCopyEdit/EventCopyEdit";
import EventPicker from "./EventInfoEdit/EventPickerEdit/EventPickerEdit";
import EventDescription from "./EventInfoEdit/DescriptionsEdit/EventDescriptionEdit";
import SeverityPicker from "./EventInfoEdit/SeverityPickerEdit/SeverityPickerEdit";
import EventTarget from "./EventInfoEdit/EventTargetEdit";
import EventOrgFields from "./EventInfoEdit/EventOrgFieldsEdit/EventOrgFieldsEdit";
// PRX-166
// import EventPhases from "./EventInfoEdit/EventPhasesEdit/EventPhasesEdit";
import moment from "moment";
import {
  deleteEvent,
  editEvents,
  lockEvent,
  postEventMessage,
  getEventMessages,
  getEventHistory,
  getOrgFields,
} from "../../../../services/events";
import Notification from "./Notification";
import {
  calculateEndTimeByStart,
  minutesToHoursAndMinutes,
  calculateDurationInMs,
  convertMsToHoursMinutes,
  formatDt,
} from "../../../../utils/times";
import { authenticationService } from "../../../../components/Auth/_services";
import {
  showEditEvent,
  showDeleteEvent,
  showEventHistoryAndMessages,
} from "../../../../utils/licenses";

import { findTrainId } from "../../../../utils/trains";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    marginTop: "0px",
  },
  alertBox: {
    height: "47px",
    maxWidth: "390px",
    width: "100vw",
    [theme.breakpoints.down("md")]: {
      width: "85vw",
    },
    marginBottom: "3px",
    backgroundColor: "transparent",
    marginTop: "-18px",
    zIndex: "5",
  },
  alertText: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
  },
  iconButton: {
    marginTop: "8px !important",
    marginLeft: "-15px !important",
    color: theme.palette.common.white + " !important",
    display: "inline-flex !important",
  },
  header: {
    display: "contents",
    float: "left",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  headerButtons: {
    display: "contents",
    marginBottom: "-2px",
    marginTop: "8px",
  },
  headerPart: {
    display: "contents",
  },
  dividerLarge: {
    display: "inline-block",
    width: "53px !important",
  },
  dividerSmall: {
    display: "inline-block",
    width: "0px !important",
    marginLeft: "-20px",
  },
  title: {
    fontSize: "16px !important",
    marginLeft: "15px !important",
    marginBottom: "5px !important",
  },
  column: {
    display: "inherit",
    marginTop: "6px",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.common.white,
    padding: "8px",
    paddingBottom: "10vh",
    transition: "opacity 1s",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100vh",
  },
  row: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    padding: "10px",
    fontSize: "22px",
  },
  rowNoBorders: {
    backgroundColor: theme.palette.common.white,
    padding: "10px",
    fontSize: "22px",
  },
  rowLocationBased: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(128,128,128, 0.1)",
    padding: "10px",
    fontSize: "22px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "22px",
  },
  saveButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  removeButton: {
    marginTop: "16px",
    marginBottom: "16px",
    marginLeft: "16px",
  },
  buttonRow: {
    backgroundColor: theme.palette.common.white,
    // This should be same as mobile navigation bar height
    marginBottom: "56px",
    padding: "10px",
    fontSize: "22px",
  },
  alertDiv: {
    marginTop: "20px",
  },
}));

const EventInfo = (props) => {
  const { openview, orgId } = props;
  const eventId = useSelector((state) => state.userinterface.eventId);
  const classes = useStyles();
  const theme = useTheme();
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const [siteId, setSiteId] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [headerColor, setHeaderColor] = useState("rgb(0, 159, 218)");
  const [lockStatus, setLockStatus] = useState(false);
  const [lockedForEdit, setLockedForEdit] = useState(false);
  const [editToolTip, setEditToolTip] = useState(
    i18n.t("navigation.eventInfoEdit.openToEdit")
  );
  const [saveToolTip, setSaveToolTip] = useState("");

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [duration, setDuration] = useState({
    apiFormat: 0,
    uiFormat: "00:00",
  });
  const [validDuration, setValidDuration] = useState(true);
  const [eventState, setEventState] = useState("planned");
  const [isForNow, setIsForNow] = useState(false);
  const [eventPicker, setEventPicker] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [criticality, setCriticality] = useState("");
  const [message, setMessage] = useState("");
  const [pastMessages, setPastMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [pastMessagesArray, setPastMessagesArray] = useState([]);
  const [restriction, setRestriction] = useState("");
  // const [phases, setPhases] = useState([]);
  // const [phasesDuration, setPhasesDuration] = useState(0);
  const [locationTracks, setLocationTracks] = useState([]);
  const [locationPoints, setLocationPoints] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [arrivalsIds, setArrivalsIds] = useState([]);
  const [departures, setDepartures] = useState([]);
  const [departuresIds, setDeparturesIds] = useState([]);
  // const [showWarning, setShowWarning] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [severity, setSeverity] = useState("");
  const [autoClose, setAutoClose] = useState(true);
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [orgFields, setOrgFields] = useState([]);
  const [customValues, setCustomValues] = useState([]);
  const [customValuesInput, setCustomValuesInput] = useState([]);
  const events = useSelector((state) => state.userinterface.events);
  const trackLayerData = useSelector((state) => state.map.trackLayerData);
  const pointLayerData = useSelector((state) => state.map.pointLayerData);
  const trainArrivals = useSelector(
    (state) => state.userinterface.trainArrivals
  );
  const trainDepartures = useSelector(
    (state) => state.userinterface.trainDepartures
  );
  const roles = useSelector((state) => state.permissions.roles);

  const handleClose = () => {
    resetForm();
    if (openview === "eventInfoEditFull") {
      props.showView("events");
    }
    if (openview === "eventInfoEditHalf") {
      props.showView("halfevents");
    }
    dispatch(setSelectedEventId(undefined));
  };
  let trackFilteredData = [];
  let locationTracksData = [];
  let pointFilteredData = [];
  let locationPointsData = [];

  const resetForm = () => {
    setHeaderColor("rgb(0, 159, 218)");
    setLockStatus(false);
    setLockedForEdit(false);
    setEditToolTip(i18n.t("navigation.eventInfoEdit.openToEdit"));
    setStartTime(new Date());
    setEndTime(new Date());
    setEventPicker("");
    setCriticality("stop");
    setDescription("");
    setShortDescription("");
    setMessage("");
    setRestriction("");
    setLocationTracks([]);
    setLocationPoints([]);
    setArrivals([]);
    setArrivalsIds([]);
    setDepartures([]);
    setDeparturesIds([]);
    // setPhases([]);
    setIsForNow(false);
    setDisabled(false);
    setShowCopy(false);
    setDeleteDisabled(true);
    setPastMessagesArray([]);
    // setShowWarning(false);
    // setPhasesDuration(0);
    setDuration({
      apiFormat: 0,
      uiFormat: "0:00",
    });
  };

  for (let i = 0; i < locationTracks.length; i++) {
    trackFilteredData.push(
      trackLayerData.features.filter(function (k) {
        if (k.properties.siteId === siteId) {
          return k.properties.trackName === locationTracks[i].toString();
        }
      })
    );
  }
  for (let i = 0; i < trackFilteredData.length; i++) {
    locationTracksData.push(trackFilteredData[i][0].properties.uuid);
  }
  for (let i = 0; i < locationPoints.length; i++) {
    pointFilteredData.push(
      pointLayerData.features.filter(function (k) {
        if (k.properties.siteId === siteId) {
          return k.properties.pointName === locationPoints[i].toString();
        }
      })
    );
  }
  for (let i = 0; i < pointFilteredData.length; i++) {
    locationPointsData.push(pointFilteredData[i][0].properties.uuid);
  }

  const cleanPhases = (eventPhases) => {
    const copyPhases = [...eventPhases];
    copyPhases.map((phase) => {
      delete phase["createdDate"];
      delete phase["createdUser"];
      delete phase["modifiedDate"];
      delete phase["modifiedUser"];
    });

    return copyPhases;
  };
  // PRX-166
  // const calculatePhasesDurations = (phaseArr) => {
  //   let sum = 0;
  //   phaseArr.forEach((phase) => {
  //     sum = sum + phase.duration;
  //   });

  //   return sum;
  // };

  useEffect(() => {
    if (eventId !== undefined) {
      const index = events.map((e) => e.uuid).indexOf(eventId);
      const event = events[index];
      if (event !== undefined) {
        if (event.lockedUntil !== null) {
          if (moment(new Date(event.lockedUntil)).isAfter(moment(new Date()))) {
            setLockStatus(true);
            if (event.lockedUser === authenticationService.auth0UuidValue) {
              setLockedForEdit(true);
              setHeaderColor("rgb(0, 159, 218)");
              setEditToolTip(i18n.t("navigation.eventInfoEdit.releaseLock"));
            } else {
              setLockedForEdit(false);
              setHeaderColor("grey");
              setEditToolTip(i18n.t("navigation.eventInfoEdit.editDisabled"));
            }
          } else {
            setLockedForEdit(false);
            setLockStatus(false);
          }
        } else {
          setLockedForEdit(false);
          setLockStatus(false);
          setHeaderColor("rgb(0, 159, 218)");
          setEditToolTip(i18n.t("navigation.eventInfoEdit.openToEdit"));
        }
        setIsEditable(
          roles[event.siteId] !== undefined &&
            showEditEvent(roles[event.siteId])
        );
        setSiteId(event.siteId);
        setEventState(event.eventState);
        setDescription(event.description !== null ? event.description : "");
        setIsForNow(event.isForNow);
        setShortDescription(
          event.shortDescription !== null ? event.shortDescription : ""
        );
        setCriticality(event.criticality);
        setRestriction(event.restriction !== null ? event.restriction : "");
        // PRX-166
        // setPhases(event.phases);
        // setPhases([]);
        setStartTime(new Date(event.startTime));
        if (event.isForNow) {
          setEndTime("");
          setDuration({ apiFormat: 0, uiFormat: "0:00" });
        } else {
          setEndTime(new Date(event.endTime));
          const hoursMins = minutesToHoursAndMinutes(event.duration);
          setDuration({ apiFormat: event.duration, uiFormat: hoursMins });
        }
        setEventPicker(event.eventType);
        const locationTracks = event.locationTracks.map((o) => {
          return o.trackName;
        });
        const locationPoints = event.locationPoints.map((o) => {
          return o.pointName;
        });
        const arrivals = event.arrivals.map((o) => {
          return o.trainNumber;
        });

        const departures = event.departures.map((o) => {
          return o.trainNumber;
        });

        const arrivalIds = event.arrivals.map((o) => {
          return o.uuid;
        });

        const departureIds = event.departures.map((o) => {
          return o.uuid;
        });

        setArrivalsIds(arrivalIds);
        setDeparturesIds(departureIds);
        setDepartures(departures);
        setArrivals(arrivals);

        setLocationTracks(locationTracks);
        setLocationPoints(locationPoints);

        if (
          roles[event.siteId] !== undefined &&
          showEventHistoryAndMessages(roles[event.siteId])
        ) {
          const requestMsg = getEventMessages(event.siteId, eventId);
          requestMsg
            .then((response) => {
              setPastMessages(response.data.comments);
            })
            .catch((error) => {
              console.log("Event messages " + error);
            });
          const requestHst = getEventHistory(event.siteId, eventId);
          requestHst
            .then((response) => {
              let ordered = response.data.history;
              ordered.sort(function compare(a, b) {
                let dateA = new Date(a.createdDate);

                if (a.modifiedDate !== null) {
                  dateA = new Date(a.modifiedDate);
                }

                let dateB = new Date(b.createdDate);

                if (b.modifiedDate !== null) {
                  dateB = new Date(b.modifiedDate);
                }
                return dateA - dateB;
              });

              setHistory(ordered);
            })
            .catch((error) => {
              console.log("Event history " + error);
            });
        }
        const requestOrgFields = getOrgFields(orgId);
        requestOrgFields
          .then((response) => {
            let ordered = response.data.orgfields;
            ordered.sort(function compare(a, b) {
              return a.position - b.position;
            });
            setCustomValues(event.customValues);
            setOrgFields(ordered);
          })
          .catch((error) => {
            console.log("Event OrgFields " + error);
          });
      } else {
        console.error(
          `For some reason could not found event with id: ${eventId}`
        );
      }
    }
  }, [eventId]);

  const editHandler = () => {
    const request = lockEvent(eventId);
    request
      .then((response) => {
        if (response.data.lockedUntil !== null) {
          setLockedForEdit(true);
          setLockStatus(true);
          setHeaderColor("rgb(0, 159, 218)");
          setEditToolTip(i18n.t("navigation.eventInfoEdit.releaseLock"));
          dispatch(updateSingleEvent(response.data));
        } else {
          setLockedForEdit(false);
          setLockStatus(false);
          setHeaderColor("rgb(0, 159, 218)");
          setEditToolTip(i18n.t("navigation.eventInfoEdit.openToEdit"));
          dispatch(updateSingleEvent(response.data));
        }
      })
      .catch((error) => {
        setSeverity("error");
        setText(i18n.t("navigation.eventInfoEdit.editDisabled"));
        console.log("Erro error" + error);
        setHeaderColor("grey");
        setLockStatus(true);
        setLockedForEdit(false);
        setEditToolTip(i18n.t("navigation.eventInfoEdit.editDisabled"));
      });
  };

  const requiredOrgFieldMissing = () => {
    let required = orgFields.filter(function (k) {
      return k.required && k.type !== "checkbox" && k.type !== "switch";
    });

    if (customValuesInput.length < 1 && required.length > 0) {
      return true;
    }

    for (let orgField of required) {
      let vals = customValuesInput.filter(function (k) {
        return (
          k !== undefined && k.fieldId === orgField.uuid && k.value.length > 0
        );
      });
      if (vals.length < 1) return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      startTime === null ||
      (endTime === null && !isForNow) ||
      locationTracks.length === 0 ||
      (criticality === "restricted" && restriction.length === 0) ||
      moment(startTime).isAfter(endTime) ||
      moment(endTime).isBefore(startTime) ||
      (duration.apiFormat < 15 && !isForNow) ||
      (duration.uiFormat.length < 5 && !isForNow) ||
      lockedForEdit === false ||
      eventPicker === "" ||
      requiredOrgFieldMissing()
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    locationTracks,
    criticality,
    restriction,
    startTime,
    endTime,
    duration,
    lockedForEdit,
    eventPicker,
    customValuesInput,
  ]);

  useEffect(() => {
    if (disabled && lockedForEdit) {
      let str = i18n.t("navigation.eventInfoEdit.checkFields");
      if (startTime === null) {
        str = str + i18n.t("navigation.eventInfoEdit.startTime");
      }
      if (endTime === null && !isForNow) {
        str = str + i18n.t("navigation.eventInfoEdit.endTime");
      }
      if (moment(startTime).isAfter(endTime)) {
        str = str + i18n.t("navigation.eventInfoEdit.dates");
      }
      if (
        (duration.apiFormat < 15 && !isForNow) ||
        (duration.uiFormat.length < 5 && !isForNow)
      ) {
        str = str + i18n.t("navigation.eventInfoEdit.duration");
      }
      if (eventPicker === "") {
        str = str + i18n.t("navigation.eventInfoEdit.eventType");
      }
      if (criticality === "restricted" && restriction.length === 0) {
        str = str + i18n.t("navigation.eventInfoEdit.restriction");
      }
      if (locationTracks.length === 0) {
        str = str + i18n.t("navigation.eventInfoEdit.locationTracks");
      }
      if (requiredOrgFieldMissing()) {
        str = str + i18n.t("navigation.eventInfoEdit.customValues");
      }
      str = str.slice(0, -2);
      setSaveToolTip(str);
    } else {
      setSaveToolTip("");
    }
  }, [
    disabled,
    locationTracks,
    criticality,
    restriction,
    startTime,
    endTime,
    duration,
    lockedForEdit,
    eventPicker,
    customValuesInput,
  ]);

  useEffect(() => {
    if (lockedForEdit === false) {
      setDeleteDisabled(true);
    } else {
      setDeleteDisabled(false);
    }
  }, [lockedForEdit]);
  // PRX-166
  // useEffect(() => {
  //   const newDurations = calculatePhasesDurations(phases);
  //   if (!isNaN(newDurations)) {
  //     setPhasesDuration(newDurations);
  //   }
  // }, [phases]);

  const updateDatesIsForNow = () => {
    const endDateObj = calculateEndTimeByStart(startTime);
    setEndTime(endDateObj);
    const { ms, d } = calculateDurationInMs(startTime, endDateObj);
    const hoursMins = convertMsToHoursMinutes(ms, d);
    setDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
  };

  const onChangeStart = (newStartDate) => {
    setStartTime(newStartDate);
    if (
      moment(newStartDate).isAfter(endTime) ||
      moment(endTime).isBefore(newStartDate)
    ) {
      setSeverity("error");
      setText(i18n.t("notifications.checkDates"));
      setAutoClose(false);
    } else {
      setAutoClose(true);
      const { ms, d } = calculateDurationInMs(newStartDate, endTime);
      const hoursMins = convertMsToHoursMinutes(ms, d);
      setDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
      setValidDuration(true);
    }
  };

  const onChangeEnd = (newEndDate) => {
    setEndTime(newEndDate);
    if (
      moment(startTime).isAfter(newEndDate) ||
      moment(newEndDate).isBefore(startTime)
    ) {
      setSeverity("error");
      setText(i18n.t("notifications.checkDates"));
      setAutoClose(false);
    } else {
      setAutoClose(true);
      const { ms, d } = calculateDurationInMs(startTime, newEndDate);
      const hoursMins = convertMsToHoursMinutes(ms, d);
      setDuration({ apiFormat: d.asMinutes(), uiFormat: hoursMins });
      setValidDuration(true);
    }
  };

  const onChangeIsForNow = (newIsForNow) => {
    setIsForNow(newIsForNow);
    if (newIsForNow) {
      setEndTime("");
      setValidDuration(true);
    } else {
      updateDatesIsForNow();
    }
  };

  // useEffect(() => {
  //   if (!isForNow && duration.apiFormat > 0 && phasesDuration > 0) {
  //     if (phasesDuration > duration.apiFormat) {
  //       setShowWarning(true);
  //     } else {
  //       setShowWarning(false);
  //     }
  //   }
  // }, [duration, phasesDuration]);

  const handleArrivalChange = (trainNumbers) => {
    setArrivals(trainNumbers);
    const ids = findTrainId(trainArrivals, trainNumbers);
    setArrivalsIds(ids);
  };

  const handleDepartureChange = (trainNumbers) => {
    setDepartures(trainNumbers);
    const ids = findTrainId(trainDepartures, trainNumbers);
    setDeparturesIds(ids);
  };

  const cleanCustomVals = (customVals) => {
    for (var idx in customVals) {
      if (customVals[idx] !== undefined) {
        if (customVals[idx].uuid === null) {
          delete customVals[idx].uuid;
        }
        if (Array.isArray(customVals[idx].value)) {
          customVals[idx].value = customVals[idx].value
            .toString()
            .replaceAll(", ", ",");
        } else if (typeof customVals[idx].value == "boolean") {
          customVals[idx].value = customVals[idx].value.toString();
        }
      } else {
        delete customVals[idx];
      }
    }
    return customVals.filter((n) => n);
  };

  const check = (obj) => {
    for (var propName in obj) {
      if (obj[propName] !== null) {
        if (isForNow && propName === "endTime" && obj[propName] === "") {
          // When event has isForNow flag we want to override the endTime
          obj[propName] = null;
        } else if (obj[propName] === "" || obj[propName].length === 0) {
          delete obj[propName];
        }
      }
    }
    return obj;
  };

  const handleEventStateChanged = (newState) => {
    if (newState !== null) {
      const eventObj = {
        eventState: newState,
      };

      const request = editEvents(siteId, eventId, eventObj);
      request
        .then((response) => {
          setSeverity("success");
          setText(i18n.t("notifications.savedSuccessfully"));
          dispatch(updateSingleEvent(response.data));
        })
        .catch((error) => {
          setSeverity("error");
          if (error.response.status === 403) {
            setText(i18n.t("notifications.savedFailedRights"));
          } else if (error.response.status === 400) {
            setText(
              i18n.t("notifications.savedFailedContent") +
                i18n.t("notifications." + error.response.data[0].name)
            );
          } else {
            setText(i18n.t("notifications.savedFailed"));
          }
          console.log("Erro error" + error);
        });
    }
  };

  const handleSaving = () => {
    // PRX-166
    // const cleanedPhases = cleanPhases(phases);
    const eventObj = {
      eventState: eventState,
      duration: duration.apiFormat,
      locationTracks: locationTracksData,
      startTime: formatDt(startTime),
      isForNow: isForNow,
      criticality: criticality,
      shortDescription: shortDescription,
      description: description,
      arrivals: arrivalsIds,
      departures: departuresIds,
      locationPoints: locationPointsData,
      // PRX-166
      // phases: cleanedPhases,
      phases: [],
      customValues: cleanCustomVals(customValuesInput),
      endTime: isForNow ? null : formatDt(endTime),
      restriction: restriction,
      eventType: eventPicker,
    };

    setDisabled(true);

    const request = editEvents(siteId, eventId, eventObj);
    request
      .then((response) => {
        setSeverity("success");
        setText(i18n.t("notifications.savedSuccessfully"));
        editHandler();
        dispatch(updateSingleEvent(response.data));
        handleClose();
      })
      .catch((error) => {
        setSeverity("error");
        setDisabled(false);
        if (error.response.status === 403) {
          setText(i18n.t("notifications.savedFailedRights"));
        } else if (error.response.status === 400) {
          setText(
            i18n.t("notifications.savedFailedContent") +
              i18n.t("notifications." + error.response.data[0].name)
          );
        } else {
          setText(i18n.t("notifications.savedFailed"));
        }
        console.log("Erro error" + error);
      });
  };

  const handleCommentSave = () => {
    if (message !== null && message.length > 0) {
      const request = postEventMessage(
        siteId,
        eventId,
        check({ comment: message })
      );
      request
        .then((response) => {
          let comments = [...pastMessages];
          comments.push(response.data);
          const eventComment = {
            comments: comments,
            newComments: 0,
          };
          dispatch(updateEventComments(eventId, eventComment));
          setSeverity("success");
          setText(i18n.t("notifications.savedSuccessfully"));
          setMessage("");
          const requestMsg = getEventMessages(siteId, eventId);
          requestMsg
            .then((response) => {
              setPastMessages(response.data.comments);
            })
            .catch((error) => {
              console.log("Event messages " + error);
            });
        })
        .catch((error) => {
          setSeverity("error");
          setDisabled(false);
          if (error.response.status === 403) {
            setText(i18n.t("notifications.savedFailedRights"));
          } else if (error.response.status === 400) {
            setText(
              i18n.t("notifications.savedFailedContent") +
                i18n.t("notifications." + error.response.data[0].name)
            );
          } else {
            setText(i18n.t("notifications.savedFailed"));
          }
          console.log("Erro error" + error);
        });
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setOpen(false);
    const request = deleteEvent(siteId, eventId);
    request
      .then(() => {
        setSeverity("success");
        setText(i18n.t("notifications.deletedSuccessfully"));
        editHandler();
        handleClose();
      })
      .catch((error) => {
        setSeverity("error");
        if (error.response.status === 403) {
          setText(i18n.t("notifications.deleteFailedRights"));
        } else {
          setText(i18n.t("notifications.deleteFailed"));
        }
        console.log("Erro error" + error);
      });
  };
  return (
    <div id="edit-event-main" className={classes.mainContainer}>
      <EditEventHeader
        headerColor={headerColor}
        isEditable={isEditable}
        lockStatus={lockStatus}
        showCopy={showCopy}
        editToolTip={editToolTip}
        handleClose={handleClose}
        handleShowCopy={setShowCopy}
        onClickEdit={editHandler}
      />
      {/* Event container */}
      <div id="edit-event-container" className={classes.eventContainer}>
        <Box
          id="create-event-column"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
            maxHeight: {
              xs: `calc(${height}px - 56px)`,
              md: `${height}px`,
            },
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Notification
            severity={severity}
            text={text}
            closable
            setSeverity={setSeverity}
            autoClose={autoClose}
          />
          {/* {showWarning && (
            <Notification
              severity="warning"
              text={i18n.t("notifications.durationWarning")}
              closable={false}
              setSeverity={setSeverity}
            />
          )} */}
          {showCopy && (
            <div id="event-copy-row" className={classes.row}>
              <EventCopy
                startDate={startTime}
                endDate={endTime}
                phases={[]}
                cleanPhases={cleanPhases}
                eventState={eventState}
                locationTracksData={locationTracksData}
                criticality={criticality}
                shortDescription={shortDescription}
                description={description}
                arrivalsIds={arrivalsIds}
                departuresIds={departuresIds}
                locationPointsData={locationPointsData}
                restriction={restriction}
                siteId={siteId}
                setSeverity={setSeverity}
                setText={setText}
                setShowCopy={setShowCopy}
                eventPicker={eventPicker}
              />
            </div>
          )}
          <div id="event-status-history-row" className={classes.row}>
            <EventState
              eventState={eventState}
              setEventState={setEventState}
              handleEventStateChanged={handleEventStateChanged}
              message={message}
              setMessage={setMessage}
              pastMessages={pastMessages}
              setPastMessagesArray={setPastMessagesArray}
              pastMessagesArray={pastMessagesArray}
              history={history}
              showHistoryAndMsg={
                roles[siteId] !== undefined &&
                showEventHistoryAndMessages(roles[siteId])
              }
              handleCommentSave={handleCommentSave}
              orgFields={orgFields}
            />
          </div>
          <div id="event-date-row" className={classes.rowNoBorders}>
            <DateTimePicker
              startTime={startTime}
              handleChangeStart={onChangeStart}
              endTime={endTime}
              handleChangeEnd={onChangeEnd}
              handleIsForNow={onChangeIsForNow}
              isForNow={isForNow}
              duration={duration}
              onDurationChange={setDuration}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
              validDuration={validDuration}
              setValidDuration={setValidDuration}
            />
          </div>
          <div
            id="event-picker-description-severity-row"
            className={classes.row}
          >
            <EventPicker
              eventPicker={eventPicker}
              handleChange={setEventPicker}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
            />
            <EventDescription
              description={description}
              shortDescription={shortDescription}
              changeDesc={setDescription}
              changeShortDesc={setShortDescription}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
            />
            <SeverityPicker
              criticality={criticality}
              restriction={restriction}
              changeCriticality={setCriticality}
              changeSeverityDesc={setRestriction}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
            />
          </div>
          <div id="event-target-row" className={classes.row}>
            <EventTarget
              locationTracks={locationTracks}
              changeLocationTracks={setLocationTracks}
              arrivals={arrivals}
              // changeArrivals={setArrivals}
              changeArrivals={handleArrivalChange}
              departures={departures}
              // changeDepartures={setDepartures}
              changeDepartures={handleDepartureChange}
              locationPoints={locationPoints}
              changeLocationPoints={setLocationPoints}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
            />
          </div>
          {/* PRX-166 
          <div id="row" className={classes.rowNoBorders}>
            <EventPhases
              phases={phases}
              addPhase={setPhases}
              updatePhases={setPhases}
              isEditable={isEditable}
              lockedForEdit={lockedForEdit}
            />
          </div> */}
          {orgFields.length > 0 && (
            <div
              id="edit-event-dynamicfields"
              className={classes.rowLocationBased}
            >
              <EventOrgFields
                orgFields={orgFields}
                customValues={customValues}
                lockedForEdit={lockedForEdit}
                customValuesInput={customValuesInput}
                setCustomValuesInput={setCustomValuesInput}
              />
            </div>
          )}
          <div id="edit-event-button-row" className={classes.buttonRow}>
            <div className={classes.buttonContainer}>
              {isEditable && (
                <Tooltip title={saveToolTip}>
                  <span>
                    <Button
                      id="save-event-btn"
                      className={classes.saveButton}
                      style={{ width: "100px" }}
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={handleSaving}
                      disabled={disabled}
                    >
                      {i18n.t("navigation.eventInfoButtons.saveEvent")}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {roles[siteId] !== undefined &&
                showDeleteEvent(roles[siteId]) && (
                  <Button
                    id="delete-event-btn"
                    className={classes.removeButton}
                    style={{ width: "100px" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={handleOpenDialog}
                    disabled={deleteDisabled}
                  >
                    {i18n.t("navigation.eventInfoButtons.removeEvent")}
                  </Button>
                )}
              <Dialog
                id="delete-event-dialog"
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {i18n.t("notifications.deleteText")}
                </DialogTitle>
                <DialogActions>
                  <Button id="accept-delete-btn" onClick={handleDelete}>
                    {i18n.t("confirmationDialog.accept")}
                  </Button>
                  <Button
                    id="decline-delete-btn"
                    onClick={handleCloseDialog}
                    autoFocus
                  >
                    {i18n.t("confirmationDialog.decline")}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default EventInfo;
