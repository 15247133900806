import i18n from "i18next";

import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import "../index.css";

const TrackRow = (props) => {
  const { trackName, handleTrackChange, tracks, menu } = props;

  return (
    <div id="event-track-row" className="rowSmall">
      <Typography className="targetTextTitle1">
        {i18n.t("navigation.eventInfoTargets.track")}
      </Typography>
      <div id="columnLargeRight" className="columnLargeRight">
        <FormControl
          sx={{
            m: 0,
            width: {
              xs: "93vw",
              md: "389px",
            },
          }}
        >
          <Select
            id="event-track-select"
            multiple
            value={trackName}
            onChange={handleTrackChange}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={menu}
          >
            {tracks.map((track) => (
              <MenuItem key={track} value={track}>
                {track}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className="textGrey">
          {i18n.t("navigation.eventInfo.mandatory")}
        </Typography>
      </div>
    </div>
  );
};

export default TrackRow;
