import * as React from "react";
import { useSelector } from "react-redux";
import { Source, Layer } from "react-map-gl";

const pointStyle = {
  id: "pointlayer-points",
  type: "circle",
  paint: {
    "circle-color": "#000000",
    "circle-radius": 4,
  },
};

const labelStyle = {
  id: "pointlayer-labels",
  type: "symbol",
  minzoom: 15,
  filter: ["==", "$type", "Point"],
  layout: {
    "symbol-placement": "point",
    "text-font": ["Noto Sans Bold"],
    "text-field": "{trackName}",
    "text-size": 11,
    "text-variable-anchor": ["left", "bottom", "top"],
    "text-justify": "left",
  },
  //PRX-101 show only black text without halo
  //Note, changed halo from blue / 2.75 to white / 2. this change is commented on JIRA
  paint: {
    "text-color": "#000000", //"#ffffff",
    "text-halo-color": "#49C2F1", //"#0000ff",
    "text-halo-width": 2, //2.75, // max 1/4 of font size
  },
};

const PointLayer = () => {
  const pointLayerData = useSelector((s) => s.map.pointLayerData);

  return (
    <Source id="pointlayer" type="geojson" data={pointLayerData}>
      <Layer {...pointStyle} />
      <Layer {...labelStyle} />
    </Source>
  );
};

export default PointLayer;
