/* eslint-disable no-unused-vars */
import React from "react";
import { authenticationService } from "../_services";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../UI/Theme";
import Layout from "../../UI/Layout/Layout";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
    };
  }

  componentDidMount() {
    //userService.getAll().then(users => this.setState({ users }));
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        <Layout />
      </ThemeProvider>
    );
  }
}

export { HomePage };
