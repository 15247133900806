import * as actionTypes from "./actionTypes";

export const storeStatus = (status) => {
  return {
    type: actionTypes.MQTT_STATUS,
    connectStatus: status,
  };
};

export const storePositionMsg = (position) => {
  return {
    type: actionTypes.MQTT_POSITION,
    position: position,
  };
};
