import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "../../../hooks/useInterval";
import * as actions from "../../../store/actions/index";
import { getEventsForListedSites } from "../../../services/events";
import { getTracks, getPoints } from "../../../services/elements";
import { getTrains } from "../../../services/trains";
import { getSite } from "../../../services/site";
import { allowFetchEvents, allowFecthTracks } from "../../../utils/licenses";
import { getDefaultStartAndEnd } from "../../../utils/times";
import { useMediaQuery, useTheme } from "@mui/material";
import permissionParser from "../../../utils/permissionParser";

import DesktopView from "./ViewChooser/DesktopView";
import MobileView from "./ViewChooser/MobileView";

const drawerWidth = 110;

const Layout = () => {
  const dispatch = useDispatch();
  const [accessToSites, setAccessToSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastEventTimeStamp, setLastEventTimeStamp] = useState(null);
  const [lastTrainTimeStamp, setLastTrainTimeStamp] = useState(null);
  const siteId = useSelector((state) => state.permissions.selectedSite);
  const viewState = useSelector((state) => state.map.viewState);
  const waitTime = process.env.REACT_APP_POLLER_TIME_INTERVAL || 2000;
  const { startOfMonth, endOfMonth } = getDefaultStartAndEnd();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(async () => {
    try {
      // Setting loading to true (this will effect to loading icon)
      dispatch(actions.changeLoadingState(true));
      const user = permissionParser.getUser();
      const email = user?.user?.email;
      Sentry.setUser({ email: email });
      // Fetching initially data and saving to redux
      const obj = permissionParser.parseRoles();
      dispatch(actions.addSiteRoles(obj.roles));
      dispatch(actions.setAvailableSites(obj.sites));
      // Create array of site names from object values
      const roles = obj?.roles;
      const org = obj?.org;
      const sites = Object.values(obj.sites);
      const id = permissionParser.parseProfile();
      const siteName = obj.sites[id];
      dispatch(actions.setUserOrg(org));
      dispatch(actions.changeSelectedSite(siteName));
      await fetchSiteInformation(id);
      await fetchAndDispatchTracks(sites, roles);
      await fetchAndDispatchPoints(sites, roles);
      await initialFetchAndDispatchEvents(sites, roles);
      await fetchTrains(siteName);
    } catch (error) {
      console.error("Initial loading failed");
      console.error(error);
    } finally {
      // Setting loading to false in anycase
      dispatch(actions.changeLoadingState(false));
    }
  }, []);

  // Custom hook which will poll REST API ever x (based to waitTime configuration) seconds
  useInterval(async () => {
    if (!loading && accessToSites.length > 0) {
      await fetchAndDispatchEvents();
    }
  }, waitTime);

  const fetchAndDispatchTracks = async (sites, initRoles) => {
    let newMap = [];
    for (const site of sites) {
      if (initRoles[site] !== undefined && allowFecthTracks(initRoles[site])) {
        try {
          const response = await getTracks(site);
          const tracks = response.data.tracks;
          for (const track of tracks) {
            const feat = {
              type: "feature",
              properties: {
                uuid: track.uuid,
                siteId: track.siteId,
                trackName: track.trackName,
                isElectric: track.isElectric,
                trackLength: track.trackLength,
              },
              geometry: track.trackGeometry,
            };
            newMap.push(feat);
          }
        } catch (err) {
          console.log("Failed to import tracks:", err);
        }
      }
    }
    const geojson = {
      type: "FeatureCollection",
      features: newMap,
    };
    dispatch(actions.setTrackLayerData(geojson));
  };

  const fetchAndDispatchPoints = async (sites, myRoles) => {
    let newMap = [];
    for (const site of sites) {
      if (myRoles[site] !== undefined && allowFecthTracks(myRoles[site])) {
        try {
          const response = await getPoints(site);
          const points = response.data.points;
          for (const point of points) {
            const feat = {
              type: "feature",
              properties: {
                uuid: point.uuid,
                siteId: point.siteId,
                trackName: point.pointName,
              },
              geometry: point.pointGeometry,
            };
            newMap.push(feat);
          }
        } catch (err) {
          console.log("Failed to import points:", err);
        }
      }
    }
    const geojson = {
      type: "FeatureCollection",
      features: newMap,
    };
    dispatch(actions.setPointLayerData(geojson));
  };

  const initialFetchAndDispatchEvents = async (sites, myRoles) => {
    let accessToSites = [];
    for (const site of sites) {
      if (myRoles[site] !== undefined && allowFetchEvents(myRoles[site])) {
        const siteNameLower = site.toLowerCase();
        if (!siteNameLower.startsWith("informative")) {
          accessToSites.push(site);
        }
      }
    }
    setAccessToSites(accessToSites);

    try {
      const request = await getEventsForListedSites(
        accessToSites,
        startOfMonth,
        endOfMonth
      );
      const events = request.data.events;
      setLastEventTimeStamp(new Date().toISOString());
      dispatch(actions.eventsData(events));
    } catch (error) {
      console.log(`Erro errror ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndDispatchEvents = async () => {
    setLoading(true);
    try {
      const request = await getEventsForListedSites(
        accessToSites,
        startOfMonth,
        endOfMonth,
        lastEventTimeStamp
      );

      const events = request.data.events;
      if (events.length > 0) {
        setLastEventTimeStamp(new Date().toISOString());
        dispatch(actions.updateEvents(events));
      }
      await fetchTrains(siteId);
    } catch (error) {
      console.log(
        `Error while fetching events and trains in realtime ${accessToSites}`
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrains = async (siteName) => {
    try {
      const trainRequest = await getTrains(siteName, lastTrainTimeStamp);
      const trains = trainRequest.data.trains;
      if (trains.length > 0) {
        if (lastTrainTimeStamp) {
          dispatch(actions.updateTrains(trains));
        } else {
          dispatch(actions.trainsData(trains));
        }
        setLastTrainTimeStamp(new Date().toISOString());
      }
    } catch (err) {
      console.log("Error while fetching trains: " + err);
      console.error(err);
    }
  };

  const fetchSiteInformation = async (siteId) => {
    try {
      const siteRequest = await getSite(siteId);
      const site = siteRequest.data;
      const lat = site.lat_bounds[0];
      const lon = site.long_bounds[0];
      const copyViewState = { ...viewState };
      copyViewState["latitude"] = lat;
      copyViewState["longitude"] = lon;
      dispatch(actions.setViewState(copyViewState));
    } catch (err) {
      console.log("Error while fetching site information: " + err);
      console.error(err);
    }
  };

  let layout = <DesktopView drawerWidth={drawerWidth} />;
  if (!matches) {
    layout = <MobileView drawerWidth={drawerWidth} />;
  }

  return layout;
};

export default Layout;
