import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationFI from "./locales/fi/translation.json";
import translationEN from "./locales/en/translation.json";

// the translations
const resources = {
  fi: {
    translation: translationFI,
  },
  en: {
    translation: translationEN,
  },
};

const LNG = process.env.REACT_APP_LANGUAGE || "fi";

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LNG,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
